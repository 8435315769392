import React, {useContext, useEffect, useState} from "react";
import {useController} from "react-hook-form";
import {addTimestampToField, addWorkAttachmentToField} from "@data/utility/Utils";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {View} from "react-native";
import {AudioRecordingContainer} from "./AudioRecordingContainer";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {audioRecordingStatus, pauseActivity} from "@data/redux/actions/activity";
import {liveService} from "@data/services/liveService";
import {useApiStatus} from "@common/hooks/useApiStatus";

const AudioVideoActivityStep = (props) => {
    // ** Component props
    const {stepData, stepIndex, control, status, setStatus, finishStep, recordingURI, setRecordingURI, style} = props

    //const [recordingURI, setRecordingURI] = useState(null)

    const [time, setTime] = useState(0)

    // ** Form field
    const {field: activityLogsField} = useController({
        control,
        name: "activity_logs"
    })
    const {field: workAttachmentLogsField} = useController({
        control,
        name: "work_attachment_logs"
    })

    useEffect(() => {

        //Enable Voice recording activity
        if (props.liveSessionId)
            props.apiRequest(liveService.setVoiceRecordingActivity, {id: props.liveSessionId}, {enabled: true})

        const stepFinishedData = activityLogsField?.value?.find(log => (log?.action === "step_finished" && log?.step === stepIndex + 1))
        const workAttachment = workAttachmentLogsField?.value?.find(log => (log?.action === "step_media_sent" && log?.step === stepIndex + 1))

        if (!workAttachment) {
            // update field to be sent to server
            addTimestampToField(activityLogsField, stepIndex + 1, "step_started")
        } else if (stepData?.length !== 0) {
            // setStatus("finished")
            setRecordingURI(workAttachment?.work_attachment?.uri)
        }


    }, [])

    useApiStatus(
        liveService.setVoiceRecordingActivity, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            //console.log("setVoiceRecordingActivity", data)
        } // success callback
    )

    useEffect(() => {
        if (status !== "finished" && !!recordingURI && recordingURI !== workAttachmentLogsField.value?.[0]?.work_attachment?.uri) {
            addWorkAttachmentToField(workAttachmentLogsField, stepIndex + 1, [{uri: recordingURI}], props?.user?.id, "m4a")
        }


        // return sound ? () => {
        //     sound?.unloadAsync();
        // } : undefined;
    }, [recordingURI]);
    useEffect(() => {
        if (props.activityPaused)
            props.pauseActivity(false)
    }, []);
    const dimensions = useContext(DimensionsContext)

    useEffect(() => {
        if (status === "finished" && !!recordingURI) {
            finishStep()
            addTimestampToField(activityLogsField, stepIndex + 1, "step_finished", {
                chrono_duration: time
            })
            if (props.liveSessionId)
                props.apiRequest(liveService.setVoiceRecordingActivity, {id: props.liveSessionId}, {enabled: false})
            // addWorkAttachmentToField(workAttachmentLogsField, stepIndex + 1, [{uri: recordingURI}], props?.user?.id, "m4a")
        }
    }, [status]);
    return <View style={{
        //flex: 1,
        width: dimensions?.width,
        paddingTop: 4,
        alignItems: 'center',
        alignContent: "flex-start",
        justifyContent: "flex-start",
        paddingHorizontal: 15,
        ...style
    }}>
        {/*<ActivityStatusChampion stepData={stepData} status={status} />*/}

        <AudioRecordingContainer status={status}
                                 setTime={setTime}
                                 recordingURI={recordingURI}
                                 setRecordingURI={setRecordingURI}
                                 activityPaused={props.activityPaused}
                                 audioRecordingStatus={props.audioRecordingStatus}
                                 setStatus={setStatus}/>


        {/*        {status !== 'finished' && <FilledButtonM color={theme.colors.success}
                                                 onPress={() => {
                                                     finishStep()
                                                     addTimestampToField(activityLogsField, stepIndex + 1, "step_finished", {
                                                         chrono_duration: time,
                                                     })
                                                     if (props.liveSessionId)
                                                         props.apiRequest(liveService.setVoiceRecordingActivity, {id: props.liveSessionId}, {enabled: false})
                                                     // addWorkAttachmentToField(workAttachmentLogsField, stepIndex + 1, [{uri: recordingURI}], props?.user?.id, "m4a")
                                                 }}
                                                 disabled={!recordingURI}
                                                 style={{
                                                     width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                                                     position: "absolute",
                                                     alignSelf: "center",
                                                     bottom: Platform.OS === 'ios' ? 20 : 5
                                                 }}
                                                 label="J'ai terminé cette étape"/>}*/}

    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        activityPaused: state.activity?.activityPaused,
        liveSessionId: state.liveMode?.liveSessionId
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        pauseActivity,
        audioRecordingStatus
    }

export default connect(mapStateToProps, mapDispatchToProps)(AudioVideoActivityStep)