import React, {useContext} from 'react';
import {theme} from "../theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {Dimensions, Platform} from "react-native";
import {clearLastNotificationIdentifier, setLastNotificationIdentifier} from "@data/redux/actions/notifications";
import {hasRole} from "@data/utility/ability";
import {liveStudentTabScreens} from "@common/liveMode/liveStudentTabScreens";
import {liveTutorTabScreens} from "@common/liveMode/liveTutorTabScreens";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {IS_WEB} from "@common/utils/mobileUtils";

const Tab = createBottomTabNavigator();

const BottomTabsNavigator = (props) => {

    const {navigation} = props
    const liveSession = props.currentLiveSession
    const liveVersion = liveSession?.live_room?.version
    const tabScreens = hasRole(props.user, 'student') ? liveStudentTabScreens(props.user) : liveTutorTabScreens(props.user, liveVersion)
    const insets = useSafeAreaInsets()

    const soundMode = hasRole(props.user, 'student') ? props.soundModeIds?.includes(props.user?.id) : true


    const ability = useContext(AbilityContext)
    return (
        <>
            {!!props.user && <Tab.Navigator
                // initialRouteName="finished-activities-live"
                screenOptions={{
                    tabBarShowLabel: false,
                    unmountOnBlur: false,
                    tabBarStyle: {
                        backgroundColor: !soundMode ? theme.colors.purple : theme.colors.success,
                        height: (IS_WEB ? 74 : 80) + insets.bottom/2,
                        width: Dimensions.get("window").width,
                        alignSelf: Platform.OS !== 'web' ? null : "center",
                        // borderTopWidth: 1,
                        borderColor: theme.colors.success,
                        alignItems: 'center',
                    }
                }}
                shifting={true}
                labeled={false}
                sceneAnimationEnabled={false}
                activeColor={!soundMode ? theme.colors.purple : theme.colors.success}
                inactiveColor={!soundMode ? theme.colors.purple : theme.colors.success}
            >
                {tabScreens.map(screen => {
                    if (!screen.can || ability.can('read', screen.can)) {
                        return <Tab.Screen
                            key={screen.name}
                            name={screen.name}
                            component={screen.component}
                            options={screen.options}
                        />
                    } else
                        return null
                })
                }
            </Tab.Navigator>}

        </>
    )
}

const mapStateToProps = state => {
    return {
        getStaticDataError: state.api.getStaticData?.error,
        getUserError: state.api.getUser?.error,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        minimumVersion: state.data.staticData?.min_client_version,
        soundModeIds: state.liveMode?.soundModeIds,
        lastNotificationIdentifier: state.notifications.lastIdentifier
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setLastNotificationIdentifier,
    clearLastNotificationIdentifier
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomTabsNavigator)
