import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {roomService} from "@data/services/roomService";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useForm} from "react-hook-form";
import selectSlotsStep from "./SelectSlotsStep";
import {backOfficeService} from "@data/services/backOfficeService";
import {RankingListItem} from "../../../student/social/RankingScreen";
import {formatHoursMinutesAndSeconds} from "@common/liveMode/live-report/LiveReportComponent";

const NewSessions = (props) => {

    const {room_id} = props.route.params
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const room = findItemInListWithId(room_id, props.rooms) ?? null
    const defaultValues = {}
    const {
        control,
        handleSubmit,
        formState: {errors, dirtyFields, isValid,},
        resetField,
        trigger,
        register,
        setError,
        getValues
    } = useForm({
        // mode: 'all',
        // resolver,
        defaultValues
    })
    const formData = [
        {
            label: "Choisir le tuteur",
            name: "tutor_id",
            type: "select.paginated",
            singleMode: true,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "tutor",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: room?.tutor?.id ?? "",
            validationRules: {...validation.required()}
        },
        {
            label: "Choisir la durée de session",
            type: "select.single",
            name: "duration",
            //All possible durations from 15 minutes to 120 minutes, with 15 minutes interval between each
            options: Array.from({length: 8}, (v, k) => (k + 1) * 15).map(x => ({value: x, label: formatHoursMinutesAndSeconds(x*60, 10, true)})),
            defaultValue: room?.type === "holiday_course" ? 120 : 60,
            validationRules: {...validation.required()}
        },
        {
            label: "Choisir les créneaux des sessions live",
            type: "custom",
            name: "slots",
            component: selectSlotsStep,
            defaultValue: [],
            validationRules: {...validation.required()}
        },
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        // console.log(data)
        if (props.addSessionsRequest) return

        props.apiRequest(roomService.addMultipleSessions, {}, {
            tutor_id: data?.tutor_id,
            slots: data?.slots,
            duration: data?.duration,
            live_room_id: room_id
        })

    }
    useApiStatus(
        roomService.addMultipleSessions, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(roomService.getRooms, {}, {}, {type: room?.type})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("rooms")
        } // success callback
    )

    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.addSessionsRequest}
                         previousRouteName={"rooms"}
                         submittedMessage=""
                         serverError={props.addSessionsError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        tutors: state.data.tutors?.list,
        addSessionsRequest: state.api.addMultipleSessions?.request,
        addSessionsError: state.api.addMultipleSessions?.error,
        rooms: state.data.backOffice?.rooms,
        users: state.data.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewSessions)
