import {Linking, View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {PremiumCoach, PremiumPlus} from "@common/components/icons/IconsM";
import React, {useContext} from "react";
import {Text} from "react-native-paper";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {getPrivilegedRole, hasRole} from "@data/utility/ability";
import {AbilityContext} from "@data/utility/Can";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {isSubscribed, isSubscriptionPaused, subscriptionPausePeriod} from "@data/utility/dataUtils";

// export const minSwitchToPremiumRemainingDays = 3

export const canSubscribeToTrialEssential = (user) => getPrivilegedRole(user) === "basic"
export const canSubscribeToEssential = (user) => getPrivilegedRole(user) === "basic" && !user?.tutor


const BecomePremiumCard = (props) => {

    // ** Component props
    const {style, index = 0} = props

    const navigation = useNavigation()

    const ability = useContext(AbilityContext)

    // function getRandomInt(max) {
    //     return Math.floor(Math.random() * max);
    // }

    // const diffDays = moment(props.user?.trial_ends_at).diff(moment().add(minSwitchToPremiumRemainingDays, "days"), "days")

    // const index = getRandomInt(2) === 1 ? 0 : 1
    // const canSwitchToEssentialTrial = canSwitchToEssential(props.user)

    // console.log(product)

    // const canSubscribeToPlus = product === "basic"
    const initialCondition = hasRole(props.user, 'student') && !isSubscribed(props.user) && ability.cannot("read", "holiday_course_views")

    const premiumAdsContent = [
        {
            title: "Offre Essentielle",
            description: "Bénéficie d'un suivi sur mesure par un tuteur",
            // descriptionTrial: (diffDays === 0 ? "Dernier jour" : ("Plus que " + diffDays + " jour" + (diffDays > 1 ? "s" : ""))) + " pour profiter d'un entretien gratuit !",
            premium_icon: false
        }
        // {
        //     title: "Masteur Plus",
        //     description: "Bénéficie de conseils et d'un score sur toutes tes activités",
        //     premium_icon: false
        // },

    ]


    function onPress() {
        if (canSubscribeToTrialEssential(props.user))
            navigation.push("start-trial")
        else
            navigation.push("offer-essential")
    }

    const dimensions = useContext(DimensionsContext)

    return (initialCondition && (
            (index === 0 && (canSubscribeToTrialEssential(props.user) || canSubscribeToEssential(props.user)))
        )
    ) ? <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: index === 1 ? theme.colors.material.secondary["100"] : theme.colors.accentDark,
            borderRadius: 20,
            width: IS_WEB ? dimensions?.width / 1.2 : dimensions?.width - 20,
            paddingHorizontal: 15,
            paddingTop: 20,
            paddingBottom: 10,
            ...style
        }}
              disabled
              underlayColor={theme.colors.accentLight}
        >
            <View style={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                // justifyContent: 'flex-start',
                flexDirection: "row",
                width: "100%"
            }}>

                <View style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "80%"
                }}>
                    <Text
                        style={{
                            flex: 0,
                            color: index === 1 ? theme.colors.accentDark : theme.colors.white,
                            fontFamily: "Owl-Cute",
                            fontSize: 30
                            // flexShrink: 1,
                            // alignSelf: 'center'
                        }}
                    >
                        {isSubscriptionPaused(props.user) ? "Abonnement en pause" : premiumAdsContent[index]?.title}
                    </Text>
                    {<TextM fontWeight="Medium"
                            color={index === 1 ? theme.colors.accentDark : theme.colors.white}
                            fontSize={14}
                            wrap
                            style={{
                                // marginLeft: 10
                            }}
                    >
                        {isSubscriptionPaused(props.user) ? "Ton abonnement est en pause " + subscriptionPausePeriod(props.user) : premiumAdsContent[index]?.description}
                        {/*{(index === 0 || (index === 1 && !canSwitchToPremiumTrial)) ? premiumAdsContent[index]?.description : premiumAdsContent[index]?.descriptionTrial}*/}
                    </TextM>}
                </View>


                {premiumAdsContent[index]?.premium_icon ? <PremiumCoach size={40}/> : <PremiumPlus size={40}/>}

            </View>
            {isSubscriptionPaused(props.user) ?
                <FilledButtonM color={theme.colors.white}
                               style={{marginTop: 10, width: "100%"}}
                               textColor={theme.colors.accentDark}
                               onPress={() => {
                                   if (IS_WEB)
                                       location.href = "mailto:support@masteur.com"
                                   else
                                       Linking.openURL("mailto:support@masteur.com").catch(err => console.error("Couldn't load page", err))
                               }}
                               label={"Une question ?"}/>
                : <FilledButtonM color={theme.colors.white}
                                 style={{marginTop: 10, width: "100%"}}
                                 textColor={theme.colors.accentDark}
                                 onPress={onPress}
                                 label={(index === 0 && canSubscribeToTrialEssential(props.user)) ? "ESSAI GRATUIT" : (index === 0 && canSubscribeToEssential(props.user)) ? "EN SAVOIR PLUS" : "J'INDIQUE MES CRÉNEAUX"}/>}
        </View>
        : null
}

const mapStateToProps = state => {
    return {

        user: state.data.currentUser?.object


    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(BecomePremiumCard)