import React from "react"
import Constants from "expo-constants";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

export const initBugsnag = () => {
    return (!__DEV__) ?
        Bugsnag.start({
            apiKey: Constants.expoConfig.extra.bugsnag.apiKey,
            plugins: [new BugsnagPluginReact()],
            releaseStage: Constants.expoConfig.extra.releaseStage,
            enabledReleaseStages: ['production', 'staging'],
            appVersion: Constants.expoConfig.version,
        }) : null

}
export const initErrorBoundary = () => {
    return Bugsnag.getPlugin('react').createErrorBoundary(React)
}

export const notifyBugsnag = (error) => {
    if ((!__DEV__))
        Bugsnag.notify(error)
}

export const setBugsnagUser = (user) => {
    if ((!__DEV__))
        Bugsnag.setUser(user?.id, user?.email, user?.display_name)
}