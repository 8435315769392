import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {ActivityIndicator} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import React from "react";
import TextButtonM from "@common/components/button/TextButtonM";

export const ConnectingView = ({width, height, onPress, miniMode, text = "Connexion en cours..."}) => <View style={{
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    flex: 1,
    backgroundColor: theme.colors.black,
    width: width,
    height: height
}}>
    <ActivityIndicator color={theme.colors.white} size={"large"}/>
    <TextM color={theme.colors.white} style={{marginTop: 6}}>{text}</TextM>
    {!miniMode && <TextButtonM
        onPress={onPress}
        trackingId="cancel_connecting"
        style={{
            marginTop: 20
        }}
        color={theme.colors.white}
        fontWeight="ExtraBold"
        label="Annuler"/>}
</View>