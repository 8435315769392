import React, {useContext, useState} from 'react';
import {backOfficeService} from "@data/services/backOfficeService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import UsersTable from "../UsersTable";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {candidaturesStatus} from "@data/constants/formConstants";
import {allOptionsFromSectionOptions} from "@data/utility/Utils";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import ServerSearch from "../ServerSearch";
import FilledButtonM from "@common/components/button/FilledButtonM";

const TutorsScreen = (props) => {

    const {tab} = props
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    const [urlParams, setUrlParams] = useState({
        roles: 'tutor'
    })
    const [formValues, setFormValues] = useState(null)

    const requestApiService = backOfficeService.searchUser

    const urlParamsAdditional = {
        accept_new_students: formValues?.accept_new_students?.join(",") ?? "",
    }

    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            // console.log("newFormValues", newFormValues)
            // console.log("urlParams", urlParams)
            props.apiRequest(requestApiService, {}, {}, {
                ...urlParams,
                accept_new_students: newFormValues?.accept_new_students?.join(",") ?? "",
            })
        } else {
            // console.log("urlParamsAdditional", urlParamsAdditional)
            // console.log("urlParams", urlParams)
            props.apiRequest(requestApiService, {}, {}, {...urlParams, ...urlParamsAdditional})
        }
    }

    // ** Filter options
    const filterItems = [
        {
            name: "accept_new_students",
            label: "Accepte de nouveaux élèves",
            options: [
                {
                    value: 1,
                    label: "Oui"
                },
                {
                    value: 0,
                    label: "Non"
                }
            ],
            defaultValue: [0, 1]
        }
    ]

    useApiStatus(
        backOfficeService.searchTutors, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // setTutors(data?.users)
            // console.log(data)
        } // success callback
    )

    return <View style={{width: dimensions?.width * 3 / 2, flex: 1}}>
        <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>
            <>
            <View style={{flexDirection: "row", alignItems: "center", marginRight: 20}}>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        // position: "absolute",
                        marginRight: 10
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
                <TitleM>Liste des tuteurs</TitleM>
            </View>
            <View style={{flexDirection: "row", alignItems: "center"}}>
                <MenuFilter style={{
                    alignItems: "space-between",
                    width: 300,
                    marginBottom: 10
                }}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            filterItems={filterItems}
                            onFinishSelecting={onFinishSelecting}
                />
                <ServerSearch style={{
                    width: 240,
                    margin: 10
                }}
                              urlParams={urlParams}
                              setUrlParams={setUrlParams}
                />
            </View>
        </>
        </HeaderContainerM>
        {props.usersRequest ? <ActivityIndicator color={theme.colors.primary}/> :
            <UsersTable
                tabIndex={1}
                urlParams={urlParams}
                tab={"tutor"}
            />
        }
    </View>
}
const mapStateToProps = state => {
    return {
        usersRequest: state.api?.searchTutors?.request,
        users: state.data?.users?.usersList,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorsScreen)