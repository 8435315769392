import React, {useContext, useEffect, useState} from 'react';
import {Dimensions, Image, ScrollView, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {StatusBar} from "expo-status-bar";
import {ActivityIndicator} from "react-native-paper";
import logo from "../../assets/logo-blue.png";
import {subscriptionService} from "@data/services/subscriptionService";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {DimensionsContext} from "@common/utils/mobileUtils";
import base64 from "@common/utils/base64";
import {useNavigation} from "@react-navigation/native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {PricingTableM} from "./PricingTableM";
import AvatarM from "@common/components/avatar/AvatarM";
import {isSubscribed, isSubscriptionPaused} from "@data/utility/dataUtils";

import MySubscriptionInfos from "../student/account/subscription/MySubscriptionInfos";
import {ToggleButtonM} from "@common/components/button/ToggleButtonM";
import {useController} from "react-hook-form";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import * as amplitude from "@amplitude/analytics-react-native";
import NextLiveSessionBanner from "../student/activity/todo/NextLiveSessionBanner";
import {TouchableOpacity} from "@gorhom/bottom-sheet";
import TitleM from "@common/components/text/TitleM";
import {setBugsnagUser} from "@common/utils/initBugsnag";

export function ChooseSubscriptionOffer(props) {

    const {
        control,
        goToNextStep,
        register,
        errors,
        serverError,
        setFormValues,
        field,
        userEmail,
        pricingTableData,
        startNextMonth,
        pricingTableDataNextMode
    } = props

    const {field: productNameField} = control ? useController({
        control,
        name: "product_name"
    }) : {};

    const {field: withCommitmentField} = control ? useController({
        control,
        name: "with_commitment"
    }) : {};

    const [withCommitment, setWithCommitment] = useState(withCommitmentField?.value ?? 1)
    const dimensions = useContext(DimensionsContext)

    const advantage = !withCommitment ? pricingTableData?.[0]?.advantage : pricingTableData?.[0]?.prices?.monthly?.commitment_discount_data?.advantage_in_months

    return <View style={{alignItems: "center", width: dimensions?.width, alignSelf: "center"}}>

        <ToggleButtonM labels={[
            <View style={{alignItems: "center"}}>
                <TextM fontSize={10} fontWeight={"ExtraBold"} color={theme.colors.gray}>Engagement mensuel</TextM>
                <TextM fontSize={8}>Paiement mensuel</TextM>
            </View>,
            <View style={{alignItems: "center"}}>
                <TextM fontSize={10} fontWeight={"ExtraBold"} color={theme.colors.success}>Engagement annuel</TextM>
                <TextM fontSize={8}>Paiement mensuel</TextM>
            </View>]}
                       buttonsStyle={{width: 180}}
                       initialIndex={withCommitment}
                       onSelectIndex={setWithCommitment}/>
        <TextM color={withCommitment ? theme.colors.success : theme.colors.grey}
               fontWeight={withCommitment ? "Medium" : "Regular"}
               style={{textAlign: "center", marginHorizontal: 10}}
               wrap>{advantage}</TextM>

        <PricingTableM startNextMonth={startNextMonth}
                       withCommitment={withCommitment}
                       onPress={control ? (productName, withCommitment) => {
                           productNameField.onChange(productName)
                           withCommitmentField.onChange(withCommitment)
                           setFormValues({
                               ...control._formValues,
                               product_name: productName,
                               with_commitment: withCommitment
                           })
                           goToNextStep()
                       } : null}
                       userEmail={userEmail}
                       pricingTableData={pricingTableData}
                       pricingTableDataNextMode={pricingTableDataNextMode}
        />
    </View>;
}

const SubscribeScreen = (props) => {

    const userEmailb64 = props.route?.params?.user ?? ""
    const userEmail = base64.decode(userEmailb64.replace(/-/g, ""))
    const startNextMonth = props.route?.params?.start_next_month ?? false
    const initialShowDetails = !!props.route?.params?.show_details ?? false
    const status = props.route?.params?.status ?? null
    //remove any "-" in the base64 email and replace it by ""
    const navigation = useNavigation()

    const pricingTableData = props.pricingTableData
    const pricingTableDataNextMode = props.pricingTableDataNextMode
    const billingPortalUrl = props.billingPortalUrl
    const user = props.user

    const dimensions = useContext(DimensionsContext)

    const insets = useSafeAreaInsets()

    useEffect(() => {

            if (!props.currentUser || props.currentUser?.email === userEmail) {
                amplitude.setUserId(userEmail)
                setBugsnagUser({id: "-", email: userEmail, display_name:"-"})
            }

            // get infos from back
            props.apiRequest(subscriptionService.getSubscribePricing, {}, {}, {
                email: userEmail
            })

            if (status === "success") {
                DialogM.show({
                    text1: "Félicitations ! 🎉",
                    text2: "Votre abonnement a bien été activé. Un mail de confirmation vous a été envoyé."
                })
                navigation.setParams({
                    ...props.route.params,
                    status: null
                })
            }
        }
        ,
        []
    )

    useApiStatus(
        subscriptionService.getSubscribePricing, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {

        }, // success callback
        (error) => {
            console.log(error)
        }, // error callback
        true
    )

    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            width: "100%"
            // paddingHorizontal: 20
        }}>
            {(props.request) ? <>
                <ActivityIndicator color={theme.colors.primary}
                                   style={{marginTop: 30}}
                />
                <TextM wrap>{"Chargement..."}</TextM>
            </> : props.error ? <>
                <TextM color={theme.colors.error}
                       wrap
                       style={{
                           marginTop: 20,
                           marginBottom: 20,
                           textAlign: "center"
                       }}
                       fontWeight={"Bold"}
                       fontSize={14}>
                    {props.error.message}
                </TextM>
                <FilledButtonM
                    onPress={() => {
                        navigation.goBack()
                    }}
                    label={"Retour à l'accueil"}
                />
            </> : props.success ? <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    width: "100%"
                }}>
                <ScrollView style={{width: "100%"}}
                            contentContainerStyle={{
                                // flex: 1,
                                width: "100%",
                                alignItems: "center"
                            }}>
                    <View
                        style={{
                            flex: 1,
                            alignItems: "center",
                            width: dimensions?.width < 800 ? dimensions?.width : null
                        }}>
                        <View style={{
                            marginBottom: 20,
                            paddingHorizontal: 10,
                            marginTop: insets.top + 10,
                            width: dimensions?.width,
                            alignItems: "center",
                            alignSelf: 'center',
                            justifyContent: "space-between",
                            flexDirection: "row"
                        }}>
                            <Image style={{
                                width: 120,
                                height: 50
                            }}
                                   resizeMode="contain"
                                   source={logo}
                            />
                            <View
                                style={{
                                    justifyContent: "flex-end", alignItems: "center", flexDirection: "row"
                                }}>

                                <View style={{marginRight: 15}}>
                                    <TextM color={theme.colors.primary} fontWeight={"Medium"} wrap
                                           style={{textAlign: "right"}}>{`${user?.firstname} ${user?.lastname}`}</TextM>
                                    <TextM color={theme.colors.grey} fontWeight={"Light"} wrap fontSize={10}
                                           style={{textAlign: "right"}}>{`${user?.email}`}</TextM>
                                </View>
                                <AvatarM user={user} size={32}/>
                            </View>
                        </View>

                        {!isSubscribed(user) && !isSubscriptionPaused(user) ? <>
                                <TextM fontWeight={"ExtraBold"}
                                       color={theme.colors.primary}
                                       fontSize={32}
                                       style={{
                                           textAlign: "center",
                                           marginTop: 30,
                                           marginBottom: 20
                                       }}
                                       wrap>{"S'abonner"}</TextM>
                                <TextM fontWeight={"Regular"}
                                       fontSize={16}
                                       style={{
                                           textAlign: "center",
                                           marginBottom: 10
                                       }}
                                       wrap>Choisissez votre formule</TextM>
                                <ChooseSubscriptionOffer userEmail={userEmail}
                                                         startNextMonth={startNextMonth}
                                                         pricingTableDataNextMode={pricingTableDataNextMode}
                                                         pricingTableData={pricingTableData}/>
                            </>
                            : <>
                                <NextLiveSessionBanner noButton subscriptionUser={props.user}/>
                                {props.user?.current_study_programs?.length > 0 && <View style={{
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.lightMore,
                                    width: Dimensions.get('window').width,
                                    paddingBottom: 20,
                                    alignItems: "center"
                                }}>
                                    <TextM fontWeight={"ExtraBold"}
                                           color={theme.colors.primary}
                                           fontSize={32}
                                           style={{
                                               textAlign: "center",
                                               marginTop: 20,
                                               marginBottom: 20
                                           }}
                                           wrap>Mes programmes de révision</TextM>

                                    {props.user?.current_study_programs?.map(studyProgram => {
                                        return <TouchableOpacity onPress={() => {
                                            navigation.push("study-program", {id: studyProgram?.id})
                                        }}
                                                                 style={{
                                                                     width: dimensions?.width - 40,
                                                                     borderWidth: 1,
                                                                     borderRadius: 15,
                                                                     borderColor: theme.colors.lightMore,
                                                                     backgroundColor: theme.colors.white,
                                                                     marginBottom: 10
                                                                 }}>
                                            <View style={{
                                                alignItems: "flex-start",
                                                flex: 1,
                                                paddingVertical: 10,
                                                paddingHorizontal: 15
                                            }}>
                                                {<TitleM style={{fontSize: 16, marginBottom: 0}}>{studyProgram?.title}</TitleM>}
                                                {<TextM fontWeight={"Bold"}
                                                        color={theme.colors.light}
                                                        style={{}}>{studyProgram?.school_subject?.name} | {studyProgram?.class}</TextM>}
                                            </View>
                                        </TouchableOpacity>
                                    })}
                                </View>}
                                <View style={{width: dimensions?.width, alignSelf: "center"}}>
                                <TextM fontWeight={"ExtraBold"}
                                       color={theme.colors.primary}
                                       fontSize={32}
                                       style={{
                                           textAlign: "center",
                                           marginTop: 30,
                                           marginBottom: 20
                                       }}
                                       wrap>{"Mon offre"}</TextM>
                                <MySubscriptionInfos user={user}
                                                     currentTableData={user?.current_table_data}
                                                     plannedTableData={user?.planned_table_data}
                                                     billingPortalUrl={billingPortalUrl}
                                                     initialShowDetails={initialShowDetails}/>
                                </View>
                            </>}
                    </View>
                </ScrollView>
            </View> : null}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        request: state.api.getSubscribePricing?.request,
        success: state.api.getSubscribePricing?.success,
        error: state.api.getSubscribePricing?.error,
        currentUser: state.data.currentUser?.object,
        user: state.data.formData?.user,
        billingPortalUrl: state.data.formData?.billingPortalUrl,
        pricingTableData: state.data.formData?.pricingTableData,
        pricingTableDataNextMode: state.data.formData?.pricingTableDataNextMode
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeScreen)