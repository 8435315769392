import React, {useEffect} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import {Platform} from "react-native";
import {theme} from "../theme/theme";
import {authService} from "@data/services/authService";
import Constants from "expo-constants";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useNavigation} from "@react-navigation/native";
import {clearLastNotificationIdentifier, setLastNotificationIdentifier} from "../../data/redux/actions/notifications";
import {hasRole} from "../../data/utility/ability";
import {setIsLiveModeEnabled} from "../../data/redux/actions/liveMode";
import moment from "moment";
import * as amplitude from "@amplitude/analytics-react-native";

const NotificationSubscriptionHandler = (props) => {

    const navigation = useNavigation()

    // ** Manage push notification responses
    const lastNotificationResponse = Platform.OS !== 'web' ? Notifications.useLastNotificationResponse() : null;

    React.useEffect(() => {
        if (Platform.OS !== 'web')
            // console.log(lastNotificationResponse)
            if (
                lastNotificationResponse &&
                lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER &&
                lastNotificationResponse.notification.request.identifier !== props.lastNotificationIdentifier
            ) {
                // const url = lastNotificationResponse.notification.request.content.data.url
                // let {path, queryParams} = Linking.parse(url);
                // console.log(`Linked to app with path: ${path} and data: ${JSON.stringify(queryParams)}`)

                // save identifier in order not to process the notification twice
                props.setLastNotificationIdentifier(lastNotificationResponse.notification.request.identifier)

                //track in amplitude
                amplitude.track("push.notif.clicked" + (lastNotificationResponse.notification.request.content.data?.path ? `.${lastNotificationResponse.notification.request.content.data?.path}` : ""))

                if (lastNotificationResponse.notification.request.content.data.path) {
                    const path = lastNotificationResponse.notification.request.content.data.path
                    const activityId = lastNotificationResponse.notification.request.content.data.activity_id
                    const challengeId = lastNotificationResponse.notification.request.content.data.challenge_id
                    const messageData = lastNotificationResponse.notification.request.content.data.data

                    switch (path) {
                        case "activities-for-review":
                            if (activityId)
                                navigation.push("activity-review", {
                                    activityId: activityId
                                })
                            return
                        case "challenges":
                            if (challengeId) {
                                console.log("challengeId", challengeId)

                                // const jumpToAction = TabActions.jumpTo("Aujourd'hui", {
                                //     challengeId: challengeId
                                // })
                                // navigation.dispatch(jumpToAction);
                            }
                            return
                        case "new-review":
                            if (activityId)
                                navigation.push("activity-result", {
                                    activityIds: [activityId],
                                    fromNotification: true
                                })
                            return
                        case "coaching":
                            navigation.navigate('menu', {screen: 'coaching'});
                            return

                        case "live-session":
                            // if live mode not enabled, enable it with the right live session id
                            if (!props.isLiveMode) {
                                navigation.navigate('menu', {screen: 'coaching'});
                            }
                            // if live mode enabled
                            else {
                                // do nothing
                            }

                            return
                        case "messages":
                            // console.log(JSON.stringify(lastNotificationResponse.notification.request.content.data))
                            // console.log("has role", hasRole(props.user, "student") )
                            // console.log("user", props.user )
                            // Live thread message
                            if (messageData?.meta?.thread_type_verbose === "GROUP") {
                                const threadLiveSession = props.user?.live_sessions?.find(s => {
                                    const liveMoment = moment(s?.day_time, "dddd HH:mm", "en").locale("fr")
                                    const isToday = liveMoment?.isSame(moment(), "day")
                                    if (s?.thread_id?.toString() === messageData?.thread_id?.toString() && isToday)
                                        return s
                                    return null
                                })
                                // if live mode not enabled, enable it with the right live session id
                                //console.log(threadLiveSession, props.liveSessionId, messageData?.thread_id)
                                if (!props.isLiveMode) {
                                    if (threadLiveSession)
                                        navigation.push("pre-call", {
                                            liveSessionId: threadLiveSession?.id
                                        })
                                    else DialogM.show({
                                        text1: 'La conversation n\'a pas été trouvée'
                                        //text2: 'Pour y accéder, il faut quitter cette session live et ouvrir l\'autre'
                                    })
                                }
                                // if live mode enabled
                                else {
                                    // if good live id, navigate to live-messages screen
                                    if (props.currentLiveSession.thread_id === messageData?.thread_id) {
                                        navigation.navigate("messages", {
                                            //userId: messageData.owner_id
                                            userId: 0
                                        })
                                    }
                                    // if wrong live id
                                    else {
                                        DialogM.show({
                                            text1: 'Message reçu dans une autre session',
                                            text2: 'Pour y accéder, il faut quitter cette session live et ouvrir l\'autre'
                                        })
                                    }

                                }
                            } else {
                                // if live mode not enabled, enable it with the right live session id
                                if (!props.isLiveMode) {
                                    if (hasRole(props.user, "student")) {
                                        if ((props.user.tutor?.id.toString() === messageData?.owner_id.toString()))
                                            navigation.navigate("messenger", {
                                                studentId: props.user?.id
                                            })
                                        else {
                                            const session = props.user?.live_sessions?.find(s => s?.thread_ids?.[messageData?.owner_id])
                                            if (session) {
                                                props.setIsLiveModeEnabled(true, session?.id)
                                            } else DialogM.show({
                                                text1: 'La conversation n\'a pas été trouvée'
                                                //text2: 'Pour y accéder, il faut quitter cette session live et ouvrir l\'autre'
                                            })
                                        }
                                    } else if (props.user.students?.find(st => st?.id === messageData?.owner_id)) {
                                        navigation.navigate("messenger", {
                                            studentId: props.user?.students?.find(st => st?.id === messageData?.owner_id)?.id
                                        })
                                    } else {
                                        const session = props.user?.live_sessions?.find(s => s?.thread_ids?.[messageData?.owner_id])
                                        if (session)
                                            props.setIsLiveModeEnabled(true, session?.id)
                                        else DialogM.show({
                                            text1: 'La conversation n\'a pas été trouvée'
                                            //text2: 'Pour y accéder, il faut quitter cette session live et ouvrir l\'autre'
                                        })
                                    }
                                }
                                // if live mode enabled
                                else {
                                    const thread = props.currentLiveSession.thread_ids[messageData?.owner_id]

                                    // if good live id, navigate to live-messages screen
                                    if (thread) {
                                        navigation.navigate("messages", {
                                            userId: messageData.owner_id
                                            //userId: 124
                                        })
                                    }
                                    // if wrong live id
                                    else {
                                        DialogM.show({
                                            text1: 'Message reçu dans une autre session',
                                            text2: 'Pour y accéder, il faut quitter cette session live et ouvrir l\'autre'
                                        })
                                    }

                                }


                            }
                            // Tutor-student message
                            /*       else {
                                       console.log("messageData123", messageData)
                                       // if live mode not enabled, enable it with the right live session id
                                       if (!props.isLiveMode) {
                                           navigation.navigate("messenger", {
                                               studentId: hasRole(props.user, "student")
                                                   ? props.user?.id
                                                   : props.user?.students?.find(st => st?.id === messageData?.owner_id)?.id
                                           })
                                       }
                                       // if live mode enabled
                                       else {
                                           DialogM.show({
                                               text1: 'Message personnel reçu',
                                               text2: 'Pour y accéder, il faut quitter cette session live'
                                           })
                                       }

                                   }*/

                            return

                        case "incoming-call":

                            const threadLiveSession2 = props.user?.live_sessions?.find(s => s?.id === messageData?.live_session_id)

                            // if live mode not enabled, enable it with the right live session id
                            if (!props.isLiveMode) {
                                if (threadLiveSession2) {
                                    // props.setIsLiveModeEnabled(true, threadLiveSession?.id)
                                    navigation.push("pre-call", {
                                        liveSessionId: threadLiveSession2?.id
                                    })
                                }
                            }
                            // if live mode enabled
                            else {
                                // if good live id, nothing to do
                                if (props.liveSessionId === threadLiveSession2?.id) {
                                }
                                // if wrong live id
                                else {
                                    DialogM.show({
                                        text1: 'Appel reçu dans une autre session',
                                        text2: 'Pour y accéder, il faut quitter cette session et ouvrir l\'autre'
                                    })
                                }

                            }

                            return

                        default:

                            navigation.navigate(path, messageData)

                            console.log('path', path)
                            return
                    }
                }

            }
    }, [lastNotificationResponse]);


    const registerForPushNotificationsAsync = async () => {
        if (Device.isDevice) {
            const {status: existingStatus} = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const {status} = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                return 'not_granted';
            }

            const projectId = Constants.expoConfig?.extra?.eas?.projectId;
            const token = (await Notifications.getExpoPushTokenAsync({projectId})).data;
            // console.log(token);
            // this.setState({expoPushToken: token});

            return token

        } else {
            console.log('Vous devez utiliser un appareil physique pour les notifications push.');
        }

        if (Platform.OS === 'android') {
            await Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: theme.colors.primary
            });
        }
    };

    useEffect(() => {

        // console.log("expo token", props.expoToken)

        if (!props.expoToken) {
            registerForPushNotificationsAsync().then((expo_token) => {
                if (expo_token === 'not_granted' || !Device.isDevice) {
                } else if (expo_token)
                    props.apiRequest(authService.subscribeToNotifications, {}, {expo_token})
                else if (!expo_token)
                    DialogM.show({
                        text1: 'Échec', text2: 'Impossible d\'enregistrer l\'appareil pour les notifications push'
                    })
                //alertPolyfill('Échec', 'Impossible d\'enregistrer l\'appareil pour les notifications push');
            })
        }
    }, []);

    useEffect(() => {

        // if any error then alert with the error and IDLE reset request state
        if (props.subscribeToNotificationsSuccess) {
            props.apiIdle(authService.subscribeToNotifications)
        } else if (props.subscribeToNotificationsError) {
            props.apiIdle(authService.subscribeToNotifications)
            DialogM.show({
                text1: 'Erreur', text2: props.subscribeToNotificationsError?.message
            })
            // alertPolyfill("Erreur", props.subscribeToNotificationsError?.message)
        }

    }, [props.subscribeToNotificationsSuccess, props.subscribeToNotificationsError])


    return null;
}

const mapStateToProps = state => {
    return {
        subscribeToNotificationsRequest: state.api.subscribeToNotifications?.request,
        subscribeToNotificationsSuccess: state.api.subscribeToNotifications?.success,
        subscribeToNotificationsError: state.api.subscribeToNotifications?.error,
        expoToken: state.data.session?.object?.expo_token,
        lastNotificationIdentifier: state.notifications.lastIdentifier,
        getStaticDataError: state.api.getStaticData?.error,
        getUserError: state.api.getUser?.error,

        isLiveMode: state.liveMode?.isLiveModeEnabled,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setLastNotificationIdentifier,
    clearLastNotificationIdentifier,
    setIsLiveModeEnabled
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSubscriptionHandler)