import {API_URL, apiConstants, VAPOR_FILE_UPLOAD} from "../../../constants/apiConstants"
import {authService} from "../../../services/authService"
import {authConstants} from "../../../constants/authConstants";
import axios from "axios";
import {vaporStore} from "../../../services";
import NetInfo from "@react-native-community/netinfo";
import {store} from "../../storeConfig/store";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {notifyBugsnag} from "@common/utils/initBugsnag";

export function setNetworkOn() {
    return {type: apiConstants.NETWORK_ON}
}

export function setNetworkOff() {
    return {type: apiConstants.NETWORK_OFF}
}

export function apiIdle(serviceFunction, data = {}) {
    return {type: serviceFunction.name + apiConstants._IDLE}
}

// Action API call
export function apiRequest(serviceFunction, routeParams = {}, data = {}, urlParams = {}, onUploadProgress = null) {

    function request() {
        return {type: serviceFunction.name + apiConstants._REQUEST, routeParams, data, urlParams}
    }

    function success(responseData) {
        return {
            type: serviceFunction.name + apiConstants._SUCCESS,
            routeParams,
            data: responseData.data,
            all_data: responseData,
            urlParams
        }
    }

    function failure(error) {
        return {type: serviceFunction.name + apiConstants._FAILURE, routeParams, error, urlParams}
    }

    const dispatchRequestIfInternet = (dispatch) => {

        const token = store.getState()?.data?.session?.object?.token
        if (token) axios.defaults.headers.common['Authorization'] = "Bearer " + token;

        NetInfo.fetch().then(state => {
            // console.log('API : Connection type', state.type);
            // console.log('API : Is connected?', state.isConnected);

            if (state.isConnected) {
                dispatch(request())

                serviceFunction(routeParams, data, urlParams, onUploadProgress)
                    .then(response => {
                            // console.log('success dispatched : ', response)
                            dispatch(success(response.data))
                        }
                    )
                    .catch(error => {
                        console.error('failure dispatched : ', error)

                        if (!error?.response) {
                            notifyBugsnag(error)
                        }

                        dispatch(failure(error?.response?.data))

                        if (error?.response && error?.response?.status === 401) {
                            dispatch({type: authConstants.LOGOUT_401})
                            // return Promise.reject()
                        }

                    })
            } else {
                DialogM.show({
                    text1: "Pas de connexion Internet",
                    text2: '',
                    buttonText1: 'Réessayer',
                    onPress: () => {
                        dispatchRequestIfInternet(dispatch)
                    },
                    buttonText2: 'Annuler'
                })
                /*alertPolyfill("Pas de connexion Internet", "", [
                    {
                        text: 'Annuler',
                        onPress: () => {
                        },
                        style: 'cancel'
                    },
                    {
                        text: 'Réessayer',
                        onPress: () => {
                            dispatchRequestIfInternet(dispatch)
                        },
                        style: 'cancel'
                    }
                ])*/
            }
        });
    }


    return dispatch => {
        // console.log('request dispatched : ', routeParams, data, urlParams)
        dispatchRequestIfInternet(dispatch)

    }
}

export function vaporFileUploadIdle() {
    return {type: VAPOR_FILE_UPLOAD + apiConstants._IDLE}
}

// Action API call upload file to vapor s3
export function vaporFileUpload(file, options = {}, formId) {

    function request() {
        return {type: VAPOR_FILE_UPLOAD + apiConstants._REQUEST, file, formId}
    }

    function success(response) {
        return {type: VAPOR_FILE_UPLOAD + apiConstants._SUCCESS, response, file, formId}
    }

    function failure(error) {
        return {type: VAPOR_FILE_UPLOAD + apiConstants._FAILURE, error, file, formId}
    }

    const dispatchRequestIfInternet = (dispatch) => {


        NetInfo.fetch().then(state => {
            // console.log('API : Connection type', state.type);
            // console.log('API : Is connected?', state.isConnected);

            if (state.isConnected) {
                dispatch(request())

                vaporStore(file, {
                    ...options,
                    baseURL: API_URL
                })
                    .then(response => {
                        // console.log('success dispatched : ', response)
                        dispatch(success(response))
                    })
                    .catch(error => {
                        // console.error('failure dispatched : ', error)

                        dispatch(failure(error))
                    })

            } else {
                DialogM.show({
                    text1: 'Pas de connexion Internet',
                    text2: '',
                    buttonText1: 'Réessayer',
                    onPress: () => {
                        dispatchRequestIfInternet(dispatch)
                    },
                    buttonText2: 'Annuler'
                    // onPressBack: resetSelection
                })
            }
        })
    }


    return dispatch => {
        // console.log('request dispatched : ', routeParams, data, urlParams)
        // dispatch(request())

        dispatchRequestIfInternet(dispatch)

    }
}

// Action API call
export function apiRequestWithPrerequest(serviceFunction, routeParams = {}, data = {}, urlParams = {}, onUploadProgress = null) {

    function request() {
        return {type: serviceFunction.name + apiConstants._REQUEST, routeParams, data, urlParams}
    }

    function success(data) {
        return {type: serviceFunction.name + apiConstants._SUCCESS, data}
    }

    function failure(error) {
        return {type: serviceFunction.name + apiConstants._FAILURE, error}
    }

    return dispatch => {
        // console.log('request dispatched')
        dispatch(request())

        authService.getCsrfToken()
            .then(() => {

                serviceFunction(routeParams, data, urlParams, onUploadProgress)
                    .then(response => {
                            // console.log('success dispatched')
                            dispatch(success(response.data.data))
                        }
                    )
                    .catch(error => {
                        console.error('failure dispatched : ', error)
                        if (!error?.response) {
                            notifyBugsnag(error)
                        }

                        dispatch(failure(error?.response?.data))
                    })

            }).catch(error => {
                console.error('failure dispatched : ', error)
                if (!error?.response) {
                    notifyBugsnag(error)
                }

                dispatch(failure(error?.response?.data))
            }
        )
    }
}

