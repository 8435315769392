import React, {useContext, useRef, useState} from "react";
import TextM from "../../../common/components/text/TextM";
import {PaginatedTableM} from "../../PaginatedTableM";
import {backOfficeService} from "../../../data/services/backOfficeService";
import ServerSearch from "../../ServerSearch";
import {Dimensions, View} from "react-native";
import TitleM from "../../../common/components/text/TitleM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {theme} from "../../../common/theme/theme";
import {HeaderContainerM} from "../../../common/header/HeaderContainerM";
import {ScrollView} from "react-native-gesture-handler";
import {DimensionsContext, IS_WEB} from "../../../common/utils/mobileUtils";
import {connect} from "react-redux";
import {TouchableOpacity} from "@gorhom/bottom-sheet";
import moment from "moment";
import {capitalize} from "../../../data/utility/Utils";
import AvatarM from "../../../common/components/avatar/AvatarM";
import FilledButtonM from "../../../common/components/button/FilledButtonM";
import EditBillingModal from "./EditBillingModal";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {useApiStatus} from "../../../common/hooks/useApiStatus";
import {DialogM} from "../../../common/components/alert/dialog/Dialog";
import ChangeStatusModal from "./ChangeStatusModal";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {EngagementScoreComponent} from "../LiveReportsScreen";
import {IconImageM} from "../../../common/components/icons/IconsM";
import live from "../../../../assets/icons/generic/live.png";
import {roomTypes} from "../../../data/constants/formConstants";
import {Rating} from "react-native-ratings/src";
import {AudioRecordingContainer} from "../../../student/activity/execute/AudioRecordingContainer";
import {formatHoursMinutesAndSeconds} from "@common/liveMode/live-report/LiveReportComponent";

const LivesHistoryScreen = (props) => {
    const [urlParams, setUrlParams] = useState({});

    const navigation = useNavigation();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openBillingModal, setOpenBillingModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);


    const handleOpenBillingModal = () => setOpenBillingModal(!openBillingModal);
    const handleOpenStatusModal = () => setOpenStatusModal(!openStatusModal);

    const flatListRef = useRef();

    const dimensions = useContext(DimensionsContext);

    const columnHeight = 70

    useApiStatus(
        backOfficeService.deleteLiveSession, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
        }
    );

    const handleRowPress = (rowId) => {
        setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
    };

    const renderTextOrVoice = (data) => {
        if (data?.comment_record) {
            return <View
                style={{
                    width: dimensions.width > 800 ? "80%" : "100%",
                    alignItems: "center",
                    height: columnHeight
                }}
            >
                <AudioRecordingContainer readonly recordingURI={data?.comment_record}/>
            </View>;
        } else {
            return <TextM>{data?.tutor_comment}</TextM>;
        }
    };

    const columns = [
        {
            name: "Date de l'occurrence",
            style: {
                width: 140,
                marginRight: 20,
                justifyContent: "center"
            },
            clickable: true,
            selector: "date",
            sortable: true,
            cell: (row) => {
                const sessionDate = capitalize(
                    moment(row?.date).format("dddd DD MMMM HH:mm")
                );
                return (
                    <View>
                        <TouchableOpacity
                            disabled={row?.live_reports?.length === 0}
                            onPress={() => {
                                handleRowPress(row?.id)
                            }}
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            {row?.live_reports?.length > 0 && (
                                <FontAwesome5
                                    name={expandedRow === row?.id ? "caret-down" : "caret-right"}
                                    color={theme.colors.primary}
                                    solid
                                    size={20}
                                    style={{marginRight: 5}}
                                />
                            )}
                            <TextM
                                wrap
                                color={
                                    row?.live_reports?.length > 0
                                        ? theme.colors.primary
                                        : theme.colors.grey
                                }
                                fontWeight={row?.live_reports?.length > 0 ? "Bold" : "Medium"}
                            >
                                {sessionDate}
                            </TextM>
                        </TouchableOpacity>
                    </View>
                );
            }
        },
        {
            name: "Tuteur",
            style: {
                width: 180,
                marginRight: 20,
                justifyContent: "center"
            },
            selector: "tutor",
            sortable: true,
            cell: (row) => (
                <View
                    style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row"
                    }}
                >
                    <AvatarM user={row.tutor} size={32}/>

                    <View style={{marginLeft: 15}}>
                        <TextM
                            color={theme.colors.primary}
                            fontWeight={"Medium"}
                            wrap
                            style={{maxWidth: 100}}
                        >{`${row?.tutor?.firstname} ${row?.tutor?.lastname}`}</TextM>
                        <TextM
                            color={theme.colors.grey}
                            fontWeight={"Light"}
                            wrap
                            fontSize={10}
                            style={{maxWidth: 100}}
                        >{`${row?.tutor?.email}`}</TextM>
                    </View>
                </View>
            )
        },
        {
            name: "Type de live",
            style: {
                width: 120,
                marginRight: 20,
            },
            selector: "type",
            sortable: true,
            cell: (row) => (
                <TextM fontWeight={"Bold"} wrap>
                    {roomTypes?.find((x) => x?.value === row?.type)?.label}
                </TextM>
            )
        },
        {
            name: "Elèves connectés /inscrits",
            style: {
                width: 100,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) => (
                <TextM
                    fontWeight={"Bold"}
                    color={
                        row?.statistics?.students_connected_count?.with_altert
                            ? theme.colors.warning
                            : theme.colors.grey
                    }
                >
                    {row?.statistics?.students_connected_count?.value ?? "-"}/
                    {row?.statistics?.students_registered_count?.value ?? "-"}
                </TextM>
            )
        },
        {
            name: "Score d’engagement moyen (min)",
            style: {
                width: 100,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) =>
                row?.statistics?.avg_total_score?.value ? (
                    <TextM
                        fontWeight={"Bold"}
                        color={
                            row?.statistics?.avg_total_score?.with_altert
                                ? theme.colors.warning
                                : theme.colors.grey
                        }
                        wrap>
                        {row?.statistics?.avg_total_score?.value} (
                        {row?.statistics?.min_total_score?.value})
                    </TextM>
                ) : <TextM>-</TextM>
        },
        {
            name: "Temps d’interaction / connexion tuteur",
            style: {
                width: 120,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) => {

                return row?.statistics ? (
                    <View>
                        <TextM
                            fontWeight={"Bold"}
                            color={
                                row?.statistics?.students_interaction_duration?.with_alert
                                    ? theme.colors.warning
                                    : theme.colors.grey
                            }
                            wrap>
                            {formatHoursMinutesAndSeconds(row?.statistics?.students_interaction_duration?.value * 60 ?? " - ", 10, true)}{" "}
                        </TextM>
                        <TextM
                            fontWeight={"Bold"}
                            color={
                                row?.statistics?.tutor_connection_duration?.with_alert
                                    ? theme.colors.warning
                                    : theme.colors.grey
                            }
                            wrap>
                            / {formatHoursMinutesAndSeconds(row?.statistics?.tutor_connection_duration?.value * 60, 10, true)}
                        </TextM>
                    </View>
                ) : <TextM>- / -</TextM>
            }

        },
        {
            name: "Qualité du Live (tuteur)",
            style: {
                width: 300,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) => (
                <View style={{alignItems: "flex-start"}}>
                    <Rating
                        ratingCount={5}
                        readonly
                        startingValue={row?.tutor_live_quality_score}
                        imageSize={25}
                        style={{marginRight: 40, marginBottom: 10}}
                    />
                    <TextM wrap fontSize={12}>{row?.tutor_comment ?? "-"}</TextM>
                </View>
            )
        },
        {
            name: "Etat",
            style: {
                width: 140,
                marginRight: 20,
            },
            selector: "status",
            sortable: true,
            cell: (row) => (
                <TouchableOpacity
                    onPress={() => {
                        setCurrentAction("etat");
                        handleOpenStatusModal();
                        setSelectedIndex(row?.id);
                    }}
                    style={{cursor: "pointer"}}
                >
                    {props.editStatusRequestOccurrenceId === row?.id &&
                    currentAction === "etat" ? (
                        <ActivityIndicator color={theme.colors.primary}/>
                    ) : (
                        <View
                            style={{
                                borderWidth: 0,
                                borderColor: theme.colors.white,
                                backgroundColor:
                                    row.status === "pending_review"
                                        ? theme.colors.warning
                                        : row.status === "canceled"
                                            ? theme.colors.grey
                                            : theme.colors.success,
                                borderRadius: 15,
                                paddingHorizontal: 8,
                                paddingVertical: 4,
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <TextM fontWeight="SemiBold" color={theme.colors.white}>
                                {" "}
                                {row.status === "pending_review"
                                    ? "En attente"
                                    : row.status === "canceled"
                                        ? "Annulé"
                                        : "Validé"}
                            </TextM>
                        </View>
                    )}
                    {row?.id === selectedIndex && (
                        <ChangeStatusModal
                            handleShowModal={handleOpenStatusModal}
                            isOpen={openStatusModal}
                            data={row}
                            apiRequest={props.apiRequest}
                        />
                    )}
                </TouchableOpacity>
            )
        },
        {
            name: "Unités de Live",
            style: {
                width: 100,
                marginRight: 20,
            },
            selector: "live_units",
            sortable: true,
            cell: (row) => <TextM fontWeight={"Bold"}>{row?.live_units}</TextM>
        },
        {
            name: "Clôture complète",
            style: {
                width: 100,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) => (
                <TextM
                    fontWeight={"Bold"}
                    color={
                        row?.statistics?.is_finalized?.with_altert
                            ? theme.colors.warning
                            : theme.colors.grey
                    }
                >
                    {row?.statistics?.is_finalized?.value ? "Oui" : "Non"}
                </TextM>
            )
        },
        {
            name: "Facturation",
            style: {
                width: 200,
                marginRight: 20
            },
            selector: "statistics",
            sortable: false,
            cell: (row) => (
                <TouchableOpacity
                    style={{
                        flexDirection: "column",
                        cursor: "pointer"
                    }}
                    onPress={() => {
                        if (row?.tutoring_invoice_attachment) {
                            if (IS_WEB) {
                                window.open(row?.tutoring_invoice_attachment);
                            } else {
                                WebBrowser.openBrowserAsync(row?.tutoring_invoice_attachment);
                                navigation.goBack();
                            }
                        } else {
                            setSelectedIndex(row?.id);
                            handleOpenBillingModal();
                        }
                    }}
                >
                    <TextM>
                        Transfert au tuteur:{" "}
                        <TextM fontWeight={"SemiBold"}>{row?.transfer_amount}</TextM>
                    </TextM>
                    <TextM>
                        Crédits de tutorat:{" "}
                        <TextM fontWeight={"SemiBold"}>{row?.tutoring_credit_amount}</TextM>
                    </TextM>
                    <TextM>
                        Commission:{" "}
                        <TextM fontWeight={"SemiBold"}>{row?.application_fee_amount}</TextM>
                    </TextM>
                    <TextM>
                        Compensation:{" "}
                        <TextM fontWeight={"SemiBold"}>
                            {row?.application_compensation_amount}
                        </TextM>
                    </TextM>
                    {row?.id === selectedIndex && (
                        <EditBillingModal
                            data={row}
                            handleShowModal={handleOpenBillingModal}
                            isOpen={openBillingModal}
                            apiRequest={props.apiRequest}
                            isLoading={props.editing}
                        />
                    )}
                </TouchableOpacity>
            )
        },
        {
            name: "Session live",
            style: {
                width: 140,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) => (
                <TouchableOpacity
                    style={{cursor: "pointer"}}
                    onPress={() => {
                        navigation.push("live-show", {
                            live_id: row?.id
                        });
                    }}
                >
                    <IconImageM source={live} width={30} style={{}}/>
                </TouchableOpacity>
            )
        },
        {
            name: "",
            style: {
                width: 160,
                marginRight: 20,
            },
            selector: "statistics",
            sortable: true,
            cell: (row) =>
                props.deleteOccurrenceRequestOccurrenceId === row.id ? (
                    <ActivityIndicator color={theme.colors.primary}/>
                ) : (
                    <FilledButtonM
                        onPress={() => {
                            DialogM.show({
                                text1: "Supprimer cette occurrence ?",
                                buttonText1: "Supprimer",
                                buttonColor1: theme.colors.error,
                                onPress: () => {
                                    props.apiRequest(backOfficeService.deleteOccurrence, {
                                        id: row.id
                                    });
                                },
                                buttonText2: "Annuler"
                            });
                        }}
                        label={"Supprimer"}
                        icon={"trash"}
                        color={theme.colors.error}
                        style={{
                            width: "100%",
                            marginTop: 7,
                            alignSelf: "center"
                        }}
                        labelStyle={{fontSize: 10}}
                        contentStyle={{height: 25}}
                    />
                )
        }
    ];

    const renderHeader = () => (
        <>
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 20
                }}
            >
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        // position: "absolute",
                        marginRight: 10
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack();
                        else navigation.replace("menu");
                    }}
                />
                <TitleM>Historique des lives</TitleM>
            </View>
            <View style={{flexDirection: "row", alignItems: "center"}}>
                <ServerSearch
                    style={{
                        width: 240,
                        margin: 10
                    }}
                    urlParams={urlParams}
                    setUrlParams={setUrlParams}
                />
            </View>
        </>
    );

    const renderExpandedRowContent = (row) => {
        return (
            <View style={{}}>
                {row.live_reports.map((report, index) => (
                    <View key={index}
                          style={{flexDirection: "row", alignItems: "center", marginBottom: 15, marginLeft: 10}}>

                        <View style={{flexDirection: "row", alignItems: "center", marginRight: 15, width: 150}}>
                            <AvatarM user={report.student} size={32}/>
                            <View style={{marginLeft: 10}}>
                                <TextM color={theme.colors.primary} fontWeight={"Medium"}>
                                    {report.student.display_name}
                                </TextM>
                                <TextM color={theme.colors.grey} fontWeight={"Light"} fontSize={10}>
                                    {report.student.profile?.class}
                                </TextM>
                            </View>
                        </View>

                        <View style={{width: 600, marginRight: 15}}>
                            <EngagementScoreComponent
                                totalScore={report?.statistics?.scores?.total_score}
                                scores={report?.statistics?.scores}
                                width={600}

                            />
                        </View>
                        <View style={{width: 200, alignItems: "flex-start"}}>
                            <Rating
                                imageSize={20}
                                readonly
                                startingValue={report?.satisfaction}
                                ratingCount={5}
                            />
                            <TextM wrap fontSize={10}>{report?.student_comment?.join(", ") ?? "-"}</TextM>
                        </View>

                        <View style={{width: 400}}>
                            <TextM wrap fontSize={12}>
                                {report?.tutor_comment?.length > 0 ? report?.tutor_comment : " - "}
                            </TextM>
                        </View>

                        <View style={{flexDirection: "row", alignItems: "center", width: 300}}>
                            <TextM>Nb d’activités validées / envoyées</TextM>
                            <TextM fontWeight={"SemiBold"} style={{marginLeft: 5}}>
                                {report?.statistics?.validated_activities_count ?? 0}/
                                {report?.statistics?.done_activities_count ?? 0}
                            </TextM>
                        </View>
                    </View>
                ))}
            </View>

        );
    };

    return (
        <View
            style={{
                alignSelf: "center",
                alignItems: "center",
                width: Dimensions.get("window").width,
                flex: 1
            }}
        >
            <HeaderContainerM
                style={{justifyContent: "space-between", width: "100%"}}
            >
                {dimensions?.width < 800 ? (
                    <ScrollView horizontal={true} style={{width: "100%"}}>
                        <View style={{flexDirection: "row"}}>{renderHeader()}</View>
                    </ScrollView>
                ) : (
                    <>{renderHeader()}</>
                )}
            </HeaderContainerM>
            <PaginatedTableM
                data={props.liveOccurrences}
                flatListRef={null}
                columns={columns}
                headerHeight={60}
                requestService={backOfficeService.getLiveOccurrences}
                urlParams={urlParams}
                expandedRow={expandedRow}
                expandedRowContent={renderExpandedRowContent}
            />
        </View>
    );
};
const mapStateToProps = (state) => {
    return {
        liveOccurrences: state.data?.backOffice?.liveOccurrences,
        editing: state.api?.editBilling?.request,
        editStatusRequestOccurrenceId: state.api.editBilling?.routeParams?.id,
        deleteOccurrenceRequestOccurrenceId:
        state.api.deleteOccurrence?.routeParams?.id
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(LivesHistoryScreen);
