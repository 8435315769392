import {Platform, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {IconImageM} from "@common/components/icons/IconsM";
import SuccessAnimation from "@common/animations/SuccessAnimation";
import {Avatar} from "react-native-paper";
import React, {useContext, useEffect} from 'react';
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ActivityXpKeyValueItem} from "../read/ActivityXpKeyValueItem";
import {useActivityFinalized} from "@common/messenger/socketHook";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";


const ActivityFinalizedCongrats = props => {

    const {activity} = props

    const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, props.user.challenges) : null
    const schoolSubject = activity?.school_subject_id ? findItemInListWithId(activity?.school_subject_id, props.schoolSubjects) : null
    const liveSession = props.currentLiveSession

    /*    if (props.isLiveMode) {
            console.log("finalized")
            useActivityFinalized({
                channel: `presence-messenger.thread.${liveSession?.thread_id}`,
                id: props.user?.id
            })
        }*/
    const finalize = useActivityFinalized({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: props.user?.id,
        displayName: props.user?.display_name,
    })

    const iconSize = 80
    const successSize = iconSize * 4 / 5
    const dimensions = useContext(DimensionsContext)

    useEffect(() => {
        PlaySoundM.play(require('../../../../assets/sounds/finished.mp3'))
        if (props.isLiveMode)
            finalize()
    }, [])

    return <View style={{
        alignItems: "center",
        width: dimensions?.width,
        paddingHorizontal: 15,
        alignSelf: Platform.OS !== 'web' ? null : "center"
    }}>
        <TextM fontSize={34}
               fontWeight="ExtraBold"
               color={theme.colors.success}
               style={{textAlign: "center", alignSelf: "center", marginVertical: 20}}
               wrap
        >
            {/*{congratsMessage(activity).toUpperCase()}*/}
            ACTIVITÉ TERMINÉE !
        </TextM>

        <View style={{
            marginTop: 0,
            alignItems: "center",
            justifyContent: "center"
        }}>


            <Avatar.Image size={iconSize * 1.4}
                          style={{
                              backgroundColor: theme.colors.white,
                              borderColor: theme.colors.success
                              // borderWidth: 1
                          }}/>

            <IconImageM width={iconSize}
                        style={{
                            position: "absolute"
                            // opacity: (0.5)
                        }}
                        uri={activityTemplate?.icon}/>
            <SuccessAnimation
                size={successSize}
                iconSize={successSize * 0.7}
                dotColor={theme.colors.success}
                backgroundColor={theme.colors.success}
                iconColor={"white"}
                dotSize={20}
                duration={Platform.OS !== 'web' ? 2000 : 500}
                style={{
                    position: "absolute",
                    // alignSelf: "center",
                    top: -successSize / 3,
                    right: -successSize / 3
                    // right: -30
                }}
                animatedLayerColor={"white"}
            />
        </View>

        <TextM fontSize={20}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center",
                   marginTop: -10
               }}
               color={theme.colors.primary}>{activityTemplate?.title}</TextM>

        {(challenge || schoolSubject) && <View style={{
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            // width: "100%",
            paddingTop: 5
            // paddingBottom: 5
        }}>
            {/*<IconImageM*/}
            {/*    // uri={challenge?.school_subject?.icon}*/}
            {/*    uri={schoolSubject ? schoolSubject?.icon : challenge?.icon}*/}
            {/*    width={16}*/}
            {/*    style={{*/}
            {/*        marginRight: 10,*/}
            {/*        marginBottom: 0*/}
            {/*    }}/>*/}
            <View style={{
                // flex: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row"
            }}>
                <TextM fontWeight="SemiBold"
                       color={theme.colors.grey}
                       fontSize={12}>{schoolSubject ? schoolSubject?.name : challenge?.title}</TextM>
                {challenge && <TextM fontWeight="Medium"
                                     color={theme.colors.light}
                                     fontSize={12}> - {challenge?.school_subject?.name}</TextM>}
            </View>


        </View>}

        <View style={{
            alignItems: "center",
            width: dimensions?.width - 20,
            marginTop: 35
        }}>
            <ActivityXpKeyValueItem activity={activity} large/>

            {/*<ActivityScoringKeyValueItem activity={activity} large style={{marginTop: 10}}/>*/}


        </View>
        {/*<View style={{height: 100}}/>*/}

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        isLiveMode: state.liveMode?.isLiveModeEnabled

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFinalizedCongrats)