import {useNavigation} from "@react-navigation/native";
import moment from "moment";
import {useSelector} from "react-redux";

const useLaunchActivity = () => {

    const navigation = useNavigation();

    const user = useSelector(state => state.data?.currentUser?.object)

    const navigateToActivityScreen = (activityData, navigationType = "push", noFeeling = false) => {
        // console.log(activityData)
        const data = {
            chapterName: activityData?.student_chapter?.chapter?.name ?? activityData?.chapterName,
            chapterId: activityData?.student_chapter?.id ?? activityData?.chapterId,
            activityTemplateId: activityData?.activity_template_id ?? activityData?.activityTemplateId,
            activityId: activityData?.activityId ?? activityData?.id,
            schoolSubjectId: activityData?.school_subject_id ?? activityData?.schoolSubjectId,
            challengeId: activityData?.challengeId,
            againActivityId: activityData?.againActivityId,
        }
        const lastFeelingSentAt = activityData?.student_chapter?.last_feeling_sent_at ?? activityData?.lastFeelingSentAt

        // const with condition : all last_feeling_sent_at are either >= 7 days or null, for all ongoing_student_chapters of currentUser
        const ongoingStudentChapters = user?.ongoing_student_chapters
        const allLastFeelingSentAt = ongoingStudentChapters?.map(ongoingStudentChapter => ongoingStudentChapter?.last_feeling_sent_at)
        const allLastFeelingSentAtAreNullOrOlderThan7Days = allLastFeelingSentAt?.reduce((prev, cur) => prev && (!cur || moment().diff(cur, 'days') >= 7), true)

        if ((!lastFeelingSentAt || allLastFeelingSentAtAreNullOrOlderThan7Days) && !noFeeling && !!data?.chapterId && ongoingStudentChapters?.map(x => x?.id)?.includes(data?.chapterId)) {
            if (navigationType === "push")
                navigation.push("feeling", {
                    ...data,
                })
            else if (navigationType === "replace")
                navigation.replace("feeling", {
                    ...data,
                })
        } else if (navigationType === "push")
            navigation.push("activity", {
                ...data
            })
        else if (navigationType === "replace")
            navigation.replace("activity", {
                ...data
            })
    };

    return navigateToActivityScreen;
};

export default useLaunchActivity;