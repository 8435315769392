import {useNavigation} from "@react-navigation/native";
import {Dimensions, TouchableOpacity, Vibration, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import AvatarM from "@common/components/avatar/AvatarM";
import TextM from "@common/components/text/TextM";
import React, {useContext, useRef, useState} from "react";
import {IconTouchableRipple} from "./IconTouchableRipple";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {schoolsService} from "@data/services/schoolsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import LiveAvatarM from "./LiveAvatarM";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {RealizationsListItemContent} from "../../student/organize/list/RealizationsListItemContent";
import TodoModal from "../../student/activity/todo/TodoModal";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {activitiesService} from "@data/services/activitiesService";
import {FlatList} from "react-native-gesture-handler";
import {hasRole} from "@data/utility/ability";
import DraggableFlatList from "react-native-draggable-flatlist";
import moment from "moment";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";
import {setTodoActivities} from "@data/redux/actions/data";
import {Checked} from "@common/components/icons/IconsM";
import {useNewTodo} from "@common/messenger/socketHook.web";
import {SchoolSubjectBadge} from "./ProfilDetails";
import {calculateSumOfEstimatedTime} from "./StudentsListItem";
import IconButtonM from "../../common/components/button/IconButtonM";
import ChaptersList from "../../student/activity/todo/ChaptersList";

const MIN_STUDENTS = 2;
const MAX_STUDENTS = 5;

function NewStudentsListItem(props) {
    const {
        student,
        index,
        period,
        menuItems = [],
        isLoading,
        isLiveMode = false,
        additionalActions = [],
        style,
        isTutor = false,
        withTodo = false,
        soundMode = false,
        studentsNumber,
        byChapter
    } = props;
    const navigation = useNavigation();
    const liveSession = props.currentLiveSession;
    const dimensions = useContext(DimensionsContext);
    const studentsPerScreen = Math.max(MIN_STUDENTS, Math.min(MAX_STUDENTS, studentsNumber));
    const width = withTodo
        ? dimensions.width > 800
            ? (Dimensions.get("window").width - 60) / studentsPerScreen
            : Dimensions.get("window").width - 60
        : dimensions.width - 20;
    const activityFinalizedCount =
        liveSession && props.isLive
            ? liveSession?.users?.find((u) => u?.id === student?.id)?.statistics?.today
                ?.activities?.count
            : student?.statistics?.today?.activities?.count;
    const isTutorsStudent =
        !!props.user?.students?.find((x) => x?.id === student?.id) ||
        liveSession?.users?.find((u) => u?.id === student?.id);
    const isOnline = !!props.participants?.find((x) => x === student?.id);
    const draggableFlatListRef = useRef();
    const flatListRef = useRef();
    const chaptersListRef = useRef();

    const [isOpen, setOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const handleShowModal = () => setOpen(!isOpen);
    const todos = student?.todo_activities;

    const totalEstimatedTime = calculateSumOfEstimatedTime(
        todos,
        "estimated_duration"
    );
    const finishedActivities = student?.activities?.filter((activity) =>
        moment(activity.finished_at).isSame(moment(), "day")
    );
    const selectedItem = todos?.concat(finishedActivities)?.find((todo) => todo.id === selectedItemId);

    const [deleteOrIndexesRequest, setDeleteOrIndexesRequest] = useState(false);

    // useEffect(() => {
    //     // console.log("CHANGE")
    //     setTodos(student?.todo_activities)
    //     setFinshedActivities(student?.activities?.filter((activity) => moment(activity.finished_at).isSame(moment(), 'day')))
    // }, [student?.todo_activities, student?.activities])
    const onDeleteTodo = (todoId) => {
        DialogM.show({
            text1: "Veux-tu vraiment supprimer cette activité ?",
            buttonText1: "Supprimer",
            buttonColor1: theme.colors.error,
            onPress: () => {
                setDeleteOrIndexesRequest(true);
                props.apiRequest(activitiesService.deleteActivityTodo, {id: todoId});
            },
            buttonText2: 'Annuler '
        })
    }

    const newTodoEvent = useNewTodo({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: student?.id
    });

    useApiStatus(
        activitiesService.deleteActivityTodo, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (deleteOrIndexesRequest) {
                newTodoEvent();
                setDeleteOrIndexesRequest(false);
            }
        } // success callback
    );

    useApiStatus(
        activitiesService.activityTodoIndexesAsTutor, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (deleteOrIndexesRequest) {
                newTodoEvent();
                setDeleteOrIndexesRequest(false);
            }
        } // success callback
    );

    const windowWidth = Dimensions.get("window").width;
    const renderActionButtons = () => {
        return (
            <>
                {/*<IconTouchableRipple*/}
                {/*    icon={"id-badge"}*/}
                {/*    label={"Profil"}*/}
                {/*    onPress={() => {*/}
                {/*        */}
                {/*    }}/>*/}
                {!withTodo && (
                    <>
                        <IconTouchableRipple
                            icon={"bullseye"}
                            label={"Activités"}
                            badgeCounter={activityFinalizedCount}
                            onPress={() => {
                                navigation.push("student", {
                                    studentId: student?.id
                                });
                            }}
                        />
                    </>
                )}
            </>
        );
    };

    useApiStatus(
        activitiesService.deleteActivitiesTodoAsTutor,
        null,
        true,
        () => {
            newTodoEvent();
        }
    );

    useApiStatus(
        schoolsService.removeStudentFromClassroom,
        "L'élève a bien été retiré de la classe",
        true
    );
    const referenceHeight = 550;
    const maxHeight = (dimensions.height / referenceHeight) * 220;

    const renderItem = ({item, drag, isActive}) => {
        const userChallenges = student?.challenges?.concat(
            student?.history?.challenges
        );
        const challenge = item?.challenge_id
            ? findItemInListWithId(item?.challenge_id, userChallenges)
            : null;
        const schoolSubject = findItemInListWithId(
            item?.school_subject_id,
            props.schoolSubjects
        );
        const schoolSubjectColor = challenge
            ? challenge?.school_subject?.color
            : schoolSubject?.color === ""
                ? theme.colors.info
                : schoolSubject?.color;

        return (
            <TouchableOpacity
                onPress={() => {
                    setSelectedItemId(item?.id);
                    handleShowModal();
                }}
                // onLongPress={drag}
                // delayLongPress={300}
                disabled={isActive}
                style={{
                    backgroundColor: theme.colors.white,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: schoolSubjectColor,
                    marginBottom: 10,
                    width: width - 40,
                    alignSelf: 'center'
                }}
            >
                <>
                    <RealizationsListItemContent
                        activity={item}
                        dragButton={drag}
                        challenge={challenge}
                        schoolSubject={findItemInListWithId(
                            item?.school_subject_id,
                            props.schoolSubjects
                        )}
                        noBorder={true}
                        noClass={props.user?.id === item?.student_id}
                        todo={true}
                        liveMode={false}
                        tinyMode={studentsPerScreen === 5}
                        custom
                        currentUser={props.user}
                    />
                    {props.deleteTodoRequest &&
                        props.deleteTodoRequestActivityId === item?.id && (
                            <ActivityIndicator
                                animating={true}
                                color={theme.colors.primary}
                                size={14}
                                style={{
                                    position: "absolute",
                                    top: -2,
                                    right: -2
                                }}
                            />
                        )}
                </>
            </TouchableOpacity>
        );
    };
    return (
        <View
            key={index}
            style={{
                ...boxedCardBorderStyle,
                width: width,
                // height: dimensions.height / 1.5,
                marginBottom: 10,
                marginHorizontal: 5,
                backgroundColor: theme.colors.white,
                borderRightWidth: soundMode ? 2 : 1,
                borderLeftWidth: soundMode ? 2 : 1,
                borderTopWidth: soundMode ? 2 : 1,
                borderBottomWidth: soundMode ? 2 : 1,
                borderColor: !isOnline
                    ? theme.colors.lightMore
                    : !soundMode && !isTutor
                        ? theme.colors.purple
                        : theme.colors.success,
                alignSelf: "stretch",
                borderRadius: 10,
                flex: 1,
                ...style
            }}
        >
            <View
                style={{
                    // width: width,
                    //flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // paddingVertical: 12,
                    paddingHorizontal: 10,
                    flex: 1
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        paddingVertical: 10
                    }}>
                    <TouchableOpacity
                        style={{}}
                        onPress={() => {
                            if (!isTutor)
                                navigation.push("student-profil", {
                                    studentId: student?.id,
                                    withActions: true
                                });
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start"
                            }}
                        >
                            {isLiveMode ? (
                                <LiveAvatarM user={student} size={studentsPerScreen === 5 ? 40 : 50}/>
                            ) : (
                                <AvatarM user={student} style={{marginRight: 2}} size={40}/>
                            )}

                            <View
                                style={{
                                    marginLeft: 5,
                                    maxWidth: width - 220
                                }}
                            >
                                <TextM
                                    fontWeight="SemiBold"
                                    fontSize={14}
                                    color={theme.colors.primary}
                                >
                                    {student?.display_name}
                                </TextM>
                                <TextM fontWeight="Medium" style={{}} fontSize={11}>
                                    {student?.profile?.class}
                                </TextM>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start"
                                    }}
                                >
                                    {student?.prioritized_school_subjects?.map((item) => {
                                        return (
                                            <SchoolSubjectBadge key={item?.id} schoolSubject={item}/>
                                        );
                                    })}
                                </View>
                            </View>
                        </View>
                        {/* {
                                !isTutor && <View style={{marginRight: 8}}>
                                    <SkillSetTitleFull title={student?.student_title}
                                                       icon={student?.student_title_icon}
                                                       skillSetId={student?.title_skill_set_id}
                                                       right
                                                       small/>
                                    <XPCount count={student?.xp}
                                             right
                                             style={{marginRight: 3}}
                                             small/>
                                </View>
                            }*/}

                    </TouchableOpacity>
                    <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                        {additionalActions?.map((a, index) =>
                            a?.request ? (
                                a?.requestComponent ? (
                                    <React.Fragment key={index}>
                                        {a?.requestComponent}
                                    </React.Fragment>
                                ) : (
                                    <ActivityIndicator
                                        key={index}
                                        color={theme.colors.primary}
                                        style={{width: 50, marginRight: 5}}
                                    />
                                )
                            ) : (
                                <IconTouchableRipple
                                    key={index}
                                    icon={a?.icon}
                                    iconSize={a?.iconSize}
                                    label={a?.label}
                                    badgeCounter={a?.badgeCounter}
                                    disabled={a?.disabled}
                                    color={a?.color}
                                    fontSize={a?.fontSize ?? 12}
                                    style={{
                                        backgroundColor: a?.backgroundColor,
                                        marginRight: 5,
                                        borderRadius: a?.borderRadius ?? 0,
                                        width: 50,
                                        maxWidth: 60,
                                        height: "100%"
                                    }}
                                    onPress={a?.onPress}
                                />
                            )
                        )}
                    </View>
                </View>

                <View
                    style={{
                        // marginRight: 10
                        flex: 1
                        // backgroundColor: "red",
                        // overflow: "hidden",
                    }}
                >
                    {menuItems?.length > 0 ? (
                        !isLoading ? (
                            <Menu3Point menuItems={menuItems}/>
                        ) : (
                            <ActivityIndicator color={theme.colors.primary}/>
                        )
                    ) : (
                        <></>
                    )}
                    {withTodo && !isTutor && (
                        <>
                            {byChapter ? (
                                <ChaptersList
                                    todos={todos}
                                    schoolSubjects={props.schoolSubjects}
                                    navigation={navigation}
                                    width={width - 40}
                                    style={{width: width - 40}}
                                    chaptersListRef={chaptersListRef}
                                />

                            ) : (
                                <DraggableFlatList
                                    //horizontal={true}
                                    data={todos ?? []}
                                    onDragBegin={() => Vibration.vibrate(50)}
                                    onDragEnd={({data}) => {
                                        props.setTodoActivities(data);
                                        setDeleteOrIndexesRequest(true);
                                        if (hasRole(props.user, "student"))
                                            props.apiRequest(
                                                activitiesService.activityTodoIndexesAsStudent,
                                                {},
                                                {
                                                    todo_activities_ids: data.map((todo) => todo.id)
                                                }
                                            );
                                        else
                                            props.apiRequest(
                                                activitiesService.activityTodoIndexesAsTutor,
                                                {id: student?.id},
                                                {
                                                    todo_activities_ids: data.map((todo) => todo.id)
                                                }
                                            );
                                        Vibration.vibrate(50);
                                    }}
                                    showsHorizontalScrollIndicator={todos?.length > 0}
                                    keyExtractor={(item) => item?.id}
                                    contentContainerStyle={{
                                        //alignItems: "stretch",
                                        paddingVertical: 5
                                    }}
                                    containerStyle={{
                                        // backgroundColor: "blue",
                                        flex: 1
                                    }}
                                    style={{
                                        width:
                                            dimensions.width < 800
                                                ? width - 20
                                                : width
                                        // flex: 1,
                                        // overflow: "hidden",
                                    }}
                                    ref={draggableFlatListRef}
                                    ListEmptyComponent={
                                        <TextM
                                            style={{
                                                textAlign: "center",
                                                marginLeft: 10,
                                                alignSelf: "center"
                                            }}
                                        >
                                            Aucune activité à faire
                                        </TextM>
                                    }
                                    ListHeaderComponent={
                                        <>
                                            <FlatList
                                                //horizontal={true}
                                                data={finishedActivities}
                                                containerStyle={{}}
                                                style={{
                                                    paddingTop: 10
                                                }}
                                                ref={flatListRef}
                                                renderItem={(item) => {
                                                    const userChallenges = student?.challenges?.concat(
                                                        student?.history?.challenges
                                                    );
                                                    const challenge = item.item?.challenge_id
                                                        ? findItemInListWithId(
                                                            item.item?.challenge_id,
                                                            userChallenges
                                                        )
                                                        : null;
                                                    const schoolSubject = findItemInListWithId(
                                                        item.item?.school_subject_id,
                                                        props.schoolSubjects
                                                    );
                                                    const schoolSubjectColor = challenge
                                                        ? challenge?.school_subject?.color
                                                        : schoolSubject?.color === ""
                                                            ? theme.colors.info
                                                            : schoolSubject?.color;

                                                    return (
                                                        <TouchableOpacity
                                                            style={{
                                                                marginBottom: 10
                                                            }}
                                                            onPress={() => {
                                                                setSelectedItemId(item.item?.id);
                                                                handleShowModal();
                                                            }}
                                                        >
                                                            <RealizationsListItemContent
                                                                activity={item.item}
                                                                challenge={challenge}
                                                                schoolSubject={findItemInListWithId(
                                                                    item?.item?.school_subject_id,
                                                                    props.schoolSubjects
                                                                )}
                                                                custom
                                                                noClass={true}
                                                                style={{
                                                                    opacity: 0.5,
                                                                    // minWidth: 160,
                                                                    borderRadius: 10,
                                                                    borderWidth: 1,
                                                                    borderColor: schoolSubjectColor,
                                                                    width: width - 40,
                                                                    alignSelf: 'center'
                                                                }}
                                                                currentUser={props.user}
                                                                liveMode={false}
                                                                tinyMode={studentsPerScreen === 5}
                                                                //noHeight
                                                            />
                                                            <Checked
                                                                size={20}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    right: 20
                                                                }}
                                                            />
                                                        </TouchableOpacity>
                                                    );
                                                }}
                                            />
                                        </>
                                    }
                                    ListFooterComponent={<View style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        alignSelf: "center"
                                    }}>
                                        <IconButtonM
                                            onPress={() => {
                                                navigation.push("student", {
                                                    studentId: student?.id,
                                                    initialTabRealizations: true
                                                });
                                            }}
                                            icon={"history"}
                                            size={20}
                                            style={{
                                                height: 40,
                                                width: 40,
                                                backgroundColor: theme.colors.lightMore,
                                                alignSelf: "center",
                                                borderRadius: 20,
                                                marginRight: 10
                                            }}
                                            iconColor={theme.colors.white}
                                        />
                                        <IconButtonM
                                            onPress={() => {
                                                navigation.push("new-todo", {
                                                    todoId: null,
                                                    studentId: student?.id
                                                });
                                            }}
                                            icon={"plus"}
                                            size={20}
                                            style={{
                                                height: 40,
                                                width: 40,
                                                backgroundColor: theme.colors.primary,
                                                alignSelf: "center",
                                                borderRadius: 20,
                                                marginRight: 10
                                            }}
                                            iconColor={theme.colors.white}
                                        />
                                    </View>
                                    }
                                    renderItem={renderItem}
                                />
                            )}
                        </>
                    )}
                </View>
                {withTodo && !isTutor && (
                    <View
                        style={{
                            borderColor: theme.colors.lightMore,
                            backgroundColor: theme.colors.white,
                            // flex: withTodo ? 0.3 : 3,
                            //height: "100%",
                            flexDirection: "row",
                            //justifyContent: 'space-around',
                            width: "100%",
                            alignItems: "center",
                            marginTop: 10,
                            height: 36
                        }}
                    >
                        {todos?.length > 0 &&
                            (props.deleteActivitiesTodoAsTutorRequest ? (
                                <ActivityIndicator
                                    color={theme.colors.primary}
                                    size={"small"}
                                />
                            ) : (
                                <IconTouchableRipple
                                    icon={"trash"}
                                    iconSize={15}
                                    style={{
                                        //marginLeft: 30,
                                        //marginRight: 30,
                                        paddingHorizontal: 10
                                    }}
                                    label={"Nettoyer"}
                                    horizontal
                                    onPress={() => {
                                        DialogM.show({
                                            champion: "zoe",
                                            text1: `Supprimer toutes les activités de la liste de ${student?.display_name} ?`,
                                            text2: "Cette action est irréversible",
                                            buttonText1: "Supprimer tout",
                                            onPress: () => {
                                                props.apiRequest(
                                                    activitiesService.deleteActivitiesTodoAsTutor,
                                                    {id: student?.id}
                                                );
                                            },
                                            buttonText2: "Annuler"
                                        });
                                    }}
                                />
                            ))}
                        <IconTouchableRipple
                            icon={"comment"}
                            iconSize={15}
                            style={{
                                // marginLeft: 30,
                                paddingHorizontal: 10
                            }}
                            color={props.currentLiveSession?.unread_private_messages_count?.[student?.id] > 0 ? theme.colors.error : theme.colors.grey}
                            label={"Messages"}
                            horizontal
                            onPress={() => {
                                navigation.navigate("messages", {
                                    userId: student?.id
                                });
                            }}
                            badgeCounter={props.currentLiveSession?.unread_private_messages_count?.[student?.id]}
                        />
                    </View>
                )}
            </View>
            {/*           {!isTutor && windowWidth <= 800 && <View
                    style={{
                        borderTopWidth: 1,
                        borderTopColor: theme.colors.lightMore,
                        // flex: 1,
                        width,
                        flexDirection: "row",
                        justifyContent: 'space-around'
                        // flexWrap: "wrap",
                    }}
                >
                    {renderActionButtons()}
                </View>}*/}
            {isOpen && (
                <TodoModal
                    isOpen={isOpen}
                    handleShowModal={handleShowModal}
                    item={selectedItem}
                    navigation={navigation}
                    onDeleteTodo={onDeleteTodo}
                    studentId={student?.id}
                    schoolSubject={findItemInListWithId(
                        selectedItem?.school_subject_id,
                        props.schoolSubjects
                    )}
                />
            )}
        </View>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
            (x) => x?.id === state.liveMode?.liveSessionId
        ),
        sessions: state.data.currentUser?.object?.sessions,
        participants: state.liveMode?.liveParticipants,
        inCallUsers: state.liveMode?.inCallUsers,
        schoolSubjects: state.data.staticData?.school_subjects,
        getSessionsRequest: state.api.getLiveSession?.request,
        isLive: state.liveMode.isLiveModeEnabled,
        notes: state.data.tutors?.notes,
        deleteTodoRequest: state.api.deleteActivityTodo?.request,
        getNotesRequest: state.api.getNotes?.request,
        deleteActivitiesTodoAsTutorRequest:
        state.api.deleteActivitiesTodoAsTutor?.request,
        deleteTodoRequestActivityId: state.api.deleteActivityTodo?.routeParams?.id,
        maxTodoActivities: state.data.staticData?.max_todo_activities,
        updatedActivityEventData: state.activity?.updatedActivityEventData,
        activityTemplates: state.data.staticData?.activity_templates
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setTodoActivities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewStudentsListItem);
