import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import AvatarM from "../common/components/avatar/AvatarM"
import {productColors, subscriptionStatusColors, subscriptionStatusNames} from "@data/constants/formConstants"
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {backOfficeService} from "@data/services/backOfficeService";
import {Pressable, TouchableOpacity, View} from "react-native";
import {hasRole} from "@data/utility/ability";
import {ActivityIndicator} from "react-native-paper";
import moment from "moment";
import {theme} from "@common/theme/theme";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";
import TextM from "@common/components/text/TextM";
import {BadgeM} from "@common/components/badge/BadgeM";
import LinkTextM from "@common/components/text/LinkTextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useNavigation} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {liveSessionsString} from "./AssignTutorAndLives";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {PaginatedTableM} from "./PaginatedTableM";
import {SchoolSubjectBadge} from "../tutor/students/ProfilDetails";
import {validation} from "@data/utility/validation";
import FormStepperModal from "@common/components/stepper/FormStepperModal";
import RescheduleTrialLiveSessionModal from "../student/tutoring/RescheduleTrialLiveSessionModal";
import {AvailabilitySlotsM} from "./AvailabilitySlotsM";

const UsersTable = (props) => {
    //console.log(props.users)

    const {urlParams, data, requestApi} = props

    const handleValidate = (id) => {
        props.apiRequest(backOfficeService.validateTutor, {id})
    }
    const navigation = useNavigation();
    const student_actions = [
        {
            route: "student-profil", icon: "id-badge"
        },
        {
            route: "student", icon: "bullseye"
        },
        // {
        //     route: "weeklyReport", icon: "paper-plane"
        // },
        // {
        //     route: "interview-reports", icon: "file-video"
        // },
        {
            route: "live-reports", icon: "file-medical-alt"
        },
        {
            route: "settings", icon: "edit"
        },
        {
            onPress: (row) => {
                DialogM.show({
                    text1: 'Supprimer l\'élève ?',
                    text2: `Voulez-vous supprimer l'élève ${row?.firstname} ${row?.lastname} ?`,
                    buttonText1: 'Supprimer',
                    buttonColor1: theme.colors.error,
                    onPress: () => {
                        props.apiRequest(backOfficeService.deleteUser, {id: row?.id})
                    },
                    buttonText2: 'Annuler',
                    onPressBack: () => {

                    }
                })
            },
            icon: "trash"
        }
    ]
    const [sortedUsers, setSortedUsers] = useState([]);
    const users = data ?? props.users
    //console.log(sortedUsers)

    useEffect(() => {
        // console.log(props.users)
        setSortedUsers(users)
    }, [props.users])

    useEffect(() => {
        navigation.setParams({initialTab: props.tabIndex})
    }, []);

    const headerHeight = {"student": 64, "tutor": 64, "admin": null};

    const [selectSchoolSubjectModalVisible, setSelectSchoolSubjectModalVisible] = useState(false)
    const [selectChannelModalVisible, setSelectChannelModalVisible] = useState(false)
    const [currentModalUser, setCurrentModalUser] = useState(null)

    const [selectLiveSessionModalVisible, setSelectLiveSessionModalVisible] = useState(false)

    const actionLoadingId = props.deleteUserRequest ? props.deleteUserRequestId : null

    const studentColumns = [
        {
            name: 'Classe',
            style: {
                width: 220,
            },
            selector: row => (row?.profile?.class ?? "-"),
            sortable: true,
            cell: row => <TouchableOpacity onPress={() => {
                setCurrentModalUser(row)
                setSelectSchoolSubjectModalVisible(true)
            }}>
                <View
                    style={{
                        marginLeft: 5,
                        flex: 1
                    }}
                >
                    <TextM fontWeight="Medium" style={{}} fontSize={12}>
                        {row?.profile?.class}
                    </TextM>

                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",

                    }}>
                        {row?.prioritized_school_subjects?.map((item) => {
                            return <SchoolSubjectBadge key={item?.id} schoolSubject={item}/>
                        })}
                    </View>
                </View>
            </TouchableOpacity>

        },
        {
            name: 'Canal', style: {
                width: 150,
                marginRight: 40
            }, selector: 'channel', sortable: true, cell: row => <TouchableOpacity onPress={() => {
                setCurrentModalUser(row)
                setSelectChannelModalVisible(true)
            }}>
                <View
                    style={{
                        marginHorizontal: 5,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flex: 1
                    }}
                >
                    <TextM fontWeight="Medium" style={{}} fontSize={12}>
                        {row?.channel ?? "-"}
                    </TextM>
                    {row?.referral_as_referee && <BadgeM
                        style={{
                            backgroundColor: theme.colors.primaryLighter,
                            color: theme.colors.primary
                        }}
                        count={row?.referral_as_referee}
                        size={14}/>}
                </View>
            </TouchableOpacity>
        },
        {
            name: 'Mobile parent',
            style: {
                width: 90,
                marginRight: 40
            }, selector: 'parent_mobile', sortable: true, cell: row => (row?.parent_mobile ?? "-")
        },
        {
            name: 'Offre',
            style: {
                width: 150, maxWidth: 150
            },

            selector: row => (row?.current_offer),
            sortable: true,
            cell: row => <TouchableOpacity onPress={() => {
                navigation?.push("change-privileges", {
                    studentId: row?.id
                })
            }}>
                <View>
                    <BadgeM style={{
                        color: row?.current_offer === "basic" ? theme.colors.grey : theme.colors.white,
                        backgroundColor: productColors?.[row?.current_offer]
                        // padding: 3,
                        // margin: 2,
                    }} count={row?.current_offer?.toUpperCase()} size={23}/>
                    {hasRole(row, "holidaycourse-student") && <BadgeM style={{
                        color: theme.colors.white,
                        backgroundColor: productColors?.["holidaycourse"],
                        // padding: 3,
                        marginTop: 5,
                        alignSelf: 'flex-start'
                    }} count={"Stage"} size={23}/>}
                </View>
            </TouchableOpacity>

        },
        {
            name: 'État de l\'abonnement',
            style: {
                maxWidth: 150, width: 150, marginRight: 40
            },

            selector: row => subscriptionStatusNames?.[row?.subscription_state?.status] ?? "-",
            sortable: true,
            cell: row => <>
                {row?.subscription_state?.status === "-" ? <TextM>-</TextM> : <BadgeM
                    style={{
                        backgroundColor: subscriptionStatusColors?.[row?.subscription_state?.status],
                        color: theme.colors.white,
                        padding: 2
                    }} count={(subscriptionStatusNames?.[row?.subscription_state?.status])} size={23}/>}
                {/*{(row?.subscription_state?.end_date === "-" ? "" : "Fin période : " + moment(row?.subscription_state?.end_date)?.format('DD MMMM YYYY'))}*/}
            </>
        },
        {
            name: 'Plan',
            style: {
                width: 120, maxWidth: 120, marginRight: 40
            },

            selector: row => (row?.subscription_state?.plan + " Code Promo : " + (row?.last_used_promo_code ?? "-")),
            sortable: true,
            cell: row => (row?.subscription_state?.plan + (row?.last_used_promo_code ? ("\n(promo " + row?.last_used_promo_code?.toUpperCase()) + ")" : "")) + (row?.subscription_state?.planned_plan ? "\n" + "Ré-activation : " + row?.subscription_state?.planned_plan : "")
        },
        {
            name: 'Fin de la période',
            style: {
                width: 80, maxWidth: 80, marginRight: 40
            },

            selector: row => (row?.subscription_state?.end_date === "-" ? "-" : moment(row?.subscription_state?.end_date)?.format('YYYY-MM-DD')),
            sortable: true,
            cell: row => (row?.subscription_state?.end_date === "-" ? "-" : moment(row?.subscription_state?.end_date)?.format('DD MMMM YYYY')) + (row?.subscription_state?.planned_start_date ? "\n" + "Ré-activation : " + moment(row?.subscription_state?.planned_start_date)?.format('DD MMMM YYYY') : "")
        },
        {
            name: 'Masteur',
            style: {
                width: 150,
                marginRight: 20
            },

            selector: row => (row.tutor ? row.tutor?.display_name : hasRole(row, "premium-student") ? "_" : "-"),
            sortable: true,
            cell: row => {

                return (row?.tutor ?
                    <View>
                        <FilledButtonM onPress={() => {
                            navigation?.push('assign-tutor', {
                                studentId: row?.id
                            })
                        }}
                                       label={`${row.tutor?.display_name}`}
                                       color={theme.colors.link}
                                       style={{
                                           width: "100%",
                                           marginTop: 7
                                       }}
                                       mode={"text"}
                                       labelStyle={{fontSize: 12}}
                                       contentStyle={{height: 25, alignSelf: 'flex-start'}}
                        />

                    </View> : hasRole(row, "premium-student") || hasRole(row, "initial-student") || hasRole(row, "essential-student") || hasRole(row, "integral-student") ? <>
                        {/*                    <TouchableOpacity style={{backgroundColor: theme.colors.error, padding: 10, borderRadius: 5}}
                                          size="small"
                                          onPress={() => {
                                              navigation?.push('assign-master-lives', {
                                                  studentId: row?.id
                                              })
                                          }}>
                            <TextM style={{alignSelf: "center"}} color={theme.colors.white}> <FontAwesome5
                                name={"user-plus"} size={15}/> Attribuer</TextM>
                        </TouchableOpacity>*/}
                        <View>
                            <FilledButtonM onPress={() => {
                                navigation?.push('assign-tutor', {
                                    studentId: row?.id
                                })
                            }}
                                           label={"Tuteur"}
                                           icon={"user-plus"}
                                           color={theme.colors.error}
                                           style={{
                                               width: "100%",
                                               marginTop: 7,
                                               alignSelf: 'center'
                                           }}
                                           labelStyle={{fontSize: 10}}
                                           contentStyle={{height: 25}}
                            />
                        </View>
                    </> : row?.current_offer !== "basic" ? <FilledButtonM onPress={() => {
                        navigation?.push('assign-tutor', {
                            studentId: row?.id
                        })
                    }}
                                                                          label={"Tuteur"}
                                                                          icon={"user-plus"}
                                                                          color={theme.colors.error}
                                                                          style={{
                                                                              width: "100%",
                                                                              marginTop: 7
                                                                          }}
                                                                          labelStyle={{fontSize: 10}}
                                                                          contentStyle={{height: 25}}
                    /> : <TextM>-</TextM>)
            }
        },
        {
            name: 'Lives',
            style: {
                width: 180, maxWidth: 180, marginRight: 30
            },

            selector: row => liveSessionsString(row?.live_sessions),
            sortable: true,
            cell: row => <View>
                <TextM wrap>{liveSessionsString(row?.live_sessions)}</TextM>
                {row?.subscription_state?.status === "on_generic_trial" ? <>
                    <LinkTextM onPress={() => {
                        setCurrentModalUser(row)
                        setSelectLiveSessionModalVisible(true)
                    }}
                               style={{
                                   fontSize: 10,
                                   marginTop: 8
                               }}>REPLANIFIER L'ESSAI</LinkTextM>
                </> : (!(row?.live_sessions?.length > 0) && row?.current_offer !== "basic") ?
                    <FilledButtonM onPress={() => {
                        navigation?.push('assign-master-lives', {
                            studentId: row?.id
                        })
                    }}
                                   label={"Tuteur & lives"}
                                   icon={"user-plus"}
                                   color={theme.colors.error}
                                   style={{
                                       width: "100%",
                                       marginTop: 7
                                   }}
                                   labelStyle={{fontSize: 10}}
                                   contentStyle={{height: 25}}
                    /> : null}
            </View>
        },
        {
            name: 'Actions', allowOverflow: true, style: {
                width: 180,
                maxWidth: 180,
            },

            cell: row => {
                return (<>
                    {student_actions?.map((action, key) => {
                        return <View key={key} style={{marginRight: 7}}>
                            <LinkTextM
                                onPress={() => {

                                    if (!action?.route) {
                                        action.onPress(row)
                                    } else {
                                        navigation.push(action.route, action.route === "interviewHistory" ? {
                                            studentId: row?.id, isInBackOffice: true
                                        } : {
                                            studentId: row?.id,
                                            userId: row?.id
                                        });
                                    }
                                }}
                            >
                                <FontAwesome5 name={action.icon} solid size={20}/>
                            </LinkTextM>
                        </View>

                    })}
                    {actionLoadingId === row?.id && <ActivityIndicator color={theme.colors.primary} size="small"/>}
                </>)
            }
        },
        {
            name: 'Dashboard parrainage',
            style: {
                width: 150, maxWidth: 150, marginRight: 30
            },

            selector: row => row?.referral_code?.referrer_dashboard_url,
            sortable: true,
            cell: row => <LinkTextM url={row?.referral_code?.referrer_dashboard_url}
                                    style={{flexWrap: "wrap", fontSize:10}}>{row?.referral_code?.referrer_dashboard_url}</LinkTextM>
        },
        {
            name: 'Lien abonnement',
            style: {
                width: 150, maxWidth: 150, marginRight: 30
            },

            selector: row => row?.subscribe_url,
            sortable: true,
            cell: row => <LinkTextM url={row?.subscribe_url}
                                    style={{flexWrap: "wrap", fontSize:10}}>{row?.subscribe_url}</LinkTextM>
        }
    ]

    const applicationColumns = [{
        name: 'Mobile', style: {
            width: props.tab !== "admin" ? 80 : 220,
            maxWidth: props.tab !== "admin" ? 80 : null,
            marginRight: 40
        },

        selector: 'mobile', sortable: true, cell: row => row?.mobile ?? "-"
    },
        {
            name: 'Candidature', style: {
                width: 220, marginRight: 40
            },

            selector: 'Candidature', sortable: true, cell: row => <View style={{alignItems: "center"}}>
                {props.tab === "Soumises" && <BadgeM style={{
                    backgroundColor: theme.colors.greyBg,
                    color: theme.colors.greyAlt,
                    alignSelf: "flex-start",
                    marginTop: 5,
                    marginBottom: 3,
                    marginRight: 3
                }} count={`Envoyée ${moment(row?.candidature_sent_at)?.format('ddd DD MMMM')}`} size={23}/>}
                {row?.evaluation_report ?
                    <TouchableOpacity onPress={() => {
                        navigation?.push("application-details", {
                            tutorId: row?.id
                        })
                    }}>
                        <BadgeM
                            style={{
                                backgroundColor: row?.evaluation_report?.is_validated ? theme.colors.successLight : theme.colors.warningLight,
                                color: row?.evaluation_report?.is_validated ? theme.colors.success : theme.colors.warning
                            }}
                            count={row?.evaluation_report?.is_validated ? "Validée" : "Refusée"} size={23}/>
                    </TouchableOpacity>
                    :
                    <FilledButtonM label={"Consulter"}
                                   color={theme.colors.error}
                                   style={{
                                       width: "100%",
                                       marginTop: 7
                                   }}
                                   labelStyle={{fontSize: 12}}
                                   contentStyle={{height: 25, alignSelf: 'flex-start'}}
                                   onPress={() => {
                                       navigation?.push("application-details", {
                                           tutorId: row?.id
                                       })
                                   }}
                    />}
            </View>

        }
        , {
            name: props.tab === "En cours" ? 'Avancement' : 'Avancement dossier', style: {
                width: 220, marginRight: 40
            },

            selector: props.tab === "En cours" ? 'Avancement' : 'Avancement dossier', sortable: true, cell: row => (
                <View>
                    <TextM>
                        étape {props.tab === "En cours" ? (row?.onboarding_current_step?.step ?? 0) : (row?.application_current_step?.step ?? 0)} / {props.tab === "En cours" ? props.onboarding_steps?.length : props.application_steps?.length}
                    </TextM>
                    <ProgressBarM count={row?.onboarding_current_step?.completion ?? 0}
                                  progressColor={theme.colors.primary}
                                  total={100}
                                  barWidth={80}
                                  withAnimation={false}
                    />
                </View>
            )
        }]


    const tutorColumns = [
    //     {
    //     name: 'Complétion du profil',
    //     style: {
    //         width: props.tab !== "admin" ? 130 : 220, //maxWidth:'130px',
    //         marginRight: 40
    //     },
    //     selector: 'profile_completion',
    //     sortable: true,
    //     cell: row => <ProgressBarM count={!row?.profile_completion ? 0 : row?.profile_completion}
    //                                percent
    //                                progressColor={row?.profile_completion < 100 ? theme.colors.warning : theme.colors.success}
    //                                total={100}
    //                                isBgColorWarning
    //                                barWidth={props.tab !== "admin" ? 80 : 170}
    //                                withAnimation={false}
    //     />
    // },
        {
            name: 'Mobile', style: {
                width: props.tab !== "admin" ? 80 : 220,
                maxWidth: props.tab !== "admin" ? 80 : null,
                marginRight: 40
            },

            selector: 'mobile', sortable: true, cell: row => row?.mobile ?? "-"
        },
        {
            name: 'Statut candidature', style: {
                width: 120, maxWidth: 120, marginRight: 40
            }, selector: 'candidature_validated_at', sortable: true, cell: row => (

                <View>
                    <View>
                        {row?.candidature_validated_at ? <BadgeM
                            style={{backgroundColor: theme.colors.successLight, color: theme.colors.success}}
                            count={moment(row?.candidature_validated_at)?.format('ddd DD MMMM')}
                            size={23}/> : (props.validateRequest) && (props.tutorId === row?.id) ?
                            <View style={{alignItems: "center"}}>
                                <ActivityIndicator color={theme.colors.primary} size="small"/>
                            </View> : <>
                                <Pressable
                                    onPress={() => {
                                        DialogM.show({
                                            text1: 'Valider candidature ?',
                                            text2: `Voulez-vous valider la candidature de ${row?.firstname} ${row?.lastname} ?`,
                                            buttonText1: 'Valider', // buttonColor1: theme.colors.error,
                                            onPress: () => {
                                                handleValidate(row.id)
                                            },
                                            buttonText2: 'Annuler',
                                            onPressBack: () => {

                                            }
                                        })
                                    }}>
                                    <BadgeM
                                        style={{
                                            backgroundColor: theme.colors.warningLight, color: theme.colors.warning
                                        }}
                                        count={"Non validée"} size={23}/>
                                </Pressable>


                            </>}
                    </View>
                </View>)
        },
        {
            name: 'Dernière mise à jour profil', style: {
                width: 120,
                maxWidth: 120,
                marginRight: 40
            },

            selector: 'updated_at', sortable: true, cell: row => <BadgeM
                style={{backgroundColor: theme.colors.lightMore, color: theme.colors.grey}}
                count={moment(row?.profile?.updated_at)?.format('ddd DD MMMM')}
                size={23}/>
        },
        {
            name: 'Matières de prédilection', style: {
                width: 100, maxWidth: 100, marginRight: 30
            },
            selector: row => row?.profile?.relevant_subjects?.join(", "),
            sortable: true,
            cell: row => <TextM fontSize={12} wrap>{row?.profile?.relevant_subjects?.join(", ")}</TextM>
        },
        {
            name: 'Classes', style: {
                width: 130, maxWidth: 130, marginRight: 30
            },
            selector: row => row.students?.length,
            sortable: true,
            cell: row => <TextM fontSize={12} wrap>{row?.profile?.relevant_classes?.join(", ")}</TextM>
        },
        {
            name: 'Créneaux dispo',
            style: {
                width: 140, maxWidth: 140, marginRight: 30
            },

            selector: row => row?.profile?.availability_slots, sortable: true, cell: row => {

                return (<AvailabilitySlotsM slots={row?.profile?.availability_slots}/>
                )
            }
        },
        {
            name: 'Accepte nouveaux élèves',
            style: {
                width: 100, maxWidth: 100, marginRight: 40
            },

            selector: 'accept_new_students',
            sortable: true,
            cell: row => (
                <TouchableOpacity onPress={() => {
                    DialogM.show({
                        text1: row?.accept_new_students ? 'Désactiver tuteur ?' : 'Activer tuteur ?',
                        //text2: `Voulez-vous valider la candidature de ${row?.firstname} ${row?.lastname} ?`,
                        buttonText1: 'Oui', // buttonColor1: theme.colors.error,
                        onPress: () => {
                            props.apiRequest(backOfficeService.acceptNewStudents, {id: row?.id}, {
                                accept_new_students: !row?.accept_new_students
                            })
                        },
                        buttonText2: 'Annuler',
                        onPressBack: () => {

                        }
                    })
                }}>
                    {row?.accept_new_students ?
                        <BadgeM style={{backgroundColor: theme.colors.successLight, color: theme.colors.success}}
                                count={"Oui"}
                                size={23}/> :
                        <BadgeM style={{backgroundColor: theme.colors.warningLight, color: theme.colors.warning}}
                                count={"Non"}
                                size={23}/>}
                </TouchableOpacity>
            )
        },
        {
            name: 'Nombre d\'élèves', style: {
                width: 100, maxWidth: 100, marginRight: 30
            },

            selector: row => row.students?.length, sortable: true, cell: row => (<BadgeM
                style={{
                    backgroundColor: row.students?.length ? theme.colors.primaryLighter : theme.colors.lightMore,
                    color: row.students?.length ? theme.colors.primaryTransparent : theme.colors.greyAlt
                }} count={row.students?.length} size={23}/>)
        },
        {
            name: 'Max élèves par live', style: {
                minWidth: 100, maxWidth: 140, marginRight: 30
            },

            selector: row => row.students?.length, sortable: true, cell: row => (
                <View style={{alignItems: 'center', marginTop: 3}}>
                    <BadgeM
                        style={{
                            backgroundColor: theme.colors.primaryLighter,
                            color: theme.colors.primaryTransparent
                        }} count={row?.profile?.max_students_per_live} size={23}/>
                    <FilledButtonM onPress={() => {
                        navigation?.push('update-max-students-live', {
                            userId: row?.id
                        })
                    }}
                                   label={"Modifier"}
                                   color={theme.colors.error}
                                   style={{
                                       width: "100%",
                                       marginTop: 7
                                   }}
                                   labelStyle={{fontSize: 8}}
                                   contentStyle={{height: 20, alignSelf: 'flex-start'}}
                    />
                </View>)
        },
        {
            name: 'Rémuneration', style: {
                width: 150, maxWidth: 150, marginRight: 30
            },

            selector: row => row?.salary, sortable: true, cell: row => {
                return (<View style={{alignItems: 'center', marginTop: 3}}>
                    <BadgeM
                        style={{
                            backgroundColor: theme.colors.lightMore,
                            color: theme.colors.greyAlt
                        }}
                        count={row?.profile?.salary ? row?.profile?.salary + "€" : '-'}
                        size={23}/>
                    <FilledButtonM onPress={() => {
                        navigation?.push('update-salary', {
                            userId: row?.id
                        })
                    }}
                                   label={"Fixer rémunération"}
                                   color={theme.colors.error}
                                   style={{
                                       width: "100%",
                                       marginTop: 7
                                   }}
                                   labelStyle={{fontSize: 8}}
                                   contentStyle={{height: 20, alignSelf: 'flex-start'}}
                    />
                </View>)
            }
        },
        {
            name: 'Coordinateur', style: {
                width: 150, maxWidth: 150, marginRight: 30
            },

            selector: row => row?.admin?.display_name, sortable: true, cell: row => (
                <View style={{
                    alignItems: 'center',
                    marginTop: 3
                }}>
                    <BadgeM
                        style={{
                            backgroundColor: theme.colors.lightMore,
                            color: theme.colors.greyAlt
                        }} count={row?.admin?.display_name ?? "-"} size={23}/>
                    <FilledButtonM onPress={() => {
                        navigation?.push('assign-admin', {
                            tutorId: row?.id
                        })
                    }}
                                   label={"Assigner"}
                                   color={theme.colors.error}
                                   style={{
                                       width: "100%",
                                       marginTop: 7
                                   }}
                                   labelStyle={{fontSize: 8}}
                                   contentStyle={{height: 20, alignSelf: 'flex-start'}}
                    />
                </View>
            )
        },
        {
            name: 'Actions',
            allowOverflow: true,
            style: {
                width: 180
                //maxWidth:'100px',
            },

            cell: row => {
                return (<>
                    <View style={{}}>
                        <Menu3Point menuItems={[{
                            title: "Modifier", onPress: () => {
                                navigation.push("settings", {
                                    userId: row?.id
                                });
                            }
                        }]}/>
                    </View>
                </>)
            }
        }
    ];


    const columns = [
        {
            name: 'Nom', style: {
                width: props.tab !== "admin" ? 200 : 220, //maxWidth: props.tab!='admin' ? '160px' : null,
                marginRight: 40
                // marginLeft:20
            }, selector: 'lastname', sortable: true, cell: row => (<>
                <TouchableOpacity onPress={() => {
                    navigation.push("student-profil", {
                        "studentId": row?.id
                    })
                }}
                                  disabled={props.tab !== "student"}
                                  style={{
                                      justifyContent: "flex-start", alignItems: "center", flexDirection: "row"
                                  }}>
                    <AvatarM user={row} size={32}/>

                    <View style={{marginLeft: 15}}>
                        <TextM color={theme.colors.primary} fontWeight={"Medium"} wrap
                               style={{maxWidth: props.tab !== 'admin' ? 100 : null}}>{`${row?.firstname} ${row?.lastname}`}</TextM>
                        <TextM color={theme.colors.grey} fontWeight={"Light"} wrap fontSize={10}
                               style={{maxWidth: props.tab !== 'admin' ? 100 : null}}>{`${row?.email}`}</TextM>
                    </View>
                </TouchableOpacity>
            </>)
        }
    ]
        .concat(props.tab === "student" ? studentColumns : [])
        .concat(props.tab === "En cours" || props.tab === "Soumises" ? applicationColumns : [])
        .concat(props.tab === "tutor" ? tutorColumns : [])
        .concat([{
            id: 'account', name: 'Compte', style: {
                width: props.tab !== "admin" ? 160 : 220
            }, selector: 'created_at', sortable: true, cell: row => <View style={{
                // alignItems:"flex-start",
                //justifyContent:"flex-start"
            }}>
                <BadgeM style={{
                    backgroundColor: theme.colors.greyBg,
                    color: theme.colors.greyAlt,
                    alignSelf: "flex-start",
                    marginTop: 5,
                    marginBottom: 3
                }} count={`Créé ${moment(row?.created_at)?.format('ddd DD MMMM')}`} size={23}/>
                {row?.is_verified === true ? <BadgeM style={{
                    backgroundColor: theme.colors.successLight,
                    color: theme.colors.success,
                    alignSelf: "flex-start",
                    marginBottom: 5
                }} count={"Vérifié"} size={23}/> : <BadgeM style={{
                    color: theme.colors.warning,
                    backgroundColor: theme.colors.warningLight,
                    alignSelf: "flex-start",
                    marginBottom: 5
                }} count={"Non vérifié"} size={23}/>}
            </View>
        }
        ])

    useEffect(() => {

        if (props.tab !== "En cours" && props.tab !== "Soumises")
            columns.splice(10, 0)
    }, []);

    return <>
        <PaginatedTableM data={sortedUsers}
                         flatListRef={null}
                         columns={columns}
                         headerHeight={headerHeight[props.tab]}
                         requestService={requestApi ? requestApi : backOfficeService.searchUser}
                         urlParams={urlParams}
        />
        <FormStepperModal visible={selectSchoolSubjectModalVisible}
                          navigation={navigation}
                          setVisible={setSelectSchoolSubjectModalVisible}
                          formData={[
                              {
                                  label: "Choisis les matières prioritaires",
                                  description: currentModalUser?.display_name,
                                  type: "select.multiple",
                                  name: "prioritized_school_subjects",
                                  options: props.schoolSubjects?.map(x => ({
                                      label: x?.name,
                                      value: x?.id,
                                      icon: x?.icon
                                  })),
                                  defaultValue: currentModalUser?.prioritized_school_subjects?.map(x => x?.id) ?? [],
                                  validationRules: {...validation.required()}
                              }
                          ]}
                          submitService={backOfficeService.updatePrioritizedSchoolSubjects}
                          submitRouteParams={{id: currentModalUser?.id}}
        />
        <FormStepperModal visible={selectChannelModalVisible}
                          navigation={navigation}
                          setVisible={setSelectChannelModalVisible}
                          formData={[
                              {
                                  label: "Comment as-tu entendu parler de Masteur ?",
                                  description: currentModalUser?.display_name,
                                  type: "select.single",
                                  name: "channel",
                                  options: [
                                      {value: "Famille/amis", label: "Famille/amis"},
                                      {value: "Facebook/Instagram", label: "Facebook/Instagram"},
                                      {value: "Recherche Google", label: "Recherche Google"},
                                      {value: "Actualités/article/blog", label: "Actualités/article/blog"},
                                      {value: "Partenaire", label: "Partenaire"},
                                      {value: "App Store ou Play Store", label: "App Store ou Play Store"},
                                      {value: "Autre", label: "Autre"}
                                  ],
                                  defaultValue: currentModalUser?.channel ?? null,
                                  validationRules: {...validation.required()}
                              },
                              {
                                  label: "Comment as-tu entendu parler de Masteur ?",
                                  description: currentModalUser?.display_name,
                                  type: "select.single",
                                  name: "channel",
                                  options: [
                                      {value: "Famille/amis", label: "Famille/amis"},
                                      {value: "Facebook/Instagram", label: "Facebook/Instagram"},
                                      {value: "Recherche Google", label: "Recherche Google"},
                                      {value: "Actualités/article/blog", label: "Actualités/article/blog"},
                                      {value: "Partenaire", label: "Partenaire"},
                                      {value: "App Store ou Play Store", label: "App Store ou Play Store"},
                                      {value: "Autre", label: "Autre"}
                                  ],
                                  defaultValue: currentModalUser?.channel ?? null,
                                  validationRules: {...validation.required()}
                              }
                          ]}
                          submitService={backOfficeService.updateChannel}
                          submitRouteParams={{id: currentModalUser?.id}}
        />
        <RescheduleTrialLiveSessionModal visible={selectLiveSessionModalVisible}
                                         navigation={navigation}
                                         setVisible={setSelectLiveSessionModalVisible}
                                         student={currentModalUser}
                                         notify
        />

    </>
}
const mapStateToProps = state => {
    return {
        users: state.data?.users?.usersList,
        deleteUserRequestId: state.api.deleteUser?.routeParams?.id,
        deleteUserRequest: state.api?.deleteUser?.request,
        tutorId: state.api.validateTutor?.routeParams?.id,
        validateRequest: state.api?.validateTutor?.request,
        validateSuccess: state.api?.validateTutor?.success,
        validateError: state.api?.validateTutor?.error,
        usersRequest: state.api?.searchUser?.request,
        usersSuccess: state.api?.searchUser?.success,
        onboarding_steps: state.data.staticData?.onboarding_steps,
        application_steps: state.data.staticData?.application_steps,
        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)