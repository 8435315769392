import React from 'react';
import {Text} from "react-native-paper";
import withPreventDoubleClick from "../../utils/withPreventDoubleClick";
//import Pressable from "react-native/Libraries/Components/Pressable/Pressable";
import { Pressable } from 'react-native';
import {theme} from "../../theme/theme";
import * as amplitude from "@amplitude/analytics-react-native";

const TextButtonM = (props) => {

    const {label, fontWeight, style, onPress, color, numberOfLines, trackingId} = props

    return <Pressable
        onPress={(e) => {
            if (trackingId) {
                amplitude.track("textbutton.clicked." + trackingId)
            }
            onPress(e)
        }}
        children={({pressed}) => (
            <Text
                style={{
                ...style,
                fontFamily: "Montserrat-" + (fontWeight ?? "Regular"),
                color: pressed ? theme.colors.light : (color ? color : theme.colors.primary)
            }}
            numberOfLines={numberOfLines ?? 1}
            >
                {label}
            </Text>
        )}/>
}

export default withPreventDoubleClick(TextButtonM)