import { useEffect, useState } from "react";
import { useInterval } from "@common/utils/useInterval";
import {
  apiIdle,
  apiRequest,
  apiRequestWithPrerequest,
  vaporFileUpload,
  vaporFileUploadIdle,
} from "@data/redux/actions/api";
import { useDispatch, useSelector } from "react-redux";
import { DialogM } from "@common/components/alert/dialog/Dialog";

export const fetchResourceFromURI = async (uri) => {
  const response = await fetch(uri);
  return await response.blob();
};

export function useRequestsBatch(
  requestsBeforeFilesUpload = [],
  filesToUpload = [],
  formId,
  requestToSendFiles,
  requestsAfterFilesUpload = [],
  noIdle = false
) {
  const vaporFileUploadService = useSelector(
    (state) => state.api?.vaporFileUpload
  );
  const dispatch = useDispatch();
  const nbReqsBefore = requestsBeforeFilesUpload?.length;
  const nbReqsAfter = requestsAfterFilesUpload?.length;
  const requestsNumber = nbReqsBefore + nbReqsAfter + filesToUpload?.length + 1;

  const apiRequestHandler = apiRequest;

  const [requestProgress, setRequestProgress] = useState(0);
  const [percentRequestCompleted, setPercentRequestCompleted] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const percentLimitForUpload = 0;
  const globalProgress = Math.max(
    0,
    Math.round(
      ((requestProgress - 1 + percentRequestCompleted / 100) / requestsNumber) *
        100
    )
  );

  const currentRequestSuccess =
    useSelector((state) => state.api?.[currentRequest?.service?.name])
      ?.success ?? false;
  const currentRequestError =
    useSelector((state) => state.api?.[currentRequest?.service?.name])?.error ??
    false;
  const currentRequestSuccessData = useSelector(
    (state) => state.api?.[currentRequest?.service?.name]
  )?.data;

  // console.log("current Request Name: ", currentRequest?.service?.name)

  // console.log("files to upload: ", filesToUpload)

  // console.log("current request success : ", currentRequestSuccess)

  // console.log("your uploaded files : ", attachments)

  // console.log(requestsNumber)

  const setNewRequestStarted = () => {
    // console.log("progressBeforeIncrement = ", requestProgress)
    setSubmitted(true);
    setRequestProgress((x) => Math.min(x + 1, requestsNumber));
    setPercentRequestCompleted(0);
  };
  const setRequestsReset = () => {
    setSubmitted(false);
    setAttachments(null);
    setRequestProgress(0);
    setPercentRequestCompleted(0);
  };

  const onUploadProgress = (progressEvent) => {
    // console.log("request", requestProgress, "progress", Math.round((progressEvent.loaded * percentLimitForUpload) / progressEvent.total))
    setPercentRequestCompleted(
      Math.round(
        (progressEvent.loaded * percentLimitForUpload) / progressEvent.total
      )
    );
  };

  useInterval(
    () => {
      setPercentRequestCompleted((p) => p + 1);
    },
      submitted
      && percentRequestCompleted >= percentLimitForUpload
      && percentRequestCompleted < 98
          ? 500
          : null
  );

  const startRequests = () => {
    // console.log("start all requests from here")
    if (requestsNumber >= 1) nextRequest();
  };

  useEffect(() => {
    if (currentRequestSuccess) {
      dispatch(apiIdle(currentRequest?.service));

      if (
        currentRequest?.onSuccessBeforeNext(currentRequestSuccessData) &&
        requestProgress < requestsNumber
      ) {
        // console.log("progressAfterIncrement = ", requestProgress)
        nextRequest();
      } else {
        setRequestsReset();
      }
    }
  }, [currentRequestSuccess]);

  useEffect(() => {
    // if POST file to AWS S3 SUCCESS then save AWS S3 key in logs and IDLE, then switch to next request
    if (
      vaporFileUploadService?.success &&
      vaporFileUploadService?.formId === formId &&
      requestProgress > 0
    ) {
      const responseKey = vaporFileUploadService?.response?.key;
      dispatch(vaporFileUploadIdle);
      // console.log("progressAfterIncrement = ", requestProgress)
      nextRequest([...attachments, responseKey]);
    }
  }, [vaporFileUploadService?.success]);

  const nextRequest = async (tmpData = []) => {
    // console.log("next request")
    // console.log("filesToUpload", filesToUpload)
    // save the updated data list to be sent after all files
    if (tmpData) {
      setAttachments(tmpData);
    }
    // if request progress < nbRequestsBefore continue handling requests before upload
    if (requestProgress < nbReqsBefore) {
      const nextApiRequest = requestsBeforeFilesUpload[requestProgress];
      dispatch(
        apiRequestHandler(
          nextApiRequest?.service,
          nextApiRequest?.params,
          nextApiRequest?.data,
          {},
          onUploadProgress
        )
      );
      setCurrentRequest(nextApiRequest);
      setNewRequestStarted();
    }
    // if requests progress < requests number - nbReqsAfter, increment progress and message, and POST file number (requests progress - nbReqsBefore) to AWS S3
    // else handle RequestsAfterUpload
    else if (requestProgress < requestsNumber - nbReqsAfter - 1) {
      const attachment = filesToUpload[requestProgress - nbReqsBefore];
      // console.log(`attachment num ${requestProgress - nbReqsBefore}: `, attachment)
      const file = await fetchResourceFromURI(attachment?.uri);
      // console.log("attachment: ", attachment)

      dispatch(
        vaporFileUpload(
          file,
          { onUploadProgress, contentType: file?.type },
          formId
        )
      );
      setNewRequestStarted();
    } else if (requestProgress === requestsNumber - nbReqsAfter - 1) {
      // console.log({
      //     ...requestToSendFiles?.data,
      //     [requestToSendFiles?.tmpFilesKey]: [...tmpData?.map(requestToSendFiles?.tmpFilesFormatter), ...requestToSendFiles?.additionalData]
      // })
      const requestToSendFilesData = {
        ...requestToSendFiles?.data,
        [requestToSendFiles?.tmpFilesKey]: [
          ...tmpData.map(requestToSendFiles?.tmpFilesFormatter),
          ...requestToSendFiles.additionalData,
        ],
      };
      // console.log("requestToSendFilesData: ", requestToSendFilesData)
      dispatch(
        apiRequestHandler(
          requestToSendFiles?.service,
          requestToSendFiles?.params,
          requestToSendFilesData,
          {},
          onUploadProgress
        )
      );
      setCurrentRequest(requestToSendFiles);
      setNewRequestStarted();
    } else {
      const nextApiRequest =
        requestsAfterFilesUpload[
          requestProgress - (nbReqsBefore + filesToUpload?.length + 1)
        ];
      // console.log(nextApiRequest?.params)
      dispatch(
        apiRequestHandler(
          nextApiRequest?.service,
          nextApiRequest?.params,
          nextApiRequest?.data,
          {},
          onUploadProgress
        )
      );
      setCurrentRequest(nextApiRequest);
      setNewRequestStarted();
    }
  };

  useEffect(() => {
    let errorMessage = null;

    // if any error then alert with the error and IDLE reset request state

    //if an error occurred before or after files upload
    if (currentRequestError) {
      if (!noIdle) {
        errorMessage = `${currentRequestError?.message}`;
        dispatch(apiIdle(currentRequest?.service));
      }
      setRequestsReset();
    }

    //if an error occurred while uploading a file
    else if (vaporFileUploadService?.error) {
      if (!noIdle) {
        dispatch(vaporFileUploadIdle());
        errorMessage = `${vaporFileUploadService?.error?.message}`;
      }
      setRequestsReset();
    }

    if (errorMessage)
      DialogM.show({
        text1: "Erreur",
        text2: errorMessage,
      });
    //alertPolyfill("Erreur", errorMessage)
  }, [currentRequestError, vaporFileUploadService?.error]);

  return [globalProgress, submitted, startRequests];
}
