import React, {useEffect, useRef, useState} from "react";
import {Audio, InterruptionModeAndroid, InterruptionModeIOS} from "expo-av";
import {Platform, TouchableHighlight, Vibration, View} from "react-native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import {IconButton} from "react-native-paper";
import Slider from "@react-native-community/slider";
import {useKeepAwake} from "expo-keep-awake";
import {displayTimeSeconds} from "@data/utility/Utils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useSelector} from "react-redux";
import {IS_WEB} from "@common/utils/mobileUtils";

const DISABLED_OPACITY = 0.5;
export const _getMMSSFromMillis = (millis) => {
    const totalSeconds = millis / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = (number) => {
        const string = number.toString();
        if (number < 10) {
            return "0" + string;
        }
        return string;
    };
    return padWithZero(minutes) + ":" + padWithZero(seconds);
};
export const AudioRecordingContainer = (props) => {
    // eslint-disable-next-line no-unused-vars
    const {
        recordingURI,
        setRecordingURI,
        status,
        readOnly,
        setTime = (recordingDuration) => {
        },
        duration,
        chatMode = false,
        color = null,
        onSend = () => {
        },
        activityPaused = false,
        onDeleteRecording = () => {
        },
        audioRecordingStatus = () => {
        }
    } = props;

    if (!IS_WEB)
        useKeepAwake("AudioRecording");

    const isLiveModeEnabled = useSelector(
        (state) => state.liveMode?.isLiveModeEnabled
    );
    const liveSessionId = useSelector((state) => state.liveMode?.liveSessionId);
    const liveSessions = useSelector(
        (state) => state.data.currentUser?.object?.live_sessions
    );
    const liveSession = liveSessions?.find(
        (session) => session.id === liveSessionId
    );
    const isV2 = liveSession?.live_room?.version === "v2";
    const cannotPlaySound =
        isLiveModeEnabled && isV2 && Platform.OS === "android";

    const maxRecordingDurationMinutes = 12;
    const [recording, setRecording] = useState(null);
    const [sound, setSound] = useState(null);
    const [isSeeking, setIsSeeking] = useState(false);
    const [shouldPlayAtEndOfSeek, setShouldPlayAtEndOfSeek] = useState(false);

    const [recordingState, setRecordingState] = useState({
        haveRecordingPermissions: false,
        isLoading: false,
        isPlaybackAllowed: false,
        muted: false,
        soundPosition: null,
        soundDuration: null,
        recordingDuration: null,
        shouldPlay: false,
        isPlaying: false,
        isRecording: false,
        fontLoaded: false,
        shouldCorrectPitch: true,
        volume: 1.0,
        rate: 1.0
    });

    useEffect(() => {
        return () => {
            unloadSoundFinished();
        };
    }, [sound]);

    useEffect(() => {
        if (recordingURI) loadSound();

        // return sound ? () => {
        //     sound?.unloadAsync();
        // } : undefined;
    }, [recordingURI]);

    async function loadSound() {
        // console.log('Loading Sound');
        const {sound} = await Audio.Sound.createAsync(
            {uri: recordingURI},
            {
                isLooping: true,
                isMuted: recordingState.muted,
                volume: recordingState.volume,
                rate: recordingState.rate,
                shouldCorrectPitch: recordingState.shouldCorrectPitch
            },
            _updateScreenForSoundStatus
        );
        setSound(sound);
    }

    useEffect(() => {
        if (status === "finished" && !!sound) {
            sound?.stopAsync();
        }
    }, [status]);

    async function unloadSoundFinished() {
        if (sound !== null) {
            sound?.setOnPlaybackStatusUpdate(null);
            sound?.stopAsync();
            await sound?.unloadAsync();
        }
    }

    async function unloadSound() {
        if (sound !== null) {
            // sound.setOnPlaybackStatusUpdate(null);
            // sound.stopAsync();
            // await sound.unloadAsync();
            onDeleteRecording();
            setSound(null);
            setRecordingURI(null);
            audioRecordingStatus("deleted")
            setRecordingState({
                ...recordingState,
                recordingDuration: null
            });
        }
    }

    async function startRecording() {
        try {
            // console.log('Requesting permissions..');
            const response = await Audio.requestPermissionsAsync();
            if (response.status !== "granted") {
                // console.log("refus autorisation")
                DialogM.show({
                    text1: "Attention",
                    text2:
                        "Vous devez autoriser l'application à enregistrer des contenus audio."
                });
                //alertPolyfill("Attention", "Vous devez autoriser l'application à enregistrer des contenus audio.")
                return;
            }

            if (sound !== null) {
                sound.stopAsync();
                await sound.unloadAsync();
                sound.setOnPlaybackStatusUpdate(null);
                setSound(null);
                setRecordingURI(null);
                setRecordingState({
                    ...recordingState,
                    recordingDuration: null
                });
            }

            await Audio.setAudioModeAsync({
                staysActiveInBackground: true,
                playsInSilentModeIOS: true,
                allowsRecordingIOS: true,
                interruptionModeIOS: InterruptionModeIOS.DoNotMix,
                shouldDuckAndroid: true,
                interruptionModeAndroid: InterruptionModeAndroid.DoNotMix
            });
            // console.log('Starting recording..');
            const recording = new Audio.Recording();
            await recording.prepareToRecordAsync(
                Audio.RecordingOptionsPresets.HIGH_QUALITY
            );
            await recording.startAsync();
            recording.setOnRecordingStatusUpdate(_updateScreenForRecordingStatus);
            setRecording(recording);
            // console.log('Recording started');
        } catch (err) {
            console.error("Failed to start recording", err);
        }
    }

    async function stopRecording() {
        // console.log('Stopping recording..');

        const recordingDuration = Math.floor(
            recordingState.recordingDuration / 1000
        );

        setTime(recordingDuration);

        if (recordingDuration === maxRecordingDurationMinutes * 60)
            DialogM.show({
                text1: "Durée max atteinte",
                text2:
                    "Les enregistrements audio ne peuvent pas dépasser " +
                    maxRecordingDurationMinutes +
                    " minutes"
            });
        //alertPolyfill("Durée max atteinte", "Les enregistrements audio ne peuvent pas dépasser " + maxRecordingDurationMinutes + " minutes")

        try {
            await recording.stopAndUnloadAsync();
        } catch (error) {
            // On Android, calling stop before any data has been collected results in
            // an E_AUDIO_NODATA error. This means no audio data has been written to
            // the output file is invalid.
            if (error.code === "E_AUDIO_NODATA") {
                console.log(
                    `Stop was called too quickly, no data has yet been received (${error.message})`
                );
            } else {
                console.log("STOP ERROR: ", error.code, error.name, error.message);
            }
            return;
        }
        setRecording(undefined);
        const uri = recording.getURI();
        setRecordingURI(uri)
        audioRecordingStatus("recorded")
        // console.log('Recording stopped and stored at', uri);

        await Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
            allowsRecordingIOS: true,
            interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
            shouldDuckAndroid: true,
            interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
            playThroughEarpieceAndroid: false
        });
        const {sound: mSound, status} = await recording.createNewLoadedSoundAsync(
            {
                isLooping: true,
                isMuted: recordingState.muted,
                volume: recordingState.volume,
                rate: recordingState.rate,
                shouldCorrectPitch: recordingState.shouldCorrectPitch
            },
            _updateScreenForSoundStatus
        );
        setSound(mSound);
    }

    const buttonRef = useRef(null);

    // ** Recording functions

    const _updateScreenForSoundStatus = (status) => {
        if (status.isLoaded) {
            setRecordingState({
                ...recordingState,
                soundDuration: status.durationMillis ?? null,
                soundPosition: status.positionMillis,
                shouldPlay: status.shouldPlay,
                isPlaying: status.isPlaying,
                rate: status.rate,
                muted: status.isMuted,
                volume: status.volume,
                shouldCorrectPitch: status.shouldCorrectPitch,
                isPlaybackAllowed: true
            });
        } else {
            setRecordingState({
                ...recordingState,
                soundDuration: null,
                soundPosition: null,
                isPlaybackAllowed: false
            });
            if (status.error) {
                console.log(`FATAL PLAYER ERROR: ${status.error}`);
            }
        }
    };

    const _updateScreenForRecordingStatus = (status) => {
        // console.log(status.durationMillis)

        // console.log("update recording status")
        if (status.canRecord) {
            setRecordingState({
                ...recordingState,
                isRecording: status.isRecording,
                recordingDuration: status.durationMillis
            });
        } else if (status.isDoneRecording) {
            setRecordingState({
                ...recordingState,
                isRecording: false,
                recordingDuration: status.durationMillis
            });
        }

        if (
            Math.floor(status.durationMillis / 1000) ===
            maxRecordingDurationMinutes * 60
        ) {
            buttonRef.current?.onPress();
            Vibration.vibrate(50);
        }
    };

    const _onPlayPausePressed = () => {
        if (cannotPlaySound)
            DialogM.show({
                champion: "abou",
                variant: "main",
                text1: "Impossible de lire un son pendant l'appel",
                text2: "Mais tu pourras écouter l'enregistrement après le Live !"
            });

        if (sound != null) {
            if (recordingState.isPlaying) {
                sound.pauseAsync();
            } else {
                sound.playAsync();
            }
        }
    };

    const setRate = async (rate) => {
        await sound.setRateAsync(
            rate,
            recordingState.shouldCorrectPitch,
            Audio.PitchCorrectionQuality.Medium
        );
        setRecordingState({
            ...recordingState,
            rate
        });
    };

    const _onRateChangePressed = async () => {
        if (sound != null) {
            switch (recordingState.rate) {
                case 1.0:
                    await setRate(1.5);
                    return;
                case 1.5:
                    await setRate(2.0);
                    return;
                case 2.0:
                    await setRate(1.0);
                    return;
                default:
                    return;
            }
        }
    };

    const _onStopPressed = () => {
        if (sound != null) {
            sound.stopAsync();
        }
    };

    const _onSeekSliderValueChange = (value) => {
        if (sound != null && !isSeeking) {
            setIsSeeking(true);
            setShouldPlayAtEndOfSeek(recordingState.shouldPlay);
            sound.pauseAsync();
        }
    };

    const _onSeekSliderSlidingComplete = async (value) => {
        if (sound != null) {
            setIsSeeking(false);
            const seekPosition = value * (recordingState.soundDuration || 0);
            if (shouldPlayAtEndOfSeek) {
                sound.playFromPositionAsync(seekPosition);
            } else {
                sound.setPositionAsync(seekPosition);
            }
        }
    };

    const _getSeekSliderPosition = () => {
        if (
            sound != null &&
            recordingState.soundPosition !== null &&
            recordingState.soundDuration !== null
        ) {
            // console.log("recordingState.soundPosition", recordingState.soundPosition)
            // console.log("recordingState.soundDuration", recordingState.soundDuration)
            return recordingState.soundPosition / recordingState.soundDuration;
        }
        return 0;
    };

    const _getPlaybackTimestamp = () => {
        if (
            sound != null &&
            recordingState.soundPosition != null &&
            recordingState.soundDuration != null
        ) {
            return `${_getMMSSFromMillis(recordingState.soundPosition)} / ${
                isNaN(recordingState.soundDuration) ||
                !Number.isFinite(recordingState.soundDuration)
                    ? recordingState.recordingDuration
                        ? _getMMSSFromMillis(recordingState.recordingDuration)
                        : displayTimeSeconds(duration)
                    : _getMMSSFromMillis(recordingState.soundDuration)
            }`;
        }
        return duration ? displayTimeSeconds(duration) : "-";
    };

    const _getRecordingTimestamp = () => {
        if (recordingState.recordingDuration != null) {
            return `${_getMMSSFromMillis(recordingState.recordingDuration)}`;
        }
        return `${_getMMSSFromMillis(0)}`;
    };

    async function pausePlayRecording() {
        if (recordingState.isRecording) {
            try {
                await recording.pauseAsync();
                setRecordingState({
                    ...recordingState,
                    isRecording: false
                });
            } catch (error) {
                // On Android, calling stop before any data has been collected results in
                // an E_AUDIO_NODATA error. This means no audio data has been written to
                // the output file is invalid.
                if (error.code === "E_AUDIO_NODATA") {
                    console.log(
                        `Stop was called too quickly, no data has yet been received (${error.message})`
                    );
                } else {
                    console.log("STOP ERROR: ", error.code, error.name, error.message);
                }
            }
        } else {
            try {
                await recording.startAsync();
                setRecordingState({
                    ...recordingState,
                    isRecording: true
                });
            } catch (error) {
                // On Android, calling stop before any data has been collected results in
                // an E_AUDIO_NODATA error. This means no audio data has been written to
                // the output file is invalid.
                if (error.code === "E_AUDIO_NODATA") {
                    console.log(
                        `Stop was called too quickly, no data has yet been received (${error.message})`
                    );
                } else {
                    console.log("STOP ERROR: ", error.code, error.name, error.message);
                }
            }
        }
    }

    async function pauseRecording() {
        try {
            await recording.pauseAsync();
            setRecordingState({
                ...recordingState,
                isRecording: false
            });
        } catch (error) {
            // On Android, calling stop before any data has been collected results in
            // an E_AUDIO_NODATA error. This means no audio data has been written to
            // the output file is invalid.
            if (error.code === "E_AUDIO_NODATA") {
                console.log(
                    `Stop was called too quickly, no data has yet been received (${error.message})`
                );
            } else {
                console.log("STOP ERROR: ", error.code, error.name, error.message);
            }
        }
    }

    useEffect(() => {
        if (activityPaused) pauseRecording().then();
    }, [activityPaused]);
    const buttonSize = 70;

    return (
        <View
            style={{
                alignItems: "center",
                width: "100%",
                marginTop: readOnly ? 5 : 30
            }}
        >
            {!sound && !readOnly && (
                <View
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        width: "100%"
                    }}
                >
                    {/*<FontAwesome5 name={"circle"}*/}
                    {/*              size={24}*/}
                    {/*              solid*/}
                    {/*              style={{*/}
                    {/*                  position: "absolute",*/}
                    {/*                  left: 20,*/}
                    {/*                  opacity: recording ? 1.0 : 0.0*/}
                    {/*              }}*/}
                    {/*              color={theme.colors.error}/>*/}

                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <TextM fontWeight="Medium" fontSize={45} color={theme.colors.grey}>
                            {_getRecordingTimestamp()}
                        </TextM>
                    </View>
                </View>
            )}

            {sound && (
                <View
                    style={{
                        opacity: !recordingState.isPlaybackAllowed ? DISABLED_OPACITY : 1.0,
                        width: "100%",
                        alignItems: "center"
                    }}
                >
                    <View/>

                    <View
                        style={{
                            // flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            alignSelf: "stretch"
                        }}
                    >
                        <IconButton
                            icon={recordingState.isPlaying ? "pause" : "play"}
                            iconColor={color ? color : theme.colors.primary}
                            size={30}
                            onPress={_onPlayPausePressed}
                            disabled={
                                !recordingState.isPlaybackAllowed || status === "finished"
                            }
                        />
                        <View
                            style={{
                                flex: 1,
                                marginTop: 20,
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                        >
                            <Slider
                                style={{
                                    alignSelf: "stretch"
                                }}
                                minimumTrackTintColor={color ? color : theme.colors.primary}
                                thumbTintColor={color ? color : theme.colors.primary}
                                maximumTrackTintColor={theme.colors.grey}
                                value={_getSeekSliderPosition()}
                                onValueChange={_onSeekSliderValueChange}
                                onSlidingComplete={_onSeekSliderSlidingComplete}
                                disabled={
                                    !recordingState.isPlaybackAllowed || status === "finished"
                                }
                            />
                            <TextM
                                fontWeight="Medium"
                                fontSize={16}
                                color={color ? color : theme.colors.grey}
                            >
                                {_getPlaybackTimestamp()}
                            </TextM>

                            {/*<IconButton*/}
                            {/*    icon={"stop"}*/}
                            {/*    color={theme.colors.primary}*/}
                            {/*    size={30}*/}
                            {/*    onPress={_onStopPressed}*/}
                            {/*    disabled={!recordingState.isPlaybackAllowed}*/}
                            {/*/>*/}
                        </View>
                        <TouchableHighlight
                            onPress={_onRateChangePressed}
                            underlayColor={theme.colors.lighter}
                            disabled={
                                !recordingState.isPlaybackAllowed || status === "finished"
                            }
                            style={{
                                marginLeft: 6,
                                borderWidth: 1,
                                borderColor: theme.colors.lightMore,
                                borderRadius: 10,
                                alignItems: "center",
                                width: 45,
                                paddingHorizontal: 8,
                                paddingVertical: 5
                            }}
                        >
                            <TextM color={theme.colors.light}>{`${
                                recordingState.rate ?? 1
                            }x`}</TextM>
                        </TouchableHighlight>
                    </View>

                    <View/>
                </View>
            )}
            {!readOnly && (
                <TextM
                    fontWeight="Medium"
                    fontSize={14}
                    color={theme.colors.error}
                    style={{
                        marginTop: 15,
                        marginBottom: 10
                    }}
                >
                    {recording
                        ? recordingState.isRecording
                            ? "Enregistrement en cours"
                            : "En pause"
                        : ""}
                </TextM>
            )}

            {status !== "finished" && !readOnly && (
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <FilledButtonM
                        color={
                            recording
                                ? theme.colors.error
                                : sound
                                    ? theme.colors.white
                                    : theme.colors.error
                        }
                        // disabled={recordingState.isLoading}
                        onPress={() => {
                            Vibration.vibrate(50);
                            const onPress = recording
                                ? pausePlayRecording
                                : sound
                                    ? unloadSound
                                    : startRecording;
                            onPress();
                        }}
                        contentStyle={{
                            width: buttonSize,
                            height: buttonSize,
                            borderRadius: buttonSize / 2
                        }}
                        style={{
                            width: buttonSize,
                            // height: buttonSize,
                            borderRadius: buttonSize / 2
                        }}
                        label={
                            <FontAwesome5
                                name={
                                    recording
                                        ? recordingState.isRecording
                                            ? "pause"
                                            : "microphone"
                                        : sound
                                            ? "trash"
                                            : "microphone"
                                }
                                size={buttonSize / 3}
                                color={sound ? theme.colors.grey : theme.colors.white}
                            />
                        }
                    />
                    {recording && (
                        <FilledButtonM
                            ref={buttonRef}
                            color={theme.colors.white}
                            // disabled={recordingState.isLoading}
                            onPress={() => {
                                Vibration.vibrate(50);
                                const onPress = stopRecording;
                                onPress();
                            }}
                            contentStyle={{
                                width: buttonSize,
                                height: buttonSize,
                                borderRadius: buttonSize / 2
                            }}
                            style={{
                                width: buttonSize,
                                // height: buttonSize,
                                borderRadius: buttonSize / 2,
                                marginLeft: 15
                                // position: "absolute",
                            }}
                            label={
                                <FontAwesome5
                                    name={"check"}
                                    size={(2 * buttonSize) / 5}
                                    color={theme.colors.success}
                                />
                            }
                        />
                    )}
                    {chatMode && sound && (
                        <FilledButtonM
                            color={theme.colors.white}
                            // disabled={recordingState.isLoading}
                            onPress={onSend}
                            contentStyle={{
                                width: buttonSize,
                                height: buttonSize,
                                borderRadius: buttonSize / 2
                            }}
                            style={{
                                width: buttonSize,
                                // height: buttonSize,
                                borderRadius: buttonSize / 2,
                                marginLeft: 15
                                // position: "absolute",
                            }}
                            label={
                                <FontAwesome5
                                    solid
                                    name={"paper-plane"}
                                    size={(2 * buttonSize) / 5}
                                    color={theme.colors.success}
                                />
                            }
                        />
                    )}
                    {/*<IconButton*/}
                    {/*    icon={recordingState.isRecording ? "pause" : "microphone"}*/}
                    {/*    iconColor={theme.colors.error}*/}
                    {/*    style={{*/}
                    {/*        position: "absolute",*/}
                    {/*        left: 20,*/}
                    {/*        opacity: recording ? 1.0 : 0.0*/}
                    {/*    }}*/}
                    {/*    disabled={!recording}*/}
                    {/*    size={28}*/}
                    {/*    onPress={() => {*/}
                    {/*        pausePlayRecording().then()*/}
                    {/*    }}*/}
                    {/*/>*/}
                </View>
            )}
        </View>
    );
};
