import React, {useContext, useRef, useState} from "react";
import {Dimensions, View, Platform} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import ServerSearch from "../../admin/ServerSearch";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {chaptersService} from "@data/services/chaptersService";
import {hasRole} from "@data/utility/ability";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import NewChapterModal from "@common/chapters/NewChapterModal";
import {Controller, useController} from "react-hook-form";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {studentClasses} from "@data/constants/formConstants";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import TextM from "@common/components/text/TextM";
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";
import {IconImageM} from "@common/components/icons/IconsM";
import {findItemInListWithId} from "@data/utility/dataUtils";
import graduate from "../../../assets/icons/menus/graduate.png";
import {ActivityIndicator} from "react-native-paper";
import ChapterItemArchive from "@common/chapters/ChapterItemArchive";
import StudyProgramsFilterList from "@common/chapters/StudyProgramsFilterList";
import StudyProgramItemComponent from "@common/studyPrograms/StudyProgramItemComponent";
import {ToggleButtonM} from "@common/components/button/ToggleButtonM";

const SelectChapterScreen = (props) => {
    const {
        control,
        goToNextStep,
        register,
        errors,
        serverError,
        trigger,
        formValues
    } = props;

    const {field} = useController({
        name: "chapter",
        control
    });
    const dimensions = useContext(DimensionsContext);
    const [urlParams, setUrlParams] = useState({
        school_subject_id: "",
        search: "",
        class: control?._formValues?.class ?? "",
        student_ids: control?._formValues?.student_ids?.join(",") ?? null
    });
    const paginatedFlatListRef = useRef();

    const liveSession = props.liveSessions?.find(
        (x) => x?.id === props.liveSessionId
    );

    const studentId = control?._formValues?.chapter?.studentId ?? null;
    const studentsList = props.isLiveMode
        ? liveSession?.users?.filter((user) => !user.is_tutor)
        : props.user?.students;
    const student = studentId
        ? findItemInListWithId(studentId, studentsList)
        : props.user;
    // console.log("student", student)

    const onGoingChapters = props.chapters?.filter(
        (x) =>
            x?.student_chapters?.length > 0 && x?.student_chapters[0]?.ongoing === 1
    );
    const [newChapter, setNewChapter] = useState(
        formValues?.chapter?.new ? formValues?.chapter : null
    );
    const [selectedChapter, setSelectChapter] = useState(
        formValues?.chapter?.new ? null : control?._formValues?.chapter
    );
    // console.log("control?._formValues?.chapter?.id", control?._formValues?.chapter?.id)
    const apiRequest = hasRole(props.user, "student")
        ? chaptersService.getChaptersAsStudent
        : chaptersService.getChaptersAsTutor;

    const [visible, setVisible] = useState(false);
    const handleShowModal = () => setVisible(!visible);

    const canAddChapter = () => {
        // if (onGoingChapters?.length >= props.maxOngoingChapters) {
        //     DialogM.show({
        //         champion: "zoe",
        //         variant: "main",
        //         text1: "Nombre maximum de chapitres en cours atteint",
        //         component: (
        //             <View>
        //                 <TextM
        //                     style={{
        //                         textAlign: "center"
        //                     }}
        //                     variant="bodyMedium"
        //                     wrap
        //                 >
        //                     Archive l'un des chapitres en cours pour en choisir un nouveau
        //                 </TextM>
        //                 <View
        //                     style={{
        //                         // height: dimensions.width < 800 ? dimensions.width / 2 : null,
        //                         // backgroundColor: "red",
        //                         flex: null
        //                     }}
        //                 >
        //                     {onGoingChapters?.length > 0 &&
        //                         onGoingChapters?.map((chapter, index) => {
        //                             const chapterInfos = {
        //                                 ...chapter,
        //                                 ...chapter?.student_chapters[0]
        //                             };
        //                             return (
        //                                 <ChapterItemArchive
        //                                     key={index}
        //                                     chapter={chapterInfos}
        //                                     isHighlighted={selectedChapter?.id === chapter?.id}
        //                                 />
        //                             );
        //                         })}
        //                 </View>
        //             </View>
        //         ),
        //         hideButton1: true,
        //         buttonText2: "Terminer",
        //         buttonColor2: theme.colors.primary,
        //         onPressBack: () => {
        //         }
        //     });
        //     return false;
        // }
        return true;
    };

    const onSelectChapter = (value) => {
        // console.log(value)
        setNewChapter(null);
        field.onChange({...field?.value, ...value});
        goToNextStep();
    };

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedStudyProgramId, setSelectedStudyProgramId] = useState(null);
    const currentStudyProgram = props.studyPrograms?.find(s => s.id === selectedStudyProgramId);

    return (
        <View
            style={{
                // marginTop: 20,
                width: Dimensions.get("window").width,
                alignItems: "center",
                flex: 1,
                alignSelf: "center"
                // backgroundColor: "red",
            }}
        >

                <ToggleButtonM labels={["Rechercher", "Programmes"]}
                               buttonsStyle={{width: IS_WEB ? 180 : 160, marginTop: -20, marginBottom: 3}}
                               trackingIds={["chapters_search", "chapters_by_program"]}
                               color={theme.colors.primary}
                    // disabled
                               initialIndex={selectedIndex}
                               onSelectIndex={setSelectedIndex}/>
            {selectedIndex === 1 ? <>
                <StudyProgramsFilterList studentIds={hasRole(props.user, "student") ? null : (control?._formValues?.student_ids?.join(",") ?? studentId)}
                                         setSelectedStudyProgramId={setSelectedStudyProgramId}
                                         selectedStudyProgramId={selectedStudyProgramId}
                />
                {selectedStudyProgramId && <StudyProgramItemComponent studyProgram={currentStudyProgram}
                                           onSelectChapter={onSelectChapter}
                                           selectedChapterId={selectedChapter?.id}
                                           noTitles />}
            </> : <PaginatedFlatList
                ref={paginatedFlatListRef}
                data={props.chapters?.filter(
                    (x) =>
                        x?.student_chapters?.length === 0 ||
                        x?.student_chapters?.[0]?.ongoing === 0
                )}
                noRefreshWhenNoResults={true}
                contentContainerStyle={{
                    paddingBottom: 100
                }}
                noActivityIndicator
                additionalOnRefresh={() => {
                }}
                additionalRefreshing={false}
                requestApiService={apiRequest}
                requestRouteParams={
                    hasRole(props.user, "student") ? null : {id: studentId}
                }
                requestUrlParams={urlParams}
                requestData={null}
                style={{
                    // marginTop: 20,
                    width: Dimensions.get("window").width,
                    flex: 1
                }}
                ListEmptyComponent={
                    props.chapters?.length === 0 && (
                        <>
                            <TextM
                                style={{
                                    textAlign: "center",
                                    marginTop: 20
                                }}
                            >
                                Aucun chapitre trouvé
                            </TextM>
                            <FilledButtonM
                                onPress={() => {
                                    if (canAddChapter()) {
                                        handleShowModal();
                                    }
                                }}
                                icon={"plus"}
                                style={{
                                    alignSelf: "center",
                                    marginTop: 10
                                }}
                                buttonColor={theme.colors.primary}
                                textColor={theme.colors.white}
                                label="Ajouter un chapitre"
                                mode={"contained"}
                            />
                        </>
                    )
                }
                ListHeaderComponent={
                    <View style={{width: dimensions.width - 40}}>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Controller
                                control={control}
                                name="subject"
                                render={({field: {onChange, value}}) => {
                                    const isEmpty =
                                        value === "" || value === undefined || value === null;

                                    return (
                                        <SelectBox
                                            //title="Matières"
                                            options={props.schoolSubjects.map((x) => {
                                                return {
                                                    value: x.id.toString(),
                                                    label: x.name,
                                                    icon: x?.icon
                                                };
                                            })}
                                            value={value}
                                            onSingleSelect={(value) => {
                                                setUrlParams({
                                                    ...urlParams,
                                                    school_subject_id: value.value
                                                });
                                                onChange(value.value);
                                            }}
                                            style={{flex: 1, margin: 0, marginRight: 10}}
                                            buttonStyle={{
                                                borderWidth: isEmpty ? 0 : 1,
                                                borderColor: theme.colors.lightMore,
                                                borderRadius: theme.roundness,
                                                //width: "100%",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                paddingHorizontal: 12,
                                                paddingVertical: 4,
                                                backgroundColor: isEmpty ? null : theme.colors.white
                                            }}
                                            iconComponent={
                                                value ? (
                                                    <IconImageM
                                                        uri={
                                                            findItemInListWithId(value, props.schoolSubjects)
                                                                ?.icon
                                                        }
                                                        style={{
                                                            marginRight: 10
                                                        }}
                                                        width={IS_WEB ? 20 : 14}
                                                    />
                                                ) : (
                                                    <FontAwesome5
                                                        solid
                                                        name="filter"
                                                        size={IS_WEB ? 20 : 14}
                                                        color={theme.colors.greyAlt}
                                                        style={{marginRight: 10}}
                                                    />
                                                )
                                            }
                                            clearButton
                                            defaultLabel={"Matière"}
                                            valueMode={true}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="class"
                                render={({field: {onChange, value}}) => {
                                    const isEmpty =
                                        value === "" || value === undefined || value === null;

                                    return (
                                        <SelectBox
                                            //title="Matières"
                                            options={studentClasses
                                                .map((x) => ({label: x, value: x}))
                                                .slice(0, -1)}
                                            value={value}
                                            onSingleSelect={(value) => {
                                                setUrlParams({...urlParams, class: value.value});
                                                onChange(value.value);
                                            }}
                                            style={{flex: 1, margin: 0, marginLeft: 10}}
                                            buttonStyle={{
                                                borderWidth: isEmpty ? 0 : 1,
                                                borderColor: theme.colors.lightMore,
                                                borderRadius: theme.roundness,
                                                //width: "100%",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                paddingHorizontal: 12,
                                                paddingVertical: 4,
                                                backgroundColor: isEmpty ? null : theme.colors.white
                                            }}
                                            iconComponent={
                                                value ? (
                                                    <IconImageM
                                                        source={graduate}
                                                        style={{
                                                            marginRight: 10
                                                        }}
                                                        width={IS_WEB ? 20 : 14}
                                                    />
                                                ) : (
                                                    <FontAwesome5
                                                        solid
                                                        name="filter"
                                                        size={IS_WEB ? 20 : 14}
                                                        color={theme.colors.greyAlt}
                                                        style={{marginRight: 10}}
                                                    />
                                                )
                                            }
                                            clearButton
                                            defaultLabel={props.user?.profile?.class ?? "Classe"}
                                            valueMode={true}
                                        />
                                    );
                                }}
                            />
                        </View>
                        <ServerSearch
                            style={{
                                width: dimensions.width - 40,
                                boxShadow: "none",
                                borderWidth: 1,
                                borderColor: theme.colors.lightMore,
                                margin: 0,
                                marginTop: 4
                                // height: 55
                            }}
                            request={
                                props.getChaptersAsStudentRequest ||
                                props.getChaptersAsTutorRequest
                            }
                            urlParams={urlParams}
                            setUrlParams={setUrlParams}
                        />

                        {IS_WEB &&
                            (props.getChaptersAsStudentRequest ||
                                props.getChaptersAsTutorRequest) && (
                                <ActivityIndicator
                                    color={theme.colors.primary}
                                    style={{marginVertical: 20}}
                                />
                            )}

                        {newChapter && (
                            <View
                                style={{
                                    //alignItems: "center",
                                    marginTop: 20
                                }}
                            >
                                <TextM fontWeight={"Bold"} fontSize={18}>
                                    {"Nouveau chapitre".toUpperCase()}
                                </TextM>
                                <ChapterItemComponent
                                    chapter={newChapter}
                                    withParentChapter
                                    // renderChapterOnly={Platform.OS === "android"}
                                    isHighlighted={!selectedChapter?.id}
                                    onPress={() => {
                                        if (canAddChapter()) {
                                            field.onChange(newChapter);
                                            //setSelectChapter(newChapter)
                                            goToNextStep();
                                        }
                                    }}
                                />
                            </View>
                        )}
                        {onGoingChapters?.length > 0 && (
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: 30
                                }}
                            >
                                <TextM fontWeight={"Bold"} fontSize={18}>
                                    {"chapitres en cours".toUpperCase()}
                                </TextM>
                                <View
                                    style={{
                                        padding: 3,
                                        borderRadius: 50,
                                        backgroundColor: theme.colors.greyAlt,
                                        width: 24,
                                        height: 24,
                                        marginLeft: 10,
                                        alignItems: "center"
                                    }}
                                >
                                    <TextM
                                        color={theme.colors.white}
                                        fontSize={14}
                                        fontWeight={"Bold"}
                                    >
                                        {onGoingChapters?.length}
                                    </TextM>
                                </View>
                            </View>
                        )}
                        {onGoingChapters?.length > 0 &&
                            onGoingChapters?.map((chapter, index) => {
                                const chapterInfos = {
                                    ...chapter,
                                    ...chapter?.student_chapters[0]
                                };
                                return (
                                    <ChapterItemComponent
                                        key={index}
                                        chapter={chapterInfos}
                                        withParentChapter
                                        // renderChapterOnly={Platform.OS === "android"}
                                        isHighlighted={selectedChapter?.id === chapter?.id}
                                        onPress={() => onSelectChapter(chapter)}
                                    />
                                );
                            })}
                        {onGoingChapters?.length > 0 && (
                            <TextM
                                fontWeight={"Bold"}
                                fontSize={18}
                                style={{marginTop: 30}}
                            >
                                {"autres chapitres".toUpperCase()}
                            </TextM>
                        )}
                    </View>
                }
                renderItem={(item) => {
                    const chapterInfos = {
                        ...item.item,
                        ...item?.item?.student_chapters[0],
                        studentChapterId: item?.item?.student_chapters[0]?.id
                    };
                    return (
                        <ChapterItemComponent
                            chapter={chapterInfos}
                            key={item.index}
                            withParentChapter
                            // renderChapterOnly={Platform.OS === "android"}
                            onPress={() => {
                                if (canAddChapter()) {
                                    onSelectChapter(item.item);
                                }
                            }}
                            isHighlighted={selectedChapter?.id === item.item?.id}
                        />
                    );
                }}
            />}
            <View
                style={{
                    alignSelf: "center",
                    width: Dimensions.get("window").width,
                    position: "absolute",
                    borderTopWidth: 1,
                    borderColor: theme.colors.lightMore,
                    bottom: 0,
                    // right: 0,
                    backgroundColor: theme.colors.whiteAlmost
                    // paddingTop: 10
                }}
            >
                <FilledButtonM
                    mode={"text"}
                    style={{
                        alignSelf: "center",
                        marginBottom: 10
                    }}
                    contentStyle={
                        {
                            // height: 40
                        }
                    }
                    onPress={() => {
                        DialogM.show({
                            text1: "Continuer sans chapitre ?",
                            text2: "Ta progression grâce à cette activité ne sera pas suivie",
                            buttonText1: "Continuer sans chapitre",
                            buttonColor1: theme.colors.primary,
                            onPress: () => {
                                field.onChange({
                                    studentId: field?.value?.studentId ?? null
                                });
                                goToNextStep();
                            },
                            buttonText2: "Annuler"
                        });
                    }}
                    color={theme.colors.primary}
                    label={"continuer sans chapitre"}
                />
            </View>
            {visible && (
                <NewChapterModal
                    handleShowModal={handleShowModal}
                    isOpen={visible}
                    setNewChapter={setNewChapter}
                    onSelectChapter={onSelectChapter}
                    defaultValues={{
                        name: urlParams?.search,
                        school_subject_id: urlParams?.school_subject_id,
                        class: urlParams?.class
                    }}
                />
            )}
        </View>
    );
};
const mapStateToProps = (state) => {
    return {
        getChaptersAsStudentRequest: state.api.getChaptersAsStudent?.request,
        getChaptersAsTutorRequest: state.api.getChaptersAsTutor?.request,
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        maxOngoingChapters: state.data.staticData?.max_ongoing_chapters,
        chapters: state.data?.chapters?.chaptersList,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        studyPrograms: state.data.studyPrograms?.studyPrograms,
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectChapterScreen);
