import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {RankingListItem} from "../../student/social/RankingScreen";
import {formatHoursMinutesAndSeconds} from "@common/liveMode/live-report/LiveReportComponent";

const LiveStepper = (props) => {

    const {live_id, room_id} = props.route.params
    const room = findItemInListWithId(room_id, props.rooms)
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    // let possibleTimes = slotsList("07:00", "22:00", 60)
    // const liveDay = live_id ? props.liveSession?.day_time?.split(' ')[0] : null
    // const liveTime = live_id ? props.liveSession?.day_time?.split(' ')[1] : null
    // const liveTutor = live_id ? props.liveSession?.tutor?.id : null
    const editing = !!live_id
    const defaultValues = {}
    const formData = [
        {
            label: "Choisir le créneau de session live",
            type: "day-time",
            name: "slot",
            defaultValue: props.liveSession?.day_time ?? "",
            validationRules: {...validation.required()}
        },
        {
            label: "Choisir le tuteur",
            name: "tutor",
            type: "select.paginated",
            singleMode: true,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "tutor",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: props.liveSession?.tutor?.id ?? "",
            validationRules: {...validation.required()}
        },
        // {
        //     label: "Choisir la salle",
        //     type: "select.single",
        //     name: "live_room_id",
        //     options: props.rooms
        //         ?.map(x => {
        //             //console.log(x?.day_time)
        //             return {
        //                 value: x?.id,
        //                 label: x?.name,
        //                 description: x?.students_count === 0 ? "Aucun élève" : x?.students_count + " élèves"
        //                 //avatar: x?.tutor
        //
        //             }
        //         }),
        //     defaultValue: props.liveSession?.live_room_id,
        //     validationRules: {...validation.required()}
        // },
        {
            label: "Choisir la durée de session",
            type: "select.single",
            name: "duration",
            options: Array.from({length: 8}, (v, k) => (k + 1) * 15).map(x => ({value: x, label: formatHoursMinutesAndSeconds(x*60, 12, true)})),
            defaultValue: props.liveSession?.duration ?? (room?.type === "holiday_course" ? 120 : 60),
            validationRules: {...validation.required()}
        }
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(defaultValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.newLiveRequest || props.updateLiveRequest) return
        //console.log(data)
        if (editing)
            props.apiRequest(backOfficeService.updateLiveSession, {id: live_id}, {
                'tutor_id': data?.tutor,
                'slot': data?.slot,
                'duration': data?.duration,
                'live_room_id': room_id
            })
        else
            props.apiRequest(backOfficeService.newLiveSession, {}, {
                'tutor_id': data?.tutor,
                'slot': data?.slot,
                'duration': data?.duration,
                'live_room_id': room_id
            })
    }
    useApiStatus(
        backOfficeService.newLiveSession, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(backOfficeService.getLiveSessions, {}, {}, {})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("admin-lives")
        } // success callback
    )
    useApiStatus(
        backOfficeService.updateLiveSession, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // props.apiRequest(roomService.getRooms, {}, {}, {type: room?.type})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("admin-lives", {live_id: live_id})
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.newLiveRequest || props.updateLiveRequest}
                         previousRouteName={"admin-lives"}
                         submittedMessage=""
                         serverError={props.updateLiveError || props.newLiveError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        tutors: state.data.tutors?.list,
        newLiveRequest: state.api.newLiveSession?.request,
        updateLiveRequest: state.api.updateLiveSession?.request,
        newLiveError: state.api.newLiveSession?.error,
        updateLiveError: state.api.updateLiveSession?.error,
        liveSession: state.data.backOffice?.liveSession,
        rooms: state.data.backOffice?.rooms,
        users: state.data.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveStepper)
