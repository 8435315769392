import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {apiIdle} from "@data/redux/actions/api"
import {DialogM} from "@common/components/alert/dialog/Dialog";

export function useApiStatus(
    serviceName,
    successMessage = "",
    errorMessage = true,
    successCallback = (() => {
    }),
    errorCallback = (() => {
    }),
    noIdle = false
) {

    const serviceApiSelector = useSelector(state => state.api?.[serviceName?.name])
    const dispatch = useDispatch()

    useEffect(() => {
        if (serviceApiSelector?.success) {
            // console.log(noIdle, noIdle)
            if (!noIdle) {
                // console.log("IDLE")
                dispatch(apiIdle(serviceName))
            }
            if (successMessage)
                DialogM.show({
                    text1: 'Succès', text2: successMessage
                })
            //alertPolyfill('Succès', successMessage)
            successCallback(serviceApiSelector?.data, serviceApiSelector?.all_data, serviceApiSelector?.urlParams)
        }

        if (serviceApiSelector?.error) {
            console.log(serviceApiSelector?.error?.message)
            if (errorMessage) {
                DialogM.show({
                    text1: 'Erreur', text2: serviceApiSelector?.error?.message
                })
                //alertPolyfill('Erreur', serviceApiSelector?.error?.message)
                dispatch(apiIdle(serviceName))
            }
            errorCallback(serviceApiSelector?.error, serviceApiSelector?.urlParams)
        }

    }, [serviceApiSelector?.success, serviceApiSelector?.error])
}
