import React, {useContext, useState} from 'react';
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {Tabs, TabScreen} from 'react-native-paper-tabs';
import {theme} from "@common/theme/theme";
import {Dimensions, Platform, View} from "react-native";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";
import TutorsApplicationsScreen from "./TutorsApplicationsScreen";
import ServerSearch from "../ServerSearch";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import {candidaturesStatus, subscribedProducts, subscriptionStates} from "@data/constants/formConstants";
import {allOptionsFromSectionOptions} from "@data/utility/Utils";
import {backOfficeService} from "@data/services/backOfficeService";

const TutorApplicationsTabNavigator = (props) => {

    const {navigation, route} = props
    const {initialTab} = route.params;
    const dimensions = useContext(DimensionsContext)
    const role = ["tutor-candidate", "tutor"]
    const [urlParams, setUrlParams] = useState({ roles: role?.join(",") ?? ""})
    const [formValues, setFormValues] = useState(null)
    const [filterDisabled, setFilterDisabled] = useState()

    const requestApiService = backOfficeService.searchUser

    const urlParamsAdditional = {
        candidature_status: formValues?.candidature_status?.join(",") ?? "",
    }

    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            // console.log("newFormValues", newFormValues)
            // console.log("urlParams", candidatureSentUrlParams)
            props.apiRequest(requestApiService, {}, {}, {
                ...candidatureSentUrlParams,
                candidature_status: newFormValues?.candidature_status?.join(",") ?? "",
            })
        } else {
            // console.log("urlParamsAdditional", urlParamsAdditional)
            // console.log("urlParams", candidatureSentUrlParams)
            props.apiRequest(requestApiService, {}, {}, {...candidatureSentUrlParams, ...urlParamsAdditional})
        }
    }

    // ** Filter options
    const filterItems = [
        {
            name: "candidature_status",
            label: "Statut",
            sectionMode: true,
            options: candidaturesStatus,
            defaultValue: allOptionsFromSectionOptions(candidaturesStatus)
        }
    ]

    const candidatureSentUrlParams = {...urlParams, candidature_sent: 1, sort_by: "candidature_sent_at", order: "desc"}

    const tabs = [
        {
            name: "En cours",
            component: TutorsApplicationsScreen,
            tabIndex: 0,
            urlParams: {...urlParams, candidature_sent: 0}
        },
        {
            name: "Soumises",
            component: TutorsApplicationsScreen,
            tabIndex: 1,
            urlParams: candidatureSentUrlParams
        },
    ]


    return <>
        <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>
            <>
                <View style={{flexDirection: "row", alignItems: "center", marginRight: 20}}>
                    <IconButton
                        icon={"times"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{
                            // position: "absolute",
                            marginRight: 10
                        }}
                        onPress={() => {
                            if (navigation.canGoBack()) navigation.goBack()
                            else navigation.replace("menu")
                        }}
                    />
                    <TitleM>Liste des candidatures</TitleM>
                </View>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <MenuFilter style={{
                        alignItems: "space-between",
                        width: 300,
                        marginBottom: 10
                    }}
                                setFormValues={setFormValues}
                                formValues={formValues}
                                filterItems={filterItems}
                                onFinishSelecting={onFinishSelecting}
                    />
                    <ServerSearch style={{
                        width: 240,
                        margin: 10
                    }}
                                  urlParams={urlParams}
                                  setUrlParams={setUrlParams}
                    />
                </View>
            </>
        </HeaderContainerM>
        <Tabs
            defaultIndex={initialTab ?? 1}
            style={{
                backgroundColor: theme.colors.white,
                width: Platform.OS === 'web' ? Dimensions.get("window").width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
            disableSwipe={true}
            onChangeIndex={(newIndex) => {
                if (newIndex === 0) {
                    setFilterDisabled(true)
                } else {
                    setFilterDisabled(false)
                }
            }}
        >
            {tabs.map((tab) => {
                return <TabScreen label={tab.name}
                                  key={tab.name}
                    // icon={<TextM>t</TextM>}
                >
                    {React.createElement(tab.component, {
                        name: tab?.name,
                        tab: tab?.tabIndex,
                        urlParams: tab?.urlParams
                    })}
                </TabScreen>
            })}

        </Tabs>
    </>

};

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationsTabNavigator)