import React, {useContext} from 'react';
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import UsersTable from "../UsersTable";
import {DimensionsContext} from "@common/utils/mobileUtils";

const TutorsApplicationsScreen = (props) => {

    const {tab, urlParams} = props
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    /*
        useEffect(() => {
            props.apiRequest(backOfficeService.searchUser, {}, {}, {
                roles: role?.join(",") ?? "",
                ...urlParams
            })
        }, []);*/

    return <View style={{width: dimensions?.width * 3 / 2, flex: 1, zIndex: -1}}>
        {props.usersRequest
            ? <ActivityIndicator color={theme.colors.primary}/>
            : <UsersTable
                tabIndex={1}
                urlParams={{...urlParams}}
                tab={props.name}/>
        }
    </View>
}
const mapStateToProps = state => {
    return {
        usersRequest: state.api?.getAllUsers?.request,
        users: state.data?.users?.usersList
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorsApplicationsScreen)