import {TouchableHighlight, View} from "react-native";
import React, {useContext, useState} from "react"
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {plr} from "@data/utility/Utils";
import money from "../../../../assets/icons/generic/money.png";
import liveChat from "../../../../assets/icons/menus/live-chat.png";
import {StatisticsItemCol} from "@common/components/statistics/StatisticsItemCol";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";
import {EngagementScoreComponent} from "../../../admin/live/LiveReportsScreen";
import {formatEngagementScoreData} from "../../../admin/interviewReports/StatisticsStep";
import moment from "moment";

function CoachStats(props) {


    const [periodName, setPeriodName] = useState("Ce mois-ci")
    const [period, setPeriod] = useState("this_month")
    const lives = props.user?.statistics?.lives
    const interviews = props.user?.statistics?.interviews
    const activity_review = props.user?.statistics?.activity_review
    const onPeriodPress = () => {
        if (periodName === "Ce mois-ci") {
            setPeriodName("Le mois dernier")
            setPeriod("last_month")
        } else {
            setPeriodName("Ce mois-ci")
            setPeriod("this_month")
        }
    }
    const dimensions = useContext(DimensionsContext)

    const startDate = period === "this_month" ? moment().startOf('month').format("DD MMM") : moment().add(-1, "month").startOf('month').format("DD MMM")
    const endDate = period === "this_month" ? moment().add(-25, "minutes").add(-1, "days").format("DD MMM") : moment().add(-1, "month").endOf('month').format("DD MMM")


    return (
        <View>
            {props.user?.students && props.user?.students?.length > 0 &&
                <View style={{
                    marginTop: 30,
                    marginBottom: 30,
                    borderColor: theme.colors.lightMore,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    backgroundColor: theme.colors.white,
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    width: dimensions?.width,
                    ...boxedCardBorderStyle
                }}>
                    <View style={{}}>
                        <TextM fontWeight={"Bold"} fontSize={20}>Mes statistiques</TextM>
                    </View>

                    <TouchableHighlight onPress={onPeriodPress}
                                        underlayColor={theme.colors.lighter}
                                        style={{
                                            borderWidth: 1,
                                            borderColor: theme.colors.lightMore,
                                            backgroundColor: theme.colors.white,
                                            borderRadius: 10,
                                            alignItems: "center",
                                            width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 2 : dimensions?.width - 20,
                                            paddingVertical: 5,
                                            marginTop: 20,
                                            alignSelf: 'center'
                                        }}>
                        <TextM color={theme.colors.grey} fontWeight="Medium">{periodName.toUpperCase()}</TextM>
                    </TouchableHighlight>
                    <TextM fontWeight={"Light"} fontSize={12}
                           style={{textAlign: "center"}}>Du {startDate} au {endDate}{lives?.[period]?.pending_review_live_units > 0 && "*"}</TextM>

                    <View style={{
                        flexDirection: "row",
                        marginTop: 10,
                        paddingVertical: 10,
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: 'space-between',
                        alignSelf: "center",
                        paddingHorizontal: 10
                    }}>
                        <StatisticsItemCol source={liveChat}
                                           count={lives?.[period]?.validated_live_units}
                                           title={plr("heure", lives?.[period]?.validated_live_units) + " de " + plr("live", lives?.[period]?.pending_review_live_units) + plr(" validée", lives?.[period]?.validated_live_units)}
                                           style={{
                                               // width: IS_WEB && dimensions?.width > 800 ? dimensions?.width / 4 - 4 : null
                                               paddingHorizontal: 15,
                                               width: dimensions?.width / 3 - 4
                                           }}
                        />
                        <StatisticsItemCol source={money}
                                           count={lives?.[period]?.validated_transfer_amount + "€"}
                                           title={"montant validé cumulé"}
                                           style={{
                                               // width: IS_WEB && dimensions?.width > 800 ? dimensions?.width / 4 - 4 : null,
                                               paddingHorizontal: 15,
                                               width: dimensions?.width / 3 - 4
                                           }}
                        />
                    </View>


                    {<View style={{
                        marginTop: 40,
                        paddingVertical: 10,
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: 'center',
                        alignSelf: "center",
                        paddingHorizontal: 10
                    }}>
                        <TextM fontWeight={"Bold"}
                               style={{textAlign: "center"}}
                               fontSize={20}
                               wrap>Moyenne des scores d'engagement de mes élèves</TextM>
                        {lives?.[period]?.average_students_total_score > 0 ? <EngagementScoreComponent
                            totalScore={lives?.[period]?.average_students_total_score}
                            scores={formatEngagementScoreData(lives?.[period]?.average_students_assiduity, lives?.[period]?.average_students_interaction, lives?.[period]?.average_students_productivity)}
                            style={{
                                // backgroundColor: 'red',
                                // marginTop: 15
                                marginBottom: 20,
                                flex: 1
                                // height: 300
                            }}
                            forceVerticalDetails={dimensions.width < 800}
                            width={"100%"}
                            disableScoreButton
                        /> : <TextM fontWeight={"Light"} fontSize={12} style={{textAlign: "center"}} wrap>Aucune donnée</TextM>}
                        {lives?.[period]?.pending_review_live_units > 0 && <TextM fontWeight={"Light"}
                                                                                  style={{marginTop: 10}}
                                                                                  fontSize={10} wrap>*Les statistiques ne prennent pas en compte certains Lives en attente de validation</TextM>}
                    </View>}
                </View>}
            {/*{props.user?.students && props.user?.students?.length > 0 &&*/}
            {/*    <View style={{*/}
            {/*        marginTop: 30,*/}
            {/*        borderColor: theme.colors.lightMore,*/}
            {/*        borderTopWidth: 1,*/}
            {/*        borderBottomWidth: 1,*/}
            {/*        backgroundColor: theme.colors.white,*/}
            {/*        paddingHorizontal: 20,*/}
            {/*        paddingVertical: 20,*/}
            {/*        width: dimensions?.width,*/}
            {/*        ...boxedCardBorderStyle*/}
            {/*    }}>*/}
            {/*        <TextM fontWeight={"Bold"} style={{}} fontSize={20}>Statistiques de mes élèves</TextM>*/}
            {/*        <CoachCharts students={props.user?.students}/>*/}
            {/*    </View>}*/}


        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request,
        interviewFinalizeSuccess: state.api.interviewFinalize?.success
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachStats)