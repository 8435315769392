import {KeyboardAvoidingView, ScrollView, TouchableOpacity, View} from "react-native";
import {useController} from "react-hook-form";
import {ListGroupM} from "../list/ListGroupM";
import {ListItemM} from "../list/ListItemM";
import TitleM from "../text/TitleM";
import TextM from "../text/TextM";
import React, {useContext, useEffect, useRef, useState} from "react";
import TextInputM from "../input/TextInputM";
import ErrorTextM from "../text/ErrorTextM";
import FilledButtonM from "../button/FilledButtonM";
import TextButtonM from "../button/TextButtonM";
import {theme} from "../../theme/theme";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
//import CameraImagesM from "../../camera/CameraImagesM";
import TouchableHighlightM from "../button/TouchableHighlightM";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import * as amplitude from "@amplitude/analytics-react-native";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import DateTimePicker from "../../../admin/DateTimePicker";
import CameraImagesM from "@common/camera/CameraImagesM";
import {addMediaToField, plr} from "@data/utility/Utils";
import {formIds} from "@data/constants/formConstants";
import {IconTextM} from "@common/components/icons/IconTextM";
import {ActivityIndicator, Switch} from "react-native-paper";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {IconImageM} from "@common/components/icons/IconsM";
import ServerSearch from "../../../admin/ServerSearch";
import {useSelector} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";

export const FormStep = (props) => {
    // ** Component props
    const {
        data,
        control,
        setError,
        track = false,
        errors,
        goToNextStep,
        isLastStep,
        trigger,
        formValues,
        setFormValues,
        register,
        resetsToDefaultOnNext,
        hideNextButton,
        isStepDirty,
        champion,
        serverError,
        index
    } = props;

    const {field} = useController({
        control,
        name: data?.name ?? ""
    });
    const insets = useSafeAreaInsets();

    useEffect(() => {
        amplitude.track(
            "form.step.started:" +
            (data?.name ?? data?.inputs?.map((x) => x?.name).join(",") ?? "unknown")
        );
    }, []);

    // console.log("data.name ", data?.name)
    //
    // console.log("current field ",field)

    const renderBottomComponent = () => {
        return (
            data?.bottomComponent && (
                <View
                    style={{
                        marginBottom: 20,
                        marginTop: "auto"
                    }}
                >
                    {typeof data?.bottomComponent === "function"
                        ? data?.bottomComponent(control, goToNextStep, errors, formValues)
                        : data?.bottomComponent}
                </View>
            )
        );
    };
    const renderTopComponent = () => {
        return (
            data?.topComponent && (
                <View
                    style={
                        {
                            // marginBottom: Platform.OS === "ios" ? 20 : 10, marginTop: "auto"
                        }
                    }
                >
                    {data?.topComponent}
                </View>
            )
        );
    };
    const dimensions = useContext(DimensionsContext);

    const renderInput = () => {
        switch (data?.type) {
            case "select.single":
                if (data?.loading)
                    return (
                        <View
                            style={{
                                alignItems: "center",
                                marginTop: 20,
                                flex: 1,
                                justifyContent: "center"
                            }}
                        >
                            <ActivityIndicator
                                color={theme.colors.primary}
                                style={{alignSelf: "center"}}
                            />
                        </View>
                    );
                else if (data?.options?.length === 0)
                    return (
                        <View
                            style={{
                                alignItems: "center",
                                marginTop: 20,
                                flex: 1,
                                justifyContent: "center"
                            }}
                        >
                            {data?.emptyIconName && (
                                <FontAwesome5
                                    name={data?.emptyIconName ?? "times"}
                                    color={theme.colors.light}
                                    solid
                                    size={40}
                                />
                            )}
                            {data?.emptyIcon && (
                                <IconImageM
                                    source={data?.emptyIcon}
                                    width={40}
                                    style={{
                                        tintColor: theme.colors.light
                                    }}
                                />
                            )}
                            <TextM
                                wrap
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    marginHorizontal: 20
                                }}
                                fontWeight={"Medium"}
                                color={theme.colors.light}
                            >
                                {data?.emptyMessage ?? "Aucun élément"}
                            </TextM>
                        </View>
                    );
                else
                    return (
                        <ListGroupM
                            options={data?.options}
                            initialScrollIndex={data?.initialVisibleIndex}
                            sectionMode={data?.sectionMode}
                            style={{
                                // marginHorizontal: 20
                                marginBottom: 70
                                //todo: I removed this because it was causing a bug (can't scroll)
                                //overflow: "hidden",
                            }}
                            itemHeight={data?.height ? data?.height : 70}
                            renderSectionHeader={({
                                                      section: {title, isFirst = false}
                                                  }) => {
                                //const sectionData = options?.find(option => option?.title === title)?.data

                                return (
                                    <View
                                        style={{
                                            backgroundColor: theme.colors.lightMore,
                                            borderTopStartRadius: isFirst ? 14 : 0,
                                            borderTopEndRadius: isFirst ? 14 : 0,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 15,
                                            paddingBottom: 10,
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}
                                    >
                                        <TextM fontWeight={"Medium"}>{title}</TextM>
                                    </View>
                                );
                            }}
                            renderItem={(item) => {
                                return (
                                    <ListItemM
                                        isFirst={!data?.sectionMode && item.index === 0}
                                        isLast={
                                            !data?.sectionMode &&
                                            item.index === data?.options?.length - 1
                                        }
                                        onPress={() => {
                                            field.onChange(item.item?.value);
                                            setFormValues({
                                                ...control._formValues,
                                                [field?.name]: item.item?.value
                                            });
                                            if (!isLastStep) goToNextStep();
                                        }}
                                        itemHeight={data?.height ? data?.height : 70}
                                        selected={item.item?.value === field.value}
                                        option={item.item}
                                        disabled={item.item?.disabled}
                                    />
                                );
                            }}
                        />
                    );

            case "select.card.single":
                return (
                    <ScrollView
                        style={{
                            alignSelf: "center",
                            width: dimensions?.width,
                            height: "100%"
                        }}
                        contentContainerStyle={{
                            alignItems: "center",
                            justifyContent: "center",
                            paddingBottom: 40
                        }}
                    >
                        {data?.options?.map((option, index) => {
                            const isSelected = option?.value === field?.value;

                            return (
                                <TouchableHighlightM
                                    key={index}
                                    onPress={
                                        option?.disabled
                                            ? null
                                            : () => {
                                                field.onChange(option?.value);
                                                goToNextStep();
                                            }
                                    }
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        backgroundColor: !isSelected
                                            ? theme.colors.white
                                            : theme.colors.linkLighter,
                                        borderWidth: !isSelected ? 1 : 2,
                                        borderColor: !isSelected
                                            ? theme.colors.lightMore
                                            : theme.colors.primary,
                                        borderRadius: 15,
                                        width: dimensions?.width - 40,
                                        marginTop: 20
                                    }}
                                    disabled={option?.disabled}
                                    underlayColor={theme.colors.lightMore}
                                >
                                    {React.createElement(data?.cardContentComponent, {
                                        labelData: option?.labelData
                                    })}
                                </TouchableHighlightM>
                            );
                        })}
                    </ScrollView>
                );

            case "select.grouped.single":
                return (
                    <ScrollView
                        style={{
                            alignSelf: "center",
                            width: dimensions?.width,
                            height: "100%"
                        }}
                        contentContainerStyle={{
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {data?.options?.map((option, index) => {
                            return (
                                option?.subOptions?.length > 0 && (
                                    <View
                                        key={index}
                                        // onPress={option?.disabled ? null : () => {
                                        //     field.onChange(option?.value)
                                        //     goToNextStep()
                                        // }}
                                        style={{
                                            alignItems: "flex-start",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            backgroundColor: theme.colors.white,
                                            borderWidth: 1,
                                            borderColor: theme.colors.lightMore,
                                            borderRadius: 15,
                                            width: dimensions?.width - 40,
                                            marginTop: 20,
                                            padding: 10
                                        }}
                                    >
                                        <TextM fontWeight="Bold" fontSize={18}>
                                            {option?.label}
                                        </TextM>
                                        <View
                                            style={{
                                                flexWrap: "wrap",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginTop: 20,
                                                width: "100%"
                                            }}
                                        >
                                            {option?.subOptions?.map((subOption, subIndex) => {
                                                const isSelected = subOption?.value === field?.value;

                                                return (
                                                    <TouchableHighlightM
                                                        key={subIndex}
                                                        onPress={() => {
                                                            field.onChange(subOption?.value);
                                                            goToNextStep();
                                                        }}
                                                        style={{
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor: isSelected
                                                                ? theme.colors.linkLighter
                                                                : theme.colors.white,
                                                            borderWidth: isSelected ? 2 : 1,
                                                            borderColor: isSelected
                                                                ? theme.colors.primary
                                                                : theme.colors.lightMore,
                                                            borderRadius: 15,
                                                            paddingHorizontal: 25,
                                                            paddingVertical: 20,
                                                            margin: 5
                                                        }}
                                                        underlayColor={theme.colors.lightMore}
                                                    >
                                                        <TextM
                                                            fontWeight={isSelected ? "Bold" : null}
                                                            color={isSelected ? theme.colors.primary : null}
                                                        >
                                                            {subOption?.label}
                                                        </TextM>
                                                    </TouchableHighlightM>
                                                );
                                            })}
                                        </View>
                                    </View>
                                )
                            );
                        })}
                    </ScrollView>
                );

            case "select.multiple":
                useEffect(() => {
                    if (resetsToDefaultOnNext) field.onChange(data?.defaultValue);
                }, []);

                return (
                    <ListGroupM
                        options={data?.options}
                        itemHeight={data?.height ? data?.height : 70}
                        sectionMode={data?.sectionMode}
                        style={{
                            // marginHorizontal: 20,
                            alignSelf: "center"
                        }}
                        renderSectionHeader={({section: {title, isFirst = false}}) => {
                            //const sectionData = options?.find(option => option?.title === title)?.data

                            return (
                                <View
                                    style={{
                                        backgroundColor: theme.colors.lightMore,
                                        borderTopStartRadius: isFirst ? 14 : 0,
                                        borderTopEndRadius: isFirst ? 14 : 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        flexDirection: "row"
                                    }}
                                >
                                    <TextM fontWeight={"Medium"}>{title}</TextM>
                                </View>
                            );
                        }}
                        renderItem={(item) => {
                            const selected = field.value?.includes(item.item?.value);

                            return (
                                <ListItemM
                                    isFirst={!data?.sectionMode && item.index === 0}
                                    isLast={
                                        !data?.sectionMode &&
                                        item.index === data?.options?.length - 1
                                    }
                                    onPress={() => {
                                        // console.log(control._formValues)
                                        const value = field.value ? field.value?.slice() : [];
                                        if (selected) {
                                            value?.splice(value?.indexOf(item.item?.value), 1);
                                        } else {
                                            value?.push(item.item?.value);
                                        }
                                        field.onChange(value);
                                        setFormValues({
                                            ...control._formValues,
                                            [field?.name]: value
                                        });
                                        // console.log(control._formValues)
                                        trigger();
                                    }}
                                    itemHeight={data?.height ? data?.height : null}
                                    selected={selected}
                                    option={item.item}
                                    style={{
                                        paddingVertical: 20
                                    }}
                                />
                            );
                        }}
                    />
                );
//TODO : delete this deprecated case (use text.multiple instead)
            case "text.single":
                return (
                    <TextInputM
                        placeholder={data?.placeholder}
                        style={
                            {
                                // marginHorizontal: 20
                            }
                        }
                        autofocus
                        name={data?.name}
                        control={control}
                        errors={errors}
                    />
                );

            case "photos":
                const [images, setImages] = useState(
                    data.defaultValue?.map((x) => {
                        return {
                            uri: x,
                            width: IS_WEB ? dimensions?.width * 0.4 : dimensions?.width * 0.6,
                            height: dimensions?.height * 0.4
                        };
                    }) ?? []
                );

                //console.log(field.value)

                // console.log(field?.value?.file_keys)
                useEffect(() => {
                    // console.log("images change", images)
                    addMediaToField(field, images, true);

                    setFormValues({
                        ...control._formValues,
                        [field?.name]: images?.map((file) => {
                            return {
                                type: "image/png",
                                height: file?.height,
                                width: file?.width,
                                uri: file?.uri
                            };
                        })
                    });
                    //console.log(field)
                }, [images]);

                return (
                    <CameraImagesM
                        images={images}
                        setImages={setImages}
                        style={{
                            marginTop: 20,
                            height: dimensions?.height * 0.6
                        }}
                        formId={formIds.NEW_MATERIAL}
                        useGallery
                    />
                );

            case "text.multiple":
                const inputRefs = data?.inputs?.map((i, index) => useRef());

                // const inputControllers = data?.inputs?.map((i, index) => useController({
                //     control,
                //     name: i?.name
                // }))

                useEffect(() => {
                    // inputRefs[0].current.focus()
                    if (!isStepDirty)
                        setTimeout(function () {
                            inputRefs[0]?.current?.focus();
                        }, 100);
                }, []);

                return (
                    <ScrollView style={{paddingBottom: 100}}>
                        {data?.inputs?.map((i, index) => {
                            // console.log(i?.validationRules)

                            return (
                                <React.Fragment key={index}>
                                    {i?.label && (
                                        <TitleM
                                            color={theme.colors.primary}
                                            style={{
                                                marginBottom: 10, // marginHorizontal: 20,
                                                marginTop: 10,
                                                textAlign: "left"
                                            }}
                                            fontWeight="ExtraBold"
                                        >
                                            {i?.label}
                                        </TitleM>
                                    )}
                                    <TextInputM
                                        {...register(i?.name, i?.validationRules)}
                                        onChangeAdditional={(value) => {
                                            setFormValues({
                                                ...control._formValues,
                                                [i?.name]: value
                                            });
                                        }}
                                        disabled={i?.disabled}
                                        hidden={i?.hidden}
                                        setError={setError}
                                        liveCheck={i?.liveCheck}
                                        liveCheckPreRequest={i?.liveCheckPreRequest}
                                        placeholder={i?.placeholder}
                                        ref={inputRefs[index]}
                                        // autofocus={index === 0}
                                        keyboardType={i?.keyboardType ?? null}
                                        inputMode={i?.inputMode ?? null}
                                        onSubmitEditing={(e) => {
                                            if (index < data?.inputs?.length - 1)
                                                inputRefs[index + 1].current?.focus();
                                            // else
                                            //     goToNextStep()
                                        }}
                                        returnKeyType={
                                            index === data?.inputs?.length - 1 ? "done" : "next"
                                        }
                                        contentContainerStyle={
                                            {
                                                // width: IS_WEB ? dimensions?.width * 3 / 5 : "100%"
                                            }
                                        }
                                        multiline={!!i?.height}
                                        style={{
                                            // marginHorizontal: 20
                                            height: i?.height ?? null
                                        }}
                                        password={i?.password}
                                        key={index}
                                        // name={i?.name}
                                        control={control}
                                        errors={errors}
                                    />
                                </React.Fragment>
                            );
                        })}
                        {data?.checkBoxes &&
                            data?.checkBoxes?.map((cb, key) => {
                                const {field} = useController({
                                    control,
                                    name: cb?.name ?? ""
                                });

                                return (
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            marginTop: 50,
                                            alignItems: "center",
                                            // backgroundColor: "red",
                                            justifyContent: "space-between",
                                            marginHorizontal: 10
                                        }}
                                        key={key}
                                    >
                                        <IconTextM
                                            text={cb?.label}
                                            style={{
                                                width: "70%",
                                                marginHorizontal: 0,
                                                marginTop: 0
                                            }}
                                            icon={cb?.icon}
                                            color={theme.colors.primary}
                                        />
                                        <Switch
                                            {...register(cb?.name, cb?.validationRules)}
                                            color={theme.colors.primary}
                                            style={{alignSelf: "center"}}
                                            value={field?.value}
                                            onValueChange={(value) => {
                                                // console.log("formValues", formValues)
                                                field.onChange(value);
                                                setFormValues({
                                                    ...control._formValues,
                                                    [cb?.name]: value
                                                });
                                                // field.onChange(value)
                                            }}
                                        />
                                    </View>
                                );
                            })}
                    </ScrollView>
                );

            case "select.two.oriented":
                return (
                    <View
                        style={{
                            alignSelf: "center",
                            width: "100%",
                            position: "absolute", // paddingHorizontal: 20,
                            bottom: 20
                        }}
                    >
                        <FilledButtonM
                            style={{marginTop: 50}}
                            onPress={
                                data?.options[0]?.action
                                    ? data?.options[0]?.action
                                    : () => {
                                        field.onChange(data?.options[0]?.value);
                                        goToNextStep();
                                    }
                            }
                            color={data?.options[0]?.color}
                            label={data?.options[0]?.label}
                        />

                        <TextButtonM
                            // mode="text"
                            style={{
                                marginTop: 10,
                                alignSelf: "center"
                            }}
                            color={data?.options[1]?.color}
                            onPress={() => {
                                field.onChange(data?.options[1]?.value);
                                goToNextStep();
                            }}
                            fontWeight="ExtraBold"
                            label={data?.options[1]?.label?.toUpperCase()}
                        />
                    </View>
                );
            case "select.paginated":
                const flatListRef = useRef();
                const [urlParams, setUrlParams] = useState({
                    search: "",
                    ...data?.additionalUrlParams
                });

                // const request = data?.service ? (useSelector((state) => state.api[data?.service?.name]?.request) ?? false) : null
                // const success = data?.service ? useSelector((state) => state.api[data?.service?.name]?.success) : null
                const error = data?.service
                    ? useSelector((state) => state.api[data?.service?.name]?.error)
                    : null;

                const [paginatedOptions, setPaginatedOptions] = useState([]);

                const initialSelectedItemsIds = data?.singleMode ? [field.value] : field.value
                const initialSelectedItems = initialSelectedItemsIds?.map(id => data?.defaultSelectedItems?.find(item => item?.id === id) ?? null)
                    ?.filter(item => !!item) ?? []

                const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

                const options = data?.withNull
                    ? [{id: null, display_name: "Aucun"}].concat(paginatedOptions)
                    : paginatedOptions;

                // Header component to display selected items
                const SelectedItemsHeader = React.memo(({selectedItems}) => (
                    !data?.singleMode && <View style={{flexWrap: 'wrap', alignItems: 'center'}}>
                        <TextM>{selectedItems.length} {plr("sélectionné", selectedItems.length)}</TextM>
                        {selectedItems.map((item, index) => (
                            renderItem({item: item, index: index}, true)
                        ))}
                    </View>
                ));

                useApiStatus(
                    data?.service, // api service
                    false, // success message on toast (string or null)
                    false, // error message on toast (true) or keep it in redux state (false)
                    (successData) => {
                        if (successData?.meta?.current_page === 1) {
                            // refresh
                            setPaginatedOptions(successData?.[data?.responseKey]);
                        } else {
                            // concat
                            setPaginatedOptions(
                                paginatedOptions.concat(successData?.[data?.responseKey])
                            );
                        }
                    } // success callback
                );

                const renderItem = (item, header = false) => {
                    const selected = data?.singleMode
                        ? field.value === item.item?.id
                        : field.value?.includes(item.item?.id);
                    return (

                        <TouchableOpacity
                            disabled={!!props.searchingRequest || selected && !header }
                            style={{
                                opacity: selected && !header ? 0.5 : 1,
                            }}
                            onPress={() => {
                                if (data?.singleMode) {
                                    field.onChange(item.item?.id);
                                    setSelectedItems([item.item]);
                                    setFormValues({
                                        ...control._formValues,
                                        [field?.name]: item.item?.id
                                    });
                                    if (!isLastStep) goToNextStep();
                                } else {
                                    const value = field.value ? field.value?.slice() : [];
                                    if (selected) {
                                        value?.splice(value?.indexOf(item.item?.id), 1);
                                        setSelectedItems(selectedItems.filter((i) => i.id !== item.item.id));
                                    } else {
                                        value?.push(item.item?.id);
                                        setSelectedItems(selectedItems.concat(item.item));
                                    }
                                    field.onChange(value);
                                    setFormValues({
                                        ...control._formValues,
                                        [field?.name]: value
                                    });
                                }

                                // console.log(control._formValues)
                                trigger();
                            }}
                        >
                            {data?.renderItem(item?.item, selected)}
                        </TouchableOpacity>
                    );
                }
                return (
                    <View
                        style={{
                            width: dimensions?.width,
                            alignSelf: "center",
                            // height: IS_WEB ? dimensions?.height / 1.1 : null,
                            flex: 1,
                            alignItems: "center",
                            paddingTop: 20
                        }}
                    >
                        <ServerSearch
                            style={{
                                width:
                                    dimensions?.width < 800
                                        ? dimensions?.width - 40
                                        : dimensions?.width / 2,
                                margin: 10
                            }}
                            urlParams={urlParams}
                            setUrlParams={setUrlParams}
                        />
                        <ErrorTextM
                            errorData={error}
                            style={{textAlign: "center", marginBottom: 20}}
                        />

                        <PaginatedFlatList
                            ref={flatListRef}
                            ListHeaderComponent={<SelectedItemsHeader selectedItems={selectedItems}/>}
                            data={options}
                            // contentContainerStyle={{paddingBottom: 100}}
                            additionalOnRefresh={() => {
                            }}
                            additionalRefreshing={false}
                            requestApiService={data?.service}
                            requestRouteParams={data?.routeParams}
                            requestUrlParams={urlParams}
                            requestData={null}
                            renderItem={renderItem}
                        />
                    </View>
                );
            case "date":
                return (
                    <DateTimePicker
                        type={data?.type}
                        control={control}
                        field={field}
                        setFormValues={setFormValues}
                        range={data?.range}
                    />
                );
            case "time":
                return (
                    <DateTimePicker
                        type={data?.type}
                        control={control}
                        field={field}
                        setFormValues={setFormValues}
                    />
                );
            case "date-time":
                return (
                    <DateTimePicker
                        type={data?.type}
                        control={control}
                        field={field}
                        setFormValues={setFormValues}
                        range={data?.range}
                    />
                );
            case "day-time":
                return (
                    <DateTimePicker
                        type={data?.type}
                        control={control}
                        field={field}
                        setFormValues={setFormValues}
                    />
                );
            case "date-multiple":
                return (
                    <View
                        style={{
                            flexDirection: "row",
                            alignSelf: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        {data?.inputs?.map((i, index) => {
                            const {field: localField} = useController({
                                control,
                                name: i?.name ?? ""
                            });

                            return (
                                <View
                                    key={index}
                                    style={{
                                        // alignItems: "center",
                                        marginHorizontal: 20
                                    }}
                                >
                                    {i?.title && (
                                        <TextM
                                            style={{
                                                marginBottom: 10,
                                                // marginRight: 10,
                                                textAlign: "center",
                                                width: 140
                                                // backgroundColor: "red"
                                            }}
                                            fontWeight={"ExtraBold"}
                                            color={theme.colors.primary}
                                            fontSize={15}
                                            wrap
                                        >
                                            {i?.title}
                                        </TextM>
                                    )}
                                    <DateTimePicker
                                        type={i?.type}
                                        control={control}
                                        field={localField}
                                        setFormValues={setFormValues}
                                    />
                                </View>
                            );
                        })}
                    </View>
                );
            case "custom":
                return React.createElement(data.component, {
                    control,
                    goToNextStep,
                    errors,
                    register,
                    serverError,
                    formValues,
                    setFormValues,
                    field,
                    trigger,
                    ...data?.componentProps
                });

            default:
                return <></>;
        }
    };

    return (
        <>
            {!champion && (
                <View
                    style={{
                        width: dimensions?.width,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        // backgroundColor: "red",
                        paddingHorizontal: 20,
                        paddingBottom: 10
                    }}
                >
                    {data?.label && (
                        <TitleM
                            color={
                                data?.type === "select.two.oriented"
                                    ? theme.colors.grey
                                    : theme.colors.primary
                            }
                            style={{
                                marginBottom: 10, // marginHorizontal: 20,
                                marginTop: data?.type === "select.two.oriented" ? 40 : 0,
                                textAlign:
                                    data?.type === "select.two.oriented" ? "center" : "left"
                            }}
                            fontWeight="ExtraBold"
                        >
                            {data?.label}
                        </TitleM>
                    )}
                    {data?.description && (
                        <TextM
                            style={{
                                marginBottom: 10,
                                marginRight: 10,
                                textAlign: "justify"
                            }}
                            wrap
                        >
                            {data?.description}
                        </TextM>
                    )}
                </View>
            )}
            <KeyboardAvoidingView
                style={{
                    flex: 1,
                    width: "100%",
                    alignItems: "center"
                }}
                keyboardVerticalOffset={
                    !champion ? -(300 + insets.top) : -(250 + insets.top)
                }
                behavior={"position"}
            >
                {/*behavior={Platform.OS === 'ios' ? 'position' : 'height'}>*/}
                <View
                    style={{
                        flex: 1,
                        width: dimensions?.width, //
                        // alignItems: "center",
                        // justifyContent: "center",
                        paddingHorizontal: 20,
                        marginBottom: hideNextButton ? 0 : 70
                    }}
                >
                    {renderTopComponent()}

                    {data?.icon && (
                        <View
                            style={{
                                marginTop: 80,
                                alignSelf: "center"
                            }}
                        >
                            {data?.icon}
                        </View>
                    )}
                    {renderInput()}
                    {!!data?.indication && data?.indication !== "" && (
                        <TextM
                            fontSize={12}
                            wrap
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                textAlign: IS_WEB ? "center" : "justify"
                            }}
                        >
                            {data?.indication}
                        </TextM>
                    )}
                    {isLastStep && (
                        <ErrorTextM
                            errorData={props.serverError}
                            style={{marginBottom: 20}}
                        />
                    )}
                    {renderBottomComponent()}
                </View>
            </KeyboardAvoidingView>
        </>
    );
};
