import {Platform, TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import React, {useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import SkillItem from "./SkillItem";
import Animated, {runOnJS, SlideInUp} from "react-native-reanimated";
import {skillSetsService} from "@data/services/skillSetsService";
import {useNavigation} from "@react-navigation/native";

function MyProgressCongrats(props) {

    const {userSkillSets} = props

    const navigation = useNavigation()

    useEffect(() => {
        props.apiRequest(skillSetsService.markSkillSetEvolutionAsRead, {}, {skill_set_ids: userSkillSets?.map(ss => ss?.id)})
    }, [])

    // console.log(userSkillSets)

    return <View style={{
        backgroundColor: theme.colors.white,
        // paddingVertical: 10,
        paddingTop: 30,
        flexGrow: 0,
        width: "100%",
        paddingHorizontal: 10
    }}>
        <TextM fontSize={30}
               wrap
               fontWeight="ExtraBold"
               style={{
                   textAlign: "center"
                   // marginTop: 50
               }}
               color={theme.colors.primary}>
            MA PROGRESSION
        </TextM>

        <>
            {userSkillSets?.map((ss, index) => {

                const [isShown, setIsShown] = useState(Platform.OS === 'web')

                const setShown = () => setIsShown(() => true)

                return <Animated.View entering={SlideInUp.delay(2500 * index).duration(700).withCallback((finished) => {
                    'worklet';
                    if (finished) {
                        runOnJS(setShown)()
                    }
                })}

                                      key={index}
                                      style={{
                                          marginTop: 50
                                      }}>
                    <TouchableOpacity style={{
                        borderRadius: 15,
                        borderColor: theme.colors.lightMore,
                        borderWidth: 1,
                        paddingLeft: 5,
                        paddingRight: 5,
                        width: "100%"
                    }}
                                      onPress={() => {
                                          navigation.push("skill-set", {skillSetId: ss?.id})
                                      }}>

                        <SkillItem stepNum={ss?.current_step}
                                   canStartAnimation={isShown}
                                   active={true}
                                   skillSet={ss}/>
                    </TouchableOpacity>
                </Animated.View>
            })}
        </>

    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        skills: state.data.staticData?.skills
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
    }

export default connect(mapStateToProps, mapDispatchToProps)(MyProgressCongrats)