// Radio fields data
import {theme} from "@common/theme/theme";
import moment from "moment";

export const studentClasses = [
    "CM1",
    "CM2",
    "6ème",
    "5ème",
    "4ème",
    "3ème",
    "2nde",
    "1ère",
    "Tle",
    "Autre"
];
export const sectionStudentClasses = [
    {
        title: "Primaire",
        options: ["CM1", "CM2"]
    },
    {
        title: "Collège",
        options: ["6ème", "5ème", "4ème", "3ème"]
    },
    {
        title: "Lycée",
        options: ["2nde", "1ère", "Tle"]
    },
    {
        title: "Autre",
        options: ["Autre"]
    }
];

export const activityStatuses = [
    {
        label: "Validé",
        value: "validated"
    },
    {
        label: "Rejeté",
        value: "rejected"
    },
    {
        label: "Fait",
        value: "not_reviewed"
    },
    {
        label: "En attente",
        value: "pending_review"
    }
];

export const days = [
    {value: "Monday", label: "L"},
    {value: "Tuesday", label: "Ma"},
    {value: "Wednesday", label: "Me"},
    {value: "Thursday", label: "J"},
    {value: "Friday", label: "V"},
    {value: "Saturday", label: "S"},
    {value: "Sunday", label: "D"}
];

export const daysOptions = [
    {value: "Monday", label: "Lundi"},
    {value: "Tuesday", label: "Mardi"},
    {value: "Wednesday", label: "Mercredi"},
    {value: "Thursday", label: "Jeudi"},
    {value: "Friday", label: "Vendredi"},
    {value: "Saturday", label: "Samedi"},
    {value: "Sunday", label: "Dimanche"}
];
export const roomTypes = [
    {value: 'biweekly', label: 'Groupe régulier'},
    {value: 'individual', label: 'Individuel'},
    {value: 'holiday_course', label: 'Stage'},
    {value: 'holiday_workbook', label: 'Révisions d\'été'},
    {value: 'trial', label: 'Essai'},
    {value: 'custom', label: 'Custom'}
]
// Gender
export const gender = ["Masculin", "Féminin"];

// interviewDays
export const interviewDays = ["Saturday", "Sunday"];

export const channels = [
    {label: "Famille/amis", value: "Famille/amis"},
    {label: "Actualités/article/blog", value: "Actualités/article/blog"},
    {label: "Facebook/Instagram", value: "Facebook/Instagram"},
    {label: "Recherche Google", value: "Recherche Google"},
    {label: "Partenaire", value: "Partenaire"},
    {label: "Autre", value: "Autre"}
];
export const favorite_subjects = [
    {value: "Maths", label: "Maths"},
    {value: "Physique-Chimie", label: "Physique-Chimie"},
    {value: "SVT", label: "SVT"},
    {value: "Français,", label: "Français"},
    {value: "Anglais,", label: "Anglais"},
    {value: "Espagnol,", label: "Espagnol"},
    {value: "Allemand,", label: "Allemand"},
    {value: "Histoire-Géographie,", label: "Histoire-Géographie"},
    {value: "Musique,", label: "Musique"},
    {value: "Sport,", label: "Sport"}
];

export const subjectPriorities = [
    {label: "Scientifiques (Maths, Physique...)", value: "scientific"},
    {label: "Littéraires (Français, Histoire-Géo, SES...)", value: "literary"}
    // {label: 'Langues (Anglais, Espagnol, Allemand...)', value: 'language'}
    // {label: 'Autre', value: 'other'}
];

export const subjectPrioritiesSing = [
    {label: "Scientifique (Maths, Physique...)", value: "scientific"},
    {label: "Littéraire (Français, Histoire-Géo, SES...)", value: "literary"}
    // {label: 'Langues (Anglais, Espagnol, Allemand...)', value: 'language'}
    // {label: 'Autre', value: 'other'}
];

export const subjectPrioritiesSingWithNullOption = [
    {label: "Scientifique (Maths, Physique...)", value: "scientific"},
    {label: "Littéraire (Français, Histoire-Géo, SES...)", value: "literary"},
    {label: "Aide aux devoirs toutes matières", value: null}
];

export const subjectPrioritiesSingLight = [
    {label: "Scientifique", value: "scientific"},
    {label: "Littéraire", value: "literary"}
    // {label: 'Langues (Anglais, Espagnol, Allemand...)', value: 'language'}
    // {label: 'Autre', value: 'other'}
];

export const subjectPrioritiesSingLightWithNullOption = [
    {label: "Scientifique", value: "scientific"},
    {label: "Littéraire", value: "literary"},
    {label: "Aide aux devoirs toutes matières", value: null}
];

export const subjectPrioritiesSubjectsDisplay = {
    scientific: ["Maths", "Physique", "+3"],
    literary: ["Français", "SES", "+2"],
    "scientific,literary": ["Maths", "Français", "+5"],
    "literary,scientific": ["Maths", "Français", "+5"]
};

export const slotsPossibleOptions = [
    {label: "Lundi 18h-20h", value: "Lundi 18h-20h"},
    {label: "Mardi 18h-20h", value: "Mardi 18h-20h"},
    {label: "Mercredi 14h-16h", value: "Mercredi 14h-16h"},
    {label: "Mercredi 16h-18h", value: "Mercredi 16h-18h"},
    {label: "Mercredi 18h-20h", value: "Mercredi 18h-20h"},
    {label: "Jeudi 18h-20h", value: "Jeudi 18h-20h"},
    {label: "Vendredi 18h-20h", value: "Vendredi 18h-20h"},
    {label: "Samedi 8h-10h", value: "Samedi 8h-10h"},
    {label: "Samedi 10h-12h", value: "Samedi 10h-12h"},
    {label: "Samedi 14h-16h", value: "Samedi 14h-16h"},
    {label: "Samedi 16h-18h", value: "Samedi 16h-18h"},
    {label: "Samedi 18h-20h", value: "Samedi 18h-20h"},
    {label: "Dimanche 8h-10h", value: "Dimanche 8h-10h"},
    {label: "Dimanche 10h-12h", value: "Dimanche 10h-12h"},
    {label: "Dimanche 14h-16h", value: "Dimanche 14h-16h"},
    {label: "Dimanche 16h-18h", value: "Dimanche 16h-18h"},
    {label: "Dimanche 18h-20h", value: "Dimanche 18h-20h"}
];
export const slotsPossibleWeekendOptions = [
    {label: "Samedi 8h-10h", value: "Samedi 8h-10h"},
    {label: "Samedi 10h-12h", value: "Samedi 10h-12h"},
    {label: "Samedi 14h-16h", value: "Samedi 14h-16h"},
    {label: "Samedi 16h-18h", value: "Samedi 16h-18h"},
    {label: "Samedi 18h-20h", value: "Samedi 18h-20h"},
    {label: "Dimanche 8h-10h", value: "Dimanche 8h-10h"},
    {label: "Dimanche 10h-12h", value: "Dimanche 10h-12h"},
    {label: "Dimanche 14h-16h", value: "Dimanche 14h-16h"},
    {label: "Dimanche 16h-18h", value: "Dimanche 16h-18h"},
    {label: "Dimanche 18h-20h", value: "Dimanche 18h-20h"}
];
export const slotsPossibleRegularDaysOptions = [
    {label: "Lundi 18h-20h", value: "Lundi 18h-20h"},
    {label: "Mardi 18h-20h", value: "Mardi 18h-20h"},
    {label: "Mercredi 14h-16h", value: "Mercredi 14h-16h"},
    {label: "Mercredi 16h-18h", value: "Mercredi 16h-18h"},
    {label: "Mercredi 18h-20h", value: "Mercredi 18h-20h"},
    {label: "Jeudi 18h-20h", value: "Jeudi 18h-20h"},
    {label: "Vendredi 18h-20h", value: "Vendredi 18h-20h"}
];
export const qualities = [
    {value: "Ambitieux", label: "Ambitieux"},
    {value: "Aventurier", label: "Aventurier"},
    {value: "Bienveillant", label: "Bienveillant"},
    {value: "Conciliant", label: "Conciliant"},
    {value: "Volontaire", label: "Volontaire"},
    {value: "Travailleur", label: "Travailleur"},
    {value: "Sympathique", label: "Sympathique"},
    {value: "Souple", label: "Souple"},
    {value: "Sociable", label: "Sociable"},
    {value: "Sobre", label: "Sobre"},
    {value: "Serviable", label: "Serviable"},
    {value: "Rusé", label: "Rusé"},
    {value: "Respectueux", label: "Respectueux"},
    {value: "Prévoyant", label: "Prévoyant"},
    {value: "Ponctuel", label: "Ponctuel"},
    {value: "Patient", label: "Patient"},
    {value: "Organisé", label: "Organisé"},
    {value: "Modeste", label: "Modeste"},
    {value: "Minutieux", label: "Minutieux"},
    {value: "Mature", label: "Mature"},
    {value: "Leader", label: "Leader"},
    {value: "Intuitif", label: "Intuitif"},
    {value: "Ingénieux", label: "Ingénieux"},
    {value: "Généreux", label: "Généreux"},
    {value: "Enthousiaste", label: "Enthousiaste"},
    {value: "Discipliné", label: "Discipliné"},
    {value: "Diplomate", label: "Diplomate"},
    {value: "Diplomate", label: "Diplomate"},
    {value: "Déterminé", label: "Déterminé"},
    {value: "Curieux", label: "Curieux"},
    {value: "Créatif", label: "Créatif"},
    {value: "Courageux", label: "Courageux"}
];
//profession
export const professionalSituation = ["Etudiant", "Professionnel"];
export const productColors = {
    integral: theme.colors.material.secondary?.["900"],
    essential: theme.colors.material.secondary?.["600"],
    initial: theme.colors.material.secondary?.["300"],
    holidaycourse: theme.colors.material.secondary?.["600"],
    premium: theme.colors.warning,
    plus: theme.colors.accent,
    basic: theme.colors.lightMore
};

export const subscriptionStatusColors = {
    recurring: theme.colors.successDark,
    on_grace_period: theme.colors.accent,
    on_grace_period_before_pause: theme.colors.warning,
    on_trial: theme.colors.successDark,
    paused: theme.colors.light,
    planned: theme.colors.success,
    ended: theme.colors.light,
    on_generic_trial: theme.colors.success,
    expired_trial: theme.colors.light
};

export const subscriptionStatusNames = {
    recurring: "RÉCURRENT",
    on_grace_period: "ACTIF AVEC ÉCHÉANCE",
    on_grace_period_before_pause: "PAUSE PLANIFIÉE",
    on_trial: "ESSAI + CB",
    paused: "MIS EN PAUSE",
    planned: "PLANIFIÉ",
    ended: "TERMINÉ",
    on_generic_trial: "ESSAI GRATUIT",
    expired_trial: "ESSAI EXPIRÉ"
};

export const subscribedProductNames = {
    initial: "Offre Initiale",
    essential: "Offre Essentielle",
    integral: "Offre Intégrale"
};
export const subscribedProducts = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: "Intégrale",
                value: "integral-student"
            },
            {
                label: "Essentielle",
                value: "essential-student"
            },
            {
                label: "Initiale",
                value: "initial-student"
            },
            {
                label: "Stage",
                value: "holidaycourse-student"
            },
            {
                label: "Premium",
                value: "premium-student"
            },
            {
                label: "Plus",
                value: "plus-student"
            },
            {
                label: "Basic",
                value: "student"
            }
        ]
    }
];

export const subscriptionStates = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: "Essai gratuit",
                value: "on_generic_trial"
            },
            {
                label: "Abonné",
                value: "subscribed"
            },
            {
                label: "Abonnement terminé",
                value: "ended"
            },
            {
                label: "Jamais abonné",
                value: "never_subscribed"
            }
        ]
    }
];

export const roomTypesFilter = [
    {
        title: "Sélectionner tout",
        data: roomTypes
    }
];

export const liveDaysFilter = [
    {
        title: "Sélectionner tout",
        data: daysOptions
    }
];

export const availabilityFilter = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: "Complet",
                value: "full"
            },
            {
                label: "Place(s) disponible(s)",
                value: "available"
            }
        ]
    }
];

export const liveOccurrenceDatesFilter = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: moment().format("ddd DD/MM"),
                value: moment().format("YYYY-MM-DD")
            },
            {
                label: moment().add(1, "days").format("ddd DD/MM"),
                value: moment().add(1, "days").format("YYYY-MM-DD")
            },
            {
                label: moment().add(2, "days").format("ddd DD/MM"),
                value: moment().add(2, "days").format("YYYY-MM-DD")
            },
            {
                label: moment().add(3, "days").format("ddd DD/MM"),
                value: moment().add(3, "days").format("YYYY-MM-DD")
            },
            {
                label: moment().add(4, "days").format("ddd DD/MM"),
                value: moment().add(4, "days").format("YYYY-MM-DD")
            },
            {
                label: moment().add(5, "days").format("ddd DD/MM"),
                value: moment().add(5, "days").format("YYYY-MM-DD")
            },
            {
                label: moment().add(6, "days").format("ddd DD/MM"),
                value: moment().add(6, "days").format("YYYY-MM-DD")
            }
        ]
    }
];

export const interviewReportState = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: "À jour (d<=26)",
                value: "ok"
            },
            {
                label: "À traiter (26<d<=29)",
                value: "todo"
            },
            {
                label: "En retard (d>29), au moins un rapport",
                value: "late"
            },
            {
                label: "En retard (29<d<=60), aucun rapport",
                value: "late_never"
            },
            {
                label: "En retard (d>60), aucun rapport",
                value: "late_never_old"
            }
        ]
    }
];

export const candidaturesStatus = [
    {
        title: "Sélectionner tout",
        data: [
            {
                label: "Validée",
                value: "validated"
            },
            {
                label: "Refusée",
                value: "rejected"
            },
            {
                label: "En attente",
                value: "pending"
            }
        ]
    }

]

export const skillSetIds = [
    {
        label: "Je maîtrise l’art de l’organisation",
        value: 101
    },
    {
        label: "Je maîtrise l’art de la concentration",
        value: 102
    },
    {
        label: "Je maîtrise l’art de l’expression",
        value: 103
    },
    {
        label: "Je maîtrise l’art de la coopération",
        value: 104
    }
];

// Result
export const resultBooster = [
    {
        value: "Rendu très satisfaisant",
        text: "Rendu très satisfaisant",
        color: "success"
    },
    {
        value: "Rendu satisfaisant",
        text: "Rendu satisfaisant",
        color: "warning"
    },
    {
        value: "Rendu insatisfaisant",
        text: "Rendu insatisfaisant",
        color: "danger"
    }
];
export const ResponseResult = [
    {
        value: "Pas du tout d'accord",
        text: "Pas du tout d'accord"
    },
    {
        value: "Pas tout à fait d'accord",
        text: "Pas tout à fait d'accord"
    },
    {
        value: "Plutôt d'accord",
        text: "Plutôt d'accord"
    },
    {
        value: "Tout à fait d'accord",
        text: "Tout à fait d'accord"
    }
];

// Survey response
export const surveyResponse = [
    "Pas du tout d'accord",
    "Pas tout à fait d'accord",
    "Plutôt d'accord",
    "Tout à fait d'accord"
];

export const formIds = {
    ACTIVITY: "activity",
    COMPLETE_PROFILE: "complete_profile",
    NEW_MATERIAL: "new-material",
    LIVE_REPORT_COMMENT: "live-report-comment"
};
