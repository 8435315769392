import {useEffect} from "react";
import {IS_WEB} from "@common/utils/mobileUtils";
import Constants from "expo-constants";

export const useGTMWeb = () => {
    useEffect(() => {
        // only web and production env
        if (IS_WEB && Constants.expoConfig.extra.releaseStage === "production") {
            // Injecting the script at the topmost of the head section
            const script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-52K5XN5R');`;
            document.head.insertBefore(script, document.head.firstChild);

            // Injecting the noscript tag just after the opening of the body
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-52K5XN5R";
            iframe.height = "0";
            iframe.width = "0";
            iframe.style.display = "none";
            iframe.style.visibility = "hidden";
            noscript.appendChild(iframe);
            document.body.insertBefore(noscript, document.body.firstChild);
        }

    }, []);
}